import React from 'react'
import { Flex, Box } from 'rebass'
import Message from 'components/Message'

const Heading = ({ title, actions, titleProps, actionProps }) => (
  // <Box position="relative" zIndex={3}>
  <Flex
    justifyContent="space-between"
    alignItems="center"
    height="46px"
    zIndex={3}
  >
    {title && (
      <Box
        display="inline-flex"
        py={2}
        px={3}
        mx={3}
        mt={-4}
        justifyContent="center"
        variant="primary"
        {...titleProps}
      >
        <Message
          id={title}
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        />
      </Box>
    )}
    {actions && (
      <Box
        display="inline-flex"
        justifyContent="center"
        // alignSelf="flex-end"
        mx={3}
        {...actionProps}
      >
        {actions}
      </Box>
    )}
  </Flex>
)

export default ({
  title,
  actions,
  children,
  titleProps,
  actionProps,
  contentProps,
  ...props
}) => (
  <Box variant="default" {...props} minWidth={['320px', 'auto']}>
    {(title || actions) && (
      <Heading
        title={title}
        titleProps={titleProps}
        actions={actions}
        actionProps={actionProps}
      />
    )}
    <Box px={3} pb={3} pt={title || actions ? 0 : 3} {...contentProps}>
      {children}
    </Box>
  </Box>
)

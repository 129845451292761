import React from 'react'
import { Text } from 'rebass'
import Link from 'components/Link'
import TextInput from 'components/TextInput'
import ConfirmButton from 'components/ConfirmButton'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions, message }) => [
  {
    id: 'spu',
    label: 'product.field.spu',
    render: ({ row }) => (
      <Link url={`/product/${row.id}/edit`}>
        <Text variant="link">{row.spu}</Text>
      </Link>
    )
  },
  {
    id: 'categoryName',
    label: 'product.field.category'
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <ConfirmButton
        title="product.title.delete"
        message="product.message.delete"
        onClick={handleDelete({ session, app, id: row.id, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const filters = ({
  message,
  filterOp,
  setFilterOp,
  filterInput,
  setFilterInput
}) => [
  {
    id: 'spu',
    label: 'product.field.spu',
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 3], width: 1 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    )
  },
  {
    id: 'categoryName',
    label: 'product.field.category',
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 3], width: 1 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    )
  }
]

export const onLoad = async ({
  state,
  setState,
  setExcel,
  session,
  app,
  page,
  filter = []
}) => {
  const { merchantId } = app.state.staff
  const countPerPage = setExcel ? -1 : 25
  const input = { page, countPerPage }

  filter.forEach(item => {
    input[item.id] = item.value
  })

  const variables = { merchantId, input }
  const query = `
    query ($merchantId: ID!, $input: ProductQueryInput) {
      pagedProducts(merchantId: $merchantId, input: $input) {
        data {
          id
          categoryName
          spu
        }
        pagination {
          totalCount
          totalPage
          page
          pages
          prevPage
          nextPage
          countPerPage
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  if (setExcel) {
    setExcel(data.pagedProducts.data)
    return
  }

  setState(data.pagedProducts)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
        mutation ($id: ID!) {
          deleteProduct(id: $id)
        }
      `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

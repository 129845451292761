import React, { useState, useRef } from 'react'
import { Button } from 'rebass'
import { FormattedMessage } from 'react-intl'
import * as model from 'models/customerList'
import Link from 'components/Link'
import Popover from 'components/Popover'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Page from 'components/PageContainer'
import CustomerModal from 'views/CustomerModal'
import Message from 'components/Message'
import { MdFileDownload } from 'react-icons/md'

export default () => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleExport = () => {
    ref.current.export()
  }

  return (
    <Page
      title="customer.title.list"
      navId="customerList"
      actions={
        <>
          <Button variant="outlineSecondary" onClick={handleOpen}>
            <FormattedMessage id="btn.add" />
          </Button>
          <Popover>
            <Link variant="inverseLink" onClick={handleExport}>
              <MdFileDownload size="24px" />
              <Message ml={2} id="btn.export" />
            </Link>
          </Popover>
        </>
      }
    >
      <Section>
        <DataTable ref={ref} {...model} />
      </Section>
      <CustomerModal open={open} actions={{ handleClose }} />
    </Page>
  )
}

import React from 'react'
import { Flex, Text } from 'rebass'
import Image from 'components/Image'
import Table from 'components/Table'
import Counter from 'components/Counter'
import { initializeState, validateForm } from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    ticketItems: value.ticketItems || [],
    quantity:
      (value.ticketItems && value.ticketItems.map(item => item.quantity)) || []
  })

const validation = {
  ticketItems: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ state, setState }) => {
  return {
    product: (
      <Table
        columns={[
          {
            id: 'product',
            label: 'refund.field.product',
            render: ({ row }) => (
              <Flex flexDirection={['column', 'row']} alignItems="center">
                <Image
                  src={row.image.src}
                  width="64px"
                  height="64px"
                  sx={{ objectFit: 'contain' }}
                />
                <Text mx={3}>{row.spu}</Text>
              </Flex>
            )
          },
          {
            id: 'quantity',
            label: 'refund.field.quantity',
            render: ({ row, index }) => {
              const ticketItem = state.ticketItems[index]
              const quantity = state.quantity[index]
              return (
                <Counter
                  min={0}
                  max={ticketItem.quantity}
                  value={quantity}
                  onChange={async count => {
                    const newQuantity = [...state.quantity]
                    newQuantity.splice(index, 1, count)
                    setState({ ...state, quantity: newQuantity })
                  }}
                />
              )
            }
          }
        ]}
        rows={state.ticketItems}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addRefund(state, app, session)
    if (!ok) return

    actions.handleRefresh()
    actions.handleRefundClose()
  }
})

async function addRefund(state, app, session) {
  const { merchantId } = app.state.staff
  const ticketItems = state.quantity
    .filter(item => item > 0)
    .map((quantity, index) => ({
      merchantId,
      productVariantId: state.ticketItems[index].productVariantId,
      quantity
    }))

  if (ticketItems.length === 0) {
    return false
  }

  const variables = {
    input: {
      ticketType: 'REFUND',
      ticketId: state.id,
      ticketItems
    }
  }
  const query = `
    mutation($input: TicketInput!) {
      createTicket(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  return ok
}

import escapeHtml from 'escape-html'
import { Editor, Text } from 'slate'

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const serialize = nodes => {
  return nodes.map(n => serializeNode(n)).join('')
}

function serializeNode(node) {
  if (Text.isText(node)) {
    return serializeLeaf(node)
  }

  const children = node.children.map(n => serializeNode(n)).join('')
  return serializeElement(node, children)
}

function serializeElement(node, children) {
  switch (node.type) {
    case 'block-quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'heading-one':
      return `<h1>${children}</h1>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    default:
      return `<p>${children}</p>`
  }
}

function serializeLeaf(node) {
  const text = escapeHtml(node.text)

  if (node.bold) return `<strong>${text}</strong>`
  if (node.code) return `<code>${text}</code>`
  if (node.italic) return `<em>${text}</em>`
  if (node.underlined) return `<u>${text}</u>`

  return text
}

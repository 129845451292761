import React from 'react'
import Select from 'components/Select'
import {
  initializeState,
  validateForm,
  handleSelectChange
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    source: value.source || 'HOME_KEYWORD',
    type: value.type || 'PRODUCT',
    title: value.title || '',
    tags: value.tags || [],
    seq: value.seq || 0
  })

const validation = {
  title: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  return {
    title: (
      <Select
        id="title"
        placeholder="keyword.field.title"
        options={state.tags}
        value={state.title}
        onChange={handleSelectChange('title', state, setState, validation)}
        errMsg={state.__error__.title}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    setState(initialState(data))
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addKeyword(state, app, session)
    if (!ok) return

    actions.handleRefresh()
    actions.handleClose()
    setState(initialState({ tags: state.tags }))
  }
})

async function getData({ app, session, id }) {
  const { merchantId } = app.state.staff
  const variables = { id, merchantId }
  const query = `
    query($merchantId: ID!) {
      productTags(merchantId: $merchantId) {
        value
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return {
    tags: data.productTags.map(({ value }) => ({ value, label: value }))
  }
}

async function addKeyword(state, app, session) {
  const variables = {
    id: state.id,
    input: {
      source: state.source,
      type: state.type,
      title: state.title.value,
      linkUrl: state.linkUrl,
      seq: state.seq
    }
  }
  const query = `
    mutation ($input: AdInput!) {
      addAd(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React from 'react'
import { Button } from 'rebass'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Table from 'components/Table'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { formatDate } from 'utilities/dateUtil'
import { MdDelete } from 'react-icons/md'
import ListGroup from 'components/ListGroup'
import DataPlaceholder from 'components/DataPlaceholder'

export const initialState = (value = {}) =>
  initializeState({
    products: value.products || [],
    id: value.id || '',
    status: value.status || 'PENDING',
    transDate: value.transDate
      ? formatDate(new Date(value.transDate))
      : getDefaultDate(),
    ticketItems: value.ticketItems || [],
    arrivalTickets: value.arrivalTickets || [],
  })

function getDefaultDate() {
  return formatDate(new Date())
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState, refs }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    transDate: (
      <TextInput
        id="transDate"
        type="date"
        label="purchase.field.transDate"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    productFilter: (
      <Select
        id="productFilter"
        placeholder="purchase.field.productFilter"
        options={state.products.map((item) => ({
          value: item.id,
          label: getProductLabel(item),
        }))}
        value=""
        onChange={(product) => {
          if (!product) return

          const { value } = product
          const ticketItems = [...state.ticketItems]
          if (ticketItems.some(({ id }) => id === value)) {
            return
          }
          const ticketItem = state.products.find(({ id }) => id === value)
          ticketItem.quantity = 1

          setState({
            ...state,
            ticketItems: [...ticketItems, ticketItem],
          })
        }}
      />
    ),
    product: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'purchase.field.spu',
          },
          {
            id: 'sku',
            label: 'purchase.field.sku',
          },
          {
            id: 'quantity',
            label: 'purchase.field.quantity',
            width: '128px',
            render: ({ row, index }) => (
              <TextInput
                containerProps={{ m: 0 }}
                type="number"
                min="0"
                value={
                  state.ticketItems[index]
                    ? state.ticketItems[index].quantity
                    : 1
                }
                onChange={(event) => {
                  let quantity = event.target.value
                  if (quantity < 1) quantity = 1

                  const ticketItems = [...state.ticketItems]
                  const product = ticketItems[index]
                  product.quantity = quantity
                  ticketItems.splice(index, 1, product)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ row }) => (
              <Button
                type="button"
                variant="table"
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  const itemIdx = ticketItems.findIndex(
                    (item) => item.id === row.id
                  )
                  ticketItems.splice(itemIdx, 1)
                  setState({ ...state, ticketItems })
                }}
              >
                <Icon>
                  <MdDelete />
                </Icon>
              </Button>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
    ticket: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'purchase.field.spu',
          },
          {
            id: 'sku',
            label: 'purchase.field.sku',
          },
          {
            id: 'quantity',
            label: 'purchase.field.quantity',
            align: 'right',
          },
        ]}
        rows={state.ticketItems}
      />
    ),
    arrivalTicket:
      state.arrivalTickets.length === 0 ? (
        <DataPlaceholder />
      ) : (
        state.arrivalTickets.map((ticket, index) => (
          <ListGroup
            py={2}
            mx={2}
            key={ticket.id}
            label="purchase.message.arrivedAt"
            labelProps={{ values: { transDate: ticket.transDate } }}
            containerSx={{
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor:
                index === state.arrivalTickets.length - 1 ? 'grey.0' : 'grey.2',
            }}
          >
            {getRefTable(ticket.ticketItems)}
          </ListGroup>
        ))
      ),
  }
}

function getRefTable(ticketItems) {
  const rows = ticketItems.reduce((result, item) => {
    const { productVariantId, spu, sku, options, quantity, extra } = item
    const { ticketType } = extra
    const ticketItem = result.get(productVariantId) || {}
    let { acceptQuantity = 0, cancelQuantity = 0 } = ticketItem

    if (ticketType === 'PURCHASE_ACCEPT') {
      acceptQuantity += quantity
    }

    if (ticketType === 'PURCHASE_CANCEL') {
      cancelQuantity += quantity
    }

    return result.set(productVariantId, {
      spu,
      sku,
      options,
      acceptQuantity,
      cancelQuantity,
    })
  }, new Map())

  return (
    <Table
      columns={[
        {
          id: 'spu',
          label: 'purchase.field.spu',
        },
        {
          id: 'sku',
          label: 'purchase.field.sku',
        },
        {
          id: 'acceptQuantity',
          label: 'purchase.field.acceptQuantity',
          align: 'right',
        },
        {
          id: 'cancelQuantity',
          label: 'purchase.field.cancelQuantity',
          align: 'right',
        },
      ]}
      rows={Array.from(rows.values())}
    />
  )
}

function getProductLabel(product) {
  const { spu, options } = product
  return (
    spu +
    (options && options.length > 0
      ? ` [${options.map(({ value }) => value).join(', ')}]`
      : '')
  )
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match,
}) => ({
  handleLoad: async () => {
    const configs = await getConfigs({ app, session })
    let id = match.params.id

    if (id) {
      const { ticket, ticketItems, refTickets } = await getTicket({
        app,
        session,
        id,
      })
      setState(
        initialState({
          products: configs.productVariants,
          id,
          status: ticket.status,
          transDate: ticket.transDate,
          ticketItems: ticketItems,
          arrivalTickets: refTickets,
        })
      )
      return
    }

    setState(initialState({ products: configs.productVariants }))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = await addPurchase(state, app, session)
    if (!ok) return

    const id = data.createTicket
    const { ticket, ticketItems } = await getTicket({ app, session, id })
    setState(
      initialState({
        ...state,
        id,
        status: ticket.status,
        transDate: ticket.transDate,
        ticketItems: ticketItems,
      })
    )
    history.push(`/purchase/${id}/edit`)
  },
  handleDelete: (id) => async () => {
    const variables = { id }
    const query = `
      mutation($id: ID!) {
        deleteProduct(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }
  },
})

async function getConfigs({ app, session }) {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query ($merchantId: ID!) {
      productVariants(merchantId: $merchantId) {
        id
        spu
        sku
        barcode
        options {
          name
          value
        }
        image {
          src
          alt
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return null

  return { productVariants: data.productVariants }
}

export async function addPurchase(state, app, session) {
  if (state.ticketItems.length === 0) return

  const { staff } = app.state
  const variables = {
    input: {
      ticketType: 'PURCHASE',
      ticketItems: state.ticketItems.map((item) => ({
        merchantId: staff.merchantId,
        productVariantId: item.id,
        quantity: parseInt(item.quantity),
      })),
    },
  }
  const query = `
    mutation($input: TicketInput!) {
      createTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getTicket({ app, session, id }) {
  const variables = { ticketId: id }
  const query = `
    query($ticketId: ID!) {
      ticket(ticketId: $ticketId) {
        transDate
        status
      }
      ticketItems(ticketId: $ticketId) {
        productVariantId
        spu
        sku
        options {
          name
          value
        }
        quantity
        transDate
        status
        createdAt
        createdBy
      }
      refTickets(id: $ticketId) {
        id
        transDate
        ticketItems {
          productVariantId
          spu
          sku
          options {
            name
            value
          }
          quantity
          extra
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data
}

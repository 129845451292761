import React from 'react'
import { Box } from 'rebass'
import Message from 'components/Message'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

export default ({ label, checked, onChange, ...props }) => {
  return (
    <Box
      onClick={onChange}
      sx={{
        cursor: 'pointer',
        userSelect: 'none'
      }}
      {...props}
    >
      {label && <Message id={label} />}
      <Box
        color={checked ? 'accent.1' : 'grey.4'}
        fontSize={4}
        lineHeight={0}
        sx={{
          '&:hover': {
            color: 'accent.1'
          }
        }}
      >
        {checked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
      </Box>
    </Box>
  )
}

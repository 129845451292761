import React from 'react'
import TextInput from 'components/TextInput'
import TextArea from 'components/TextArea'
import Select from 'components/Select'
import Message from 'components/Message'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { formatDate, formatTime } from 'utilities/dateUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    date: value.date || formatDate(new Date()),
    time: value.time || formatTime(new Date()),
    status: value.status || '',
    memo: value.memo || '',
  })

const validation = {
  date: [{ type: 'required', message: 'error.required' }],
  time: [{ type: 'required', message: 'error.required' }],
  status: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    date: (
      <TextInput
        id="date"
        type="date"
        label="order.field.date"
        value={state.date}
        onChange={onTextChange('date')}
        errMsg={state.__error__.date}
      />
    ),
    time: (
      <TextInput
        id="time"
        type="time"
        label="order.field.time"
        value={state.time}
        onChange={onTextChange('time')}
        errMsg={state.__error__.time}
      />
    ),
    status: (
      <Select
        id="status"
        isSearchable={false}
        label="order.field.status"
        placeholder="order.field.status"
        options={[
          // {
          //   value: 'ORDER_RECEIVED',
          //   label: <Message id="order.status.orderReceived" />
          // },
          {
            value: 'SHIPPED',
            label: <Message id="order.status.SHIPPED" />,
          },
          {
            value: 'ARRIVED',
            label: <Message id="order.status.ARRIVED" />,
          },
        ]}
        value={state.status}
        onChange={handleSelectChange('status', state, setState, validation)}
      />
    ),
    memo: (
      <TextArea
        rows="4"
        label="order.field.memo"
        placeholder="order.field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
        errMsg={state.__error__.memo}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addTicket(state, app, session)
    if (ok) {
      actions.handleRefresh()
      actions.handleClose()
    }
  },
})

async function addTicket(value, app, session) {
  const variables = {
    ticketId: value.id,
    input: {
      ticketType: 'SELL',
      status: 'SHIPPED',
      extra: {
        timestamp: `${value.date} ${value.time}:00`,
        status: value.status.value,
        memo: value.memo,
      },
    },
  }
  const query = `
    mutation($ticketId: ID!, $input: TicketStatusInput!) {
      editTicketStatus(ticketId: $ticketId, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

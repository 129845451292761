import React, { useEffect, useContext } from 'react'
import { Box, Text, Image } from 'rebass'
import { withRouter } from 'react-router'
import { Scrollbars } from 'react-custom-scrollbars'
import BgImage from 'components/BgImage'
import Link from 'components/Link'
import List from 'components/List'
import ListGroup from 'components/ListGroup'
import ListItem from 'components/ListItem'
import Divider from 'components/Divider'
import { AppRoute } from 'configs/route'
import { appLinks, pageLinks } from 'configs/nav'
import AppContext from 'contexts/AppContext'
import { NAV_SET } from 'constants/actionType'
import { isActive } from 'utilities/listUtil'
import Logo from 'assets/svg/Logo'
import bgImg from 'assets/img/background.jpg'
import AvatarImg from 'assets/img/01.png'

const Container = ({ open, children }) => (
  <BgImage src={bgImg}>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${open ? '260px' : 0} 1fr`,
        gridGap: 0,
        height: '100vh'
      }}
    >
      {children}
    </Box>
  </BgImage>
)

const Nav = ({ children }) => (
  <Scrollbars>
    <Box
      as="nav"
      sx={{
        p: 3,
        overflow: 'hidden',
        zIndex: 200
      }}
    >
      {children}
    </Box>
  </Scrollbars>
)

const Brand = () => (
  <Link px={2} pb={3}>
    <Box width="30px" height="30px" mr={3}>
      <Logo color="#fff" />
    </Box>
    <Text color="grey.0" fontSize={2}>
      MR. ADMIN
    </Text>
  </Link>
)

const AppList = ({ t, history, items, staff }) => {
  if (!items) return null

  return (
    <List my={2}>
      <ListGroup
        p={2}
        label={staff.username}
        formatMessage={false}
        icon={<Image src={AvatarImg} height="30px" width="30px" />}
        textSx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
        sx={{ color: 'grey.3' }}
      >
        <List>
          {items.map(item => (
            <ListItem
              key={item.id}
              p={2}
              mt={2}
              label={item.label}
              icon={item.icon}
              variant="glass"
              onClick={() => history.push(item.path)}
            />
          ))}
        </List>
      </ListGroup>
    </List>
  )
}

const PageList = ({ t, history, items, navId, setNavId }) => {
  if (!items) return null
  return (
    <List>
      {items.map(item => {
        if (item.items) {
          const active = isActive(item.items, navId)
          return (
            <ListGroup
              key={item.id}
              p={2}
              mt={2}
              label={item.label}
              icon={item.icon}
              active={active}
              variant={active ? 'glassActive' : 'glass'}
            >
              <PageList
                t={t}
                history={history}
                items={item.items}
                navId={navId}
                setNavId={setNavId}
              />
            </ListGroup>
          )
        }

        const active = item.id === navId
        return (
          <ListItem
            key={item.id}
            p={2}
            mt={2}
            label={item.label}
            icon={item.icon}
            active={active}
            variant={active ? 'secondary' : 'glass'}
            onClick={() => {
              setNavId(item.id)
              history.push(item.path)
            }}
          />
        )
      })}
    </List>
  )
}

const Main = () => (
  <Box as="main" bg="grey.2">
    <AppRoute />
  </Box>
)

const App = ({ history }) => {
  const { state, dispatch } = useContext(AppContext)
  const { navId, navOpen, token, staff } = state

  useEffect(() => {
    if (!token.accessToken) {
      history.push('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.accessToken])

  return (
    <Container open={navOpen}>
      <Nav>
        <Brand />
        <Divider />
        <AppList history={history} items={appLinks} staff={staff} />
        <Divider />
        <PageList
          history={history}
          items={pageLinks}
          navId={navId}
          setNavId={id => dispatch({ type: NAV_SET, id })}
        />
      </Nav>
      <Main />
    </Container>
  )
}

export default withRouter(App)

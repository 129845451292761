import React from 'react'
import { Box, Button, Image } from 'rebass'
import ReactTooltip from 'react-tooltip'
import { injectIntl } from 'react-intl'
import Icon from 'components/Icon'
import Overlay from 'components/Overlay'
import { MdDelete, MdTextFields, MdVisibility } from 'react-icons/md'

export default injectIntl(
  ({ intl, src, alt, onView, onEdit, onDelete, ...props }) => {
    return (
      <Box
        m={1}
        width="100px"
        height="120px"
        sx={{
          position: 'relative',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'grey.2'
        }}
        onClick={e => e.stopPropagation()}
        {...props}
      >
        <Image
          src={src}
          alt={alt}
          width={1}
          height="100%"
          sx={{
            objectFit: 'contain',
            opacity: 1,
            transition: '.2s ease',
            backfaceVisibility: 'hidden'
          }}
        />
        <Overlay
          display="flex"
          opacity={0}
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            transition: '.3s ease',
            '&:hover': { opacity: 0.6 }
          }}
        >
          <Button type="button" variant="thumb" onClick={onView}>
            <Icon
              mb={2}
              fontSize={3}
              data-tip={intl.formatMessage({ id: 'btn.preview' })}
            >
              <MdVisibility />
            </Icon>
          </Button>
          <Button type="button" variant="thumb" onClick={onEdit}>
            <Icon
              mb={2}
              fontSize={3}
              data-tip={intl.formatMessage({ id: 'btn.edit.alt' })}
            >
              <MdTextFields />
            </Icon>
          </Button>
          <Button type="button" variant="thumb" onClick={onDelete}>
            <Icon
              mb={2}
              fontSize={3}
              data-tip={intl.formatMessage({ id: 'btn.delete' })}
            >
              <MdDelete />
            </Icon>
          </Button>
        </Overlay>
        <ReactTooltip effect="solid" />
      </Box>
    )
  }
)

import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import App from 'components/App'
import Login from 'views/Login'
import Logout from 'views/Logout'
import Dashboard from 'views/Dashboard'
import CategoryList from 'views/CategoryList'
import WarehouseList from 'views/WarehouseList'
import LocationList from 'views/LocationList'
import LocationPage from 'views/LocationPage'
import StoreList from 'views/StoreList'
import InventoryList from 'views/InventoryList'
import BackorderList from 'views/BackorderList'
import OrderList from 'views/OrderList'
import OrderPage from 'views/OrderPage'
import TrackingNoPage from 'views/TrackingNoPage'
import CustomerList from 'views/CustomerList'
import CustomerPage from 'views/CustomerPage'
import ProductList from 'views/ProductList'
import ProductPage from 'views/ProductPage'
import ProductVariantPage from 'views/ProductVariantPage'
import ProductShelfList from 'views/ProductShelfList'
import PurchaseList from 'views/PurchaseList'
import PurchasePage from 'views/PurchasePage'
import PurchaseAcceptPage from 'views/PurchaseAcceptPage'
import AdjustList from 'views/AdjustList'
import AdjustPage from 'views/AdjustPage'
import MoveList from 'views/MoveList'
import MovePage from 'views/MovePage'
import DiscountList from 'views/DiscountList'
import DiscountPage from 'views/DiscountPage'
import EStorePage from 'views/EStorePage'
import PaymentConfigList from 'views/PaymentConfigList'
import AdList from 'views/AdList'
import AdBlockList from 'views/AdBlockList'
import KeywordList from 'views/KeywordList'
import CategoryAdList from 'views/CategoryAdList'
import ProductAdList from 'views/ProductAdList'
import ProductAdPage from 'views/ProductAdPage'
import SupportList from 'views/SupportList'
import SupportPage from 'views/SupportPage'
import PasswordPage from 'views/PasswordPage'
import StaffList from 'views/StaffList'
import PermissionList from 'views/PermissionList'
import PermissionPage from 'views/PermissionPage'

export const RootRoute = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route path="/" component={App} />
  </Switch>
)

export const AppRoute = () => (
  <Switch>
    <Route path="/dashboard" component={Dashboard} />
    <Route exact path="/password" component={PasswordPage} />
    <Route exact path="/category" component={CategoryList} />
    <Route exact path="/category/ad" component={CategoryAdList} />
    <Route path="/category/:id" component={CategoryList} />
    <Route exact path="/warehouse" component={WarehouseList} />
    <Route exact path="/store" component={StoreList} />
    <Route exact path="/location" component={LocationList} />
    <Route path="/location/:id" component={LocationPage} />
    <Route exact path="/inventory" component={InventoryList} />
    <Route exact path="/backorder" component={BackorderList} />
    <Route exact path="/order" component={OrderList} />
    <Route path="/order/:id" component={OrderPage} />
    <Route path="/trackingNo" component={TrackingNoPage} />
    <Route exact path="/customer" component={CustomerList} />
    <Route path="/customer/add" component={CustomerPage} />
    <Route path="/customer/:id/edit" component={CustomerPage} />
    <Route exact path="/product" component={ProductList} />
    <Route path="/product/add" component={ProductPage} />
    <Route exact path="/product/:id/edit" component={ProductPage} />
    <Route path="/product/:id/variant/add" component={ProductVariantPage} />
    <Route
      exact
      path="/product/:id/variant/:variantId/edit"
      component={ProductVariantPage}
    />
    <Route exact path="/purchase" component={PurchaseList} />
    <Route path="/purchase/add" component={PurchasePage} />
    <Route path="/purchase/:id/edit" component={PurchasePage} />
    <Route path="/purchase/:id/accept" component={PurchaseAcceptPage} />
    <Route exact path="/adjust" component={AdjustList} />
    <Route path="/adjust/add" component={AdjustPage} />
    <Route path="/adjust/:id/edit" component={AdjustPage} />
    <Route exact path="/move" component={MoveList} />
    <Route path="/move/add" component={MovePage} />
    <Route path="/move/:id/edit" component={MovePage} />
    <Route path="/product/shelf" component={ProductShelfList} />
    <Route exact path="/discount" component={DiscountList} />
    <Route path="/discount/add" component={DiscountPage} />
    <Route exact path="/discount/:id/edit" component={DiscountPage} />
    <Route exact path="/ad" component={AdList} />
    <Route exact path="/ad/block" component={AdBlockList} />
    <Route exact path="/product/ad" component={ProductAdList} />
    <Route path="/product/ad/add" component={ProductAdPage} />
    <Route path="/product/ad/:id/edit" component={ProductAdPage} />
    <Route path="/estore" component={EStorePage} />
    <Route path="/payment/config" component={PaymentConfigList} />
    <Route exact path="/support" component={SupportList} />
    <Route path="/support/:id" component={SupportPage} />
    <Route path="/staff" component={StaffList} />
    <Route exact path="/permission" component={PermissionList} />
    <Route path="/permission/add" component={PermissionPage} />
    <Route path="/permission/:id/edit" component={PermissionPage} />
    <Route exact path="/keyword" component={KeywordList} />
    <Redirect from="/" to="/order" />
  </Switch>
)

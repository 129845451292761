import React, { useEffect, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { initialState, fields, handlers } from 'models/supportPage'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import { Box, Flex, Text, Button } from 'rebass'
import Page from 'components/PageContainer'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const { id } = match.params
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())
  const field = fields({ session, app, state, setState, message })
  const handler = handlers({ id, state, setState, session, app })

  useEffect(() => {
    handler.handleLoad({ id, setState, app, session })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderForm = () => (
    <form onSubmit={handler.handleSubmit}>
      {field.message}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        {/* <Popover
          linkProps={{ variant: 'outlineAccent' }}
          icon=""
          label={state.status}
        >
          <Link variant="inverseLink" onClick={() => console.log('hi')}>
            <Message ml={2} id="btn.export" />
          </Link>
        </Popover> */}
        <Flex alignItems="center" mr={3}>
          {/* <Message mr={2} id="support.field.status" /> */}
          {field.status}
        </Flex>
        <Button type="submit" variant="outlineAccent">
          <Message id="btn.submit" />
        </Button>
      </Flex>
    </form>
  )

  const renderMessage = (item) => (
    <Box
      key={item.id}
      mt={4}
      mb={3}
      sx={{
        bg: item.staffName ? 'warning.0' : 'grey.0',
        borderRadius: '5px',
      }}
    >
      <Flex p={3} justifyContent="space-between">
        <Text>{item.userName || item.staffName}</Text>
        <Text>{item.createdAt}</Text>
      </Flex>
      <Text pb={3} px={3}>
        {item.message}
      </Text>
    </Box>
  )

  return (
    <Page
      title="support.title.page"
      navId="supportList"
      actions={
        <LinkButton variant="plain" url="/support">
          <MdArrowBack />
          <Message ml={1} id="btn.back" />
        </LinkButton>
      }
    >
      {renderForm()}
      {state.messages.map((item) => renderMessage(item))}
    </Page>
  )
}

import React, { useRef } from 'react'
import { Box } from 'rebass'
import { useDrag, useDrop, DragPreviewImage } from 'react-dnd'

export default ({ type, index, previewImage, children, onDrag, ...props }) => {
  const ref = useRef(null)
  const [, drag, preview] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  })
  const [, drop] = useDrop({
    accept: type,
    hover(item, monitor) {
      if (!ref.current) return

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return

      // Determine rectangle on screen
      const { top, bottom, left, right } = ref.current.getBoundingClientRect()

      const hoverMiddleY = (bottom - top) / 2
      const hoverMiddleX = (right - left) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = clientOffset.y - top
      const hoverClientX = clientOffset.x - left

      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY &&
        hoverClientX < hoverMiddleX
      ) {
        return
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY &&
        hoverClientX > hoverMiddleX
      ) {
        return
      }

      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  drag(drop(ref))
  return (
    <>
      <DragPreviewImage connect={preview} src={previewImage} />
      <Box ref={ref} {...props}>
        {children}
      </Box>
    </>
  )
}

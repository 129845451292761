import React, {
  forwardRef,
  useState,
  useContext,
  useEffect,
  useImperativeHandle,
} from 'react'
import { useIntl } from 'react-intl'
import { Flex, Box, Text, Button } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Select from 'components/Select'
import Chip from 'components/Chip'
import Pagination from 'components/Pagination'
import { createExcel } from 'utilities/excelUtil'
import { MdSearch, MdKeyboardArrowDown } from 'react-icons/md'
import { FaInbox } from 'react-icons/fa'

const Table = ({ children }) => (
  <Box as="table" width={1} sx={{ borderCollapse: 'collapse' }}>
    {children}
  </Box>
)

const Row = ({ index, children, ...props }) => (
  <Box as="tr" bg={index % 2 === 0 ? 'grey.1' : 'grey.0'} {...props}>
    {children}
  </Box>
)

const EmptyRow = ({ columnCount }) => (
  <Box as="tr" bg="grey.1">
    <Cell
      colSpan={columnCount}
      color="grey.3"
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'grey.3',
        textAlign: 'center',
      }}
    >
      <FaInbox fontSize={48} />
      <Text>No Data</Text>
    </Cell>
  </Box>
)

const HeaderCell = ({ message, label, align, children }) => (
  <Box
    as="th"
    py={3}
    px={2}
    sx={{
      borderBottom: 'none',
      textAlign: align || 'left',
      fontWeight: 300,
      whiteSpace: 'nowrap',
    }}
  >
    {children ? children : label && message({ id: label })}
  </Box>
)

const Cell = ({ align, width, children, ...props }) => (
  <Box
    as="td"
    py={3}
    px={2}
    width={width || 'auto'}
    color="grey.5"
    sx={{
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'grey.3',
      textAlign: align || 'left',
    }}
    {...props}
  >
    {children}
  </Box>
)

const getDefaultFilter = (filters, message) => {
  if (filters.length === 0) return {}

  const defaultFilter = filters[0]
  return {
    value: defaultFilter.id,
    label: message({ id: defaultFilter.label }),
  }
}

const DataTable = (
  {
    match,
    title,
    footer,
    rowProps,
    cellProps,
    onLoad,
    values,
    actions,
    ...props
  },
  ref
) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])
  const { formatMessage: message } = useIntl()
  const columns = props.columns({ session, app, actions, message })
  const data = state.data || state
  const { pagination, nextPage, countPerPage } = state
  const [filterOp, setFilterOp] = useState('')
  const [filterInput, setFilterInput] = useState('')
  const filters = props.filters
    ? props.filters({
        message,
        filterOp,
        setFilterOp,
        filterInput,
        setFilterInput,
      })
    : []
  const [filterSelect, setFilterSelect] = useState(
    getDefaultFilter(filters, message)
  )
  const [filter, setFilter] = useState([])

  useEffect(() => {
    onLoad({ match, session, app, state, setState, values, actions })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(ref, () => ({
    refresh: (values) => {
      onLoad({ match, session, app, state, setState, values, actions, filter })
    },
    export: () => {
      const setExcel = (rows) => createExcel({ message, title, columns, rows })
      onLoad({
        match,
        session,
        app,
        state,
        setState,
        values,
        actions,
        setExcel,
      })
    },
    getFilter: () => filter,
  }))

  const renderCell = (column, row, index) => {
    if (column.render) {
      return column.render({ row, index, session, app, state, setState })
    }
    return <Text>{row[column.id]}</Text>
  }

  const renderFilterSelector = () => (
    <Select
      containerProps={{ mb: [2, 0], mr: [0, 3], flex: 1, width: 1 }}
      isSearchable={false}
      isClearable={false}
      options={filters.map((item) => ({
        value: item.id,
        label: message({ id: item.label }),
      }))}
      value={filterSelect}
      onChange={(value) => {
        setFilterOp('')
        setFilterInput('')
        setFilterSelect(value)
      }}
    />
  )

  const renderFilterInput = () =>
    filters.find((item) => item.id === filterSelect.value).input

  const renderFilterOp = () =>
    filters.find((item) => item.id === filterSelect.value).op

  const renderFilterButton = () => (
    <Button
      variant="plain"
      onClick={() => {
        if (!filterInput) return

        const hasFilter = filter.some((item) => isFilterEqual(item))
        if (hasFilter) return

        const newFilter = [
          ...filter,
          {
            id: filterSelect.value,
            op: filterOp.value,
            value: filterInput,
          },
        ]
        setFilter(newFilter)
        setFilterOp('')
        setFilterInput('')

        onLoad({
          match,
          session,
          app,
          state,
          setState,
          values,
          actions,
          filter: newFilter,
        })
      }}
    >
      <MdSearch size="24px" />
    </Button>
  )

  const isFilterEqual = (value) => {
    const filter = filters.find((item) => item.id === value.id)
    const filterVal = value.value
    return (
      value.id === filterSelect.value &&
      (!value.op || value.op === filterOp) &&
      (filter.getLabel ? filter.getLabel(filterVal) : filterVal) === filterInput
    )
  }

  const getFilterLabel = (value) => {
    const filter = filters.find((item) => item.id === value.id)
    if (filter.getLabel) {
      return filter.getLabel(value)
    }

    return (
      <Flex alignItems="center" sx={{ lineHeight: 0 }}>
        {message({ id: filter.label })}
        <Text px={1}>{message({ id: 'filter.op.eq' })}</Text>
        {value.value}
      </Flex>
    )
  }

  const hasNext = () => {
    if (data.length === 0) return false
    if (data.length < countPerPage) return false
    if (!nextPage) return false
    return true
  }

  return (
    <>
      {filters && filters.length > 0 && (
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          px={2}
          width={1}
          bg="grey.0"
        >
          {renderFilterSelector()}
          <Flex flex={[1, 3]} width={1} alignItems="center">
            {renderFilterOp()}
            {renderFilterInput()}
          </Flex>
          {renderFilterButton()}
        </Flex>
      )}
      {filter.length > 0 && (
        <Flex alignItems="center" p={2} width={1} bg="grey.0">
          {filter.map((item, index) => (
            <Chip
              mr={2}
              key={index}
              text={getFilterLabel(item)}
              onDelete={() => {
                const newFilter = [...filter]
                newFilter.splice(index, 1)
                setFilter(newFilter)
                onLoad({
                  match,
                  session,
                  app,
                  state,
                  setState,
                  values,
                  actions,
                  filter: newFilter,
                })
              }}
            />
          ))}
        </Flex>
      )}
      <Box sx={{ overflowX: 'auto' }}>
        <Table {...props}>
          <thead>
            <Row>
              {columns.map((column) => (
                <HeaderCell
                  message={message}
                  key={column.id}
                  align={column.align}
                  label={column.label}
                />
              ))}
            </Row>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((row, index) => (
                <Row key={index} index={index} {...rowProps}>
                  {columns.map((column) => (
                    <Cell
                      key={column.id}
                      align={column.align}
                      width={column.width}
                      {...cellProps}
                    >
                      {renderCell(column, row, index)}
                    </Cell>
                  ))}
                </Row>
              ))
            ) : (
              <EmptyRow columnCount={columns.length} />
            )}
          </tbody>
          {footer && <tfoot>{footer}</tfoot>}
        </Table>
      </Box>
      {hasNext() && (
        <Flex
          justifyContent="center"
          pt={3}
          px={2}
          width={1}
          bg="grey.0"
          sx={{
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: 'grey.3',
          }}
        >
          <Button
            type="button"
            variant="plain"
            disabled={data.length === 0}
            onClick={() => {
              onLoad({
                match,
                session,
                app,
                state,
                setState,
                values,
                actions,
                filter,
                page: nextPage,
                isAppend: true,
              })
            }}
            py={0}
            sx={{ lineHeight: 0 }}
          >
            <Flex alignItems="center">
              <MdKeyboardArrowDown size="28px" />
              {message({ id: 'btn.more' })}
            </Flex>
          </Button>
        </Flex>
      )}
      {pagination && pagination.totalPage > 1 && (
        <Box
          pt={3}
          px={2}
          width={1}
          bg="grey.0"
          sx={{
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: 'grey.3',
          }}
        >
          <Pagination
            value={pagination}
            onClick={(page) => {
              onLoad({
                match,
                session,
                app,
                state,
                setState,
                values,
                actions,
                filter,
                page,
              })
            }}
          />
        </Box>
      )}
    </>
  )
}

export default forwardRef(DataTable)

import React, { useState, useContext, useEffect } from 'react'
import { Flex, Box, Text } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import LinkButton from 'components/LinkButton'
import Table from 'components/Table'
import Message from 'components/Message'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/customerPage'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState({ customer: {} })
  const { customer, customerTickets } = state
  const { phones = [], addresses = [] } = customer
  const customerId = match.params.id

  useEffect(() => {
    model.onLoad({ setState, app, session, customerId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId])

  return (
    <Page
      title={`customer.title.${customerId ? 'view' : 'add'}`}
      navId="customerList"
      actions={
        <LinkButton variant="plain" url="/order">
          <MdArrowBack />
          <Message ml={1} id="btn.back" />
        </LinkButton>
      }
    >
      <Box
        mt={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 2fr'],
          gridGap: 3,
        }}
      >
        <Box>
          <Section title="customer.section.basic">
            <Flex>
              <Message flex={1} id="customer.field.name" color="grey.5" />
              <Text flex={3} mt={1} mb={3}>
                {customer.name}
              </Text>
            </Flex>
            <Flex>
              <Message flex={1} id="customer.field.phone" color="grey.5" />
              {phones.map((item) => (
                <Text key={item} flex={3} mt={1} mb={3}>
                  {item}
                </Text>
              ))}
            </Flex>
            <Flex>
              <Message flex={1} id="customer.field.address" color="grey.5" />
              <Box flex={3} mt={1}>
                {addresses.map((item) => (
                  <Text key={item} mb={2}>
                    {item}
                  </Text>
                ))}
              </Box>
            </Flex>
          </Section>
        </Box>
        <Section title="customer.section.ticket">
          <Table
            columns={[
              {
                id: 'id',
                label: 'customer.field.ticketId',
              },
              {
                id: 'transDate',
                label: 'customer.field.transDate',
              },
              {
                id: 'price',
                label: 'customer.field.price',
              },
            ]}
            rows={customerTickets}
          />
        </Section>
      </Box>
    </Page>
  )
}

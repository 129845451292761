import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Button } from 'rebass'
import Image from 'components/Image'
import Modal from 'components/Modal'

export default ({ images, value, open, onConfirm, onCancel }) => {
  const [state, setState] = useState(value || {})

  useEffect(() => {
    setState(value)
  }, [value])

  const isActive = image => {
    if (!state || !state.image) return false
    return state.image.src === image.src
  }

  return (
    <Modal
      open={open}
      width={['100%', 600]}
      contentProps={{ titleProps: { py: 3, flex: 1 } }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr 1fr'],
          justifyItems: 'center',
          gridGap: 3
        }}
      >
        {images.map(image => (
          <Image
            key={image.src}
            src={image.src}
            alt={image.alt}
            width={1}
            height={150}
            sx={{
              objectFit: 'contain',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: isActive(image) ? 'accent.1' : 'grey.2',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
            onClick={() => setState({ ...state, image })}
          />
        ))}
      </Box>
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          mr={2}
          type="button"
          variant="outlineSecondary"
          onClick={onCancel}
        >
          <FormattedMessage id="btn.close" />
        </Button>
        <Button
          type="button"
          variant="outlineAccent"
          onClick={() => onConfirm(state)}
        >
          <FormattedMessage id="btn.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}

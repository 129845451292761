import { request } from 'utilities/requestUtil'

export const onLoad = async ({ setState, app, session, customerId }) => {
  const data = await getData({ app, session, customerId })
  setState(data)
}

async function getData({ app, session, customerId }) {
  const variables = { id: customerId }
  const query = `
    query($id: ID!) {
      customer(id: $id) {
        id
        name
        phones
        addresses
      }
      customerTickets(customerId: $id) {
        id
        ticketType
        transDate
        price
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  return data
}

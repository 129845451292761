import React from 'react'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import TextInput from 'components/TextInput'
import { TOKEN_SET, STAFF_SET } from 'constants/actionType'

export const initialState = () =>
  initializeState({
    username: '',
    password: ''
  })

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ state, setState }) => {
  const handleChange = id => handleTextChange(id, state, setState, validation)
  return {
    username: (
      <TextInput
        id="username"
        placeholder="login.field.username"
        value={state.username}
        onChange={handleChange('username')}
        errMsg={state.__error__.username}
        py={3}
      />
    ),
    password: (
      <TextInput
        id="password"
        type="password"
        placeholder="login.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={handleChange('password')}
        errMsg={state.__error__.password}
        py={3}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, history }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const { username, password } = state
    const variables = { username, password }
    const query = `
      mutation ($username: String!, $password: String!) {
        createStaffToken(username: $username, password: $password) {
          accessToken
          refreshToken
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session })
    if (!ok) return

    const token = data.createStaffToken
    const staff = await getStaff({ token })
    app.dispatch({ type: STAFF_SET, staff })

    app.dispatch({ type: TOKEN_SET, token })
    history.push('/dashboard')
  }
})

async function getStaff({ token }) {
  const query = `
    query {
      staff {
        id
        merchantId
        username
      }
    }
  `
  const [ok, data] = await request({ query }, { token })
  return ok ? data.staff : {}
}

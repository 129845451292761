import React, { useRef } from 'react'
import { Box, Button, Text } from 'rebass'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/adjustPage'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const { id } = match.params
  const refs = {
    specRef: useRef(null)
  }
  return (
    <Form {...model} match={match} refs={refs}>
      {({ form, action, state }) => (
        <Page
          title={`adjust.title.${id ? 'edit' : 'add'}`}
          navId="adjustList"
          actions={
            <>
              <LinkButton variant="plain" url="/adjust">
                <MdArrowBack />
                <Message ml={1} id="btn.back" />
              </LinkButton>
              {!state.id && (
                <Button variant="outlineAccent">
                  <Message id="btn.save" />
                </Button>
              )}
            </>
          }
        >
          <Box
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 2fr'],
              gridTemplateRows: '1fr',
              gridGap: 3
            }}
          >
            <Section title="adjust.section.basic" mb={[3, 0]}>
              {state.id ? (
                <>
                  <Message id="adjust.field.id" color="grey.4" />
                  <Text mt={1} mb={3}>
                    {state.id}
                  </Text>
                  <Message id="adjust.field.transDate" color="grey.4" />
                  <Text mt={1} mb={3}>
                    {state.transDate}
                  </Text>
                </>
              ) : (
                form.transDate
              )}
            </Section>
            <Section title="adjust.section.product">
              {state.id ? (
                form.ticket
              ) : (
                <>
                  {form.productFilter}
                  {form.product}
                </>
              )}
            </Section>
          </Box>
        </Page>
      )}
    </Form>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'rebass'
import Page from 'components/PageContainer'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Breadcrumb from 'components/Breadcrumb'
import LocationModal from 'views/LocationModal'
import * as model from 'models/locationPage'

export default ({ match }) => {
  const [open, setOpen] = useState(false)
  const [location, setLocation] = useState(null)
  const [value, setValue] = useState({})
  const ref = useRef(null)

  const handleOpen = item => {
    setValue({ ...item, parentLocationId: location.id })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleRefresh = () => {
    ref.current.refresh()
  }

  useEffect(() => {
    handleRefresh()
  }, [match.params.id])

  return (
    <Page
      title="location.title.list"
      navId="locationList"
      actions={
        <Button
          type="button"
          variant="outlineSecondary"
          onClick={() => handleOpen({})}
        >
          <FormattedMessage id="location.title.add" />
        </Button>
      }
    >
      <Section title="location.section.path" mb={4}>
        <Breadcrumb items={getBreadcrumb(location)} />
      </Section>
      <Section>
        <DataTable
          ref={ref}
          match={match}
          actions={{ handleOpen, handleRefresh, setLocation }}
          {...model}
        />
      </Section>
      <LocationModal
        open={open}
        value={value}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

function getBreadcrumb(location) {
  if (!location) return null

  if (!location.path) {
    return [{ label: location.name }]
  }

  const breadcrumb = location.path.map(item => ({
    url: `/location/${item.id}`,
    label: item.name
  }))

  breadcrumb.push({ label: location.name })
  return breadcrumb
}

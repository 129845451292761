import React, { useRef } from 'react'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Page from 'components/PageContainer'
import * as model from 'models/inventoryList'

export default () => {
  const ref = useRef(null)
  const handleRefresh = () => {
    ref.current.refresh()
  }
  return (
    <Page title="inventory.title.list" navId="inventoryList">
      <Section>
        <DataTable ref={ref} actions={{ handleRefresh }} {...model} />
      </Section>
    </Page>
  )
}

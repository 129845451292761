import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    channel: value.channel || '',
    merchantId: value.merchantId || '',
    key: value.key || '',
    secret: value.secret || ''
  })

const validation = {
  channel: [{ type: 'required', message: 'error.required' }],
  key: [{ type: 'required', message: 'error.required' }],
  secret: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ message, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  const { channel } = state
  return {
    // channel: (
    //   <Select
    //     id="channel"
    //     isSearchable={false}
    //     label="paymentConfig.field.channel"
    //     placeholder="paymentConfig.field.channel"
    //     options={[
    //       {
    //         value: 'ECPAY',
    //         label: message({ id: 'paymentConfig.channel.ECPAY' })
    //       },
    //       {
    //         value: 'LINEPAY',
    //         label: message({ id: 'paymentConfig.channel.LINEPAY' })
    //       }
    //     ]}
    //     value={state.channel}
    //     onChange={handleSelectChange('channel', state, setState, validation)}
    //   />
    // ),
    merchantId: channel === 'ECPAY' && (
      <TextInput
        id="merchantId"
        label="paymentConfig.field.merchantId"
        placeholder="paymentConfig.field.merchantId"
        value={state.merchantId}
        onChange={onTextChange('merchantId')}
        errMsg={state.__error__.merchantId}
      />
    ),
    key: (
      <TextInput
        id="key"
        label={getKeyLabel(channel)}
        placeholder={getKeyLabel(channel)}
        value={state.key}
        onChange={onTextChange('key')}
        errMsg={state.__error__.key}
      />
    ),
    secret: (
      <TextInput
        id="secret"
        type="password"
        label={getSecretLabel(channel)}
        placeholder={getSecretLabel(channel)}
        value={state.secret}
        onChange={onTextChange('secret')}
        errMsg={state.__error__.secret}
      />
    )
  }
}

function getKeyLabel(channel) {
  if (!channel) return 'paymentConfig.field.key'
  return `paymentConfig.field.${channel}.key`
}

function getSecretLabel(channel) {
  if (!channel) return 'paymentConfig.field.secret'
  return `paymentConfig.field.${channel}.secret`
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addPaymentConfig(state, app, session)
    if (!ok) return

    actions.handleRefresh()
    actions.handleClose()
    setState(initialState())
  }
})

async function addPaymentConfig(value, app, session) {
  const variables = {
    input: {
      channelProvider: value.channel,
      merchantId: value.merchantId,
      key: value.key,
      secret: value.secret
    }
  }
  const query = `
    mutation($input: PaymentConfigInput!) {
      addPaymentConfig(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

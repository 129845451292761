import React from 'react'
import { Flex, Button } from 'rebass'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

export default ({ value, onClick }) => {
  const { pages, page, prevPage, nextPage } = value

  const renderPages = () =>
    pages.map(item => {
      const isActive = page === item
      return (
        <Button
          key={`page-${item}`}
          type="button"
          variant={isActive ? 'secondary' : 'plain'}
          onClick={() => onClick(item)}
        >
          {item}
        </Button>
      )
    })

  const renderPrev = () => {
    const startPage = pages[0]
    return (
      <Button
        type="button"
        variant="plain"
        disabled={page === startPage}
        onClick={() => onClick(prevPage)}
        py={0}
        sx={{ lineHeight: 0 }}
      >
        <MdKeyboardArrowLeft size="28px" />
      </Button>
    )
  }

  const renderNext = () => {
    const endPage = pages[pages.length - 1]
    return (
      <Button
        type="button"
        variant="plain"
        disabled={page === endPage}
        onClick={() => onClick(nextPage)}
        py={0}
        sx={{ lineHeight: 0 }}
      >
        <MdKeyboardArrowRight size="28px" />
      </Button>
    )
  }

  return (
    <Flex justifyContent="center">
      {renderPrev()}
      {renderPages()}
      {renderNext()}
    </Flex>
  )
}

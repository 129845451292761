import React from 'react'
import { handleTextChange, initializeState } from 'utilities/formUtil'
import TextInput from 'components/TextInput'

export const initialState = (value = {}) => ({
  shippingProvider: value.name,
  ...initializeState({
    shippingFee: value.shippingFee || 0,
  }),
})

const validation = {
  shippingFee: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    shippingFee: (
      <TextInput
        id="shippingFee"
        type="number"
        min={0}
        label="product.field.shippingFee"
        value={state.shippingFee}
        onChange={onTextChange('shippingFee')}
        errMsg={state.__error__.shippingFee}
      />
    ),
    // isFeeWaived: (
    //   <Flex
    //     alignItems={'center'}
    //     onClick={() => {
    //       setState({ ...state, isFeeWaived: !state.isFeeWaived })
    //     }}
    //     sx={{
    //       cursor: 'pointer',
    //       userSelect: 'none',
    //     }}
    //   >
    //     <Box
    //       color={state.isFeeWaived ? 'accent.1' : 'grey.4'}
    //       fontSize={4}
    //       lineHeight={0}
    //       mr={1}
    //       sx={{
    //         '&:hover': {
    //           color: 'accent.1',
    //         },
    //       }}
    //     >
    //       {state.isFeeWaived ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
    //     </Box>
    //     <Message id="product.field.isFeeWaived" />
    //   </Flex>
    // ),
  }
}

export const handlers = ({ state, actions }) => ({
  handleConfirm: async (event) => {
    event.preventDefault()

    actions.handleSubmit(state)
  },
})

import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Flex, Button } from 'rebass'
import { createExcel } from 'utilities/excelUtil'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Link from 'components/Link'
import LinkButton from 'components/LinkButton'
import Message from 'components/Message'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import FileInput from 'components/FileInput'
import Table from 'components/Table'
import { initialState, fields, handlers } from 'models/trackingNoPage'
import { MdArrowBack } from 'react-icons/md'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [disabled, setDisabled] = useState(true)
  const [state, setState] = useState(initialState())
  const [errors, setErrors] = useState([])
  const form = fields({ state, setState, message })
  const action = handlers({
    app,
    session,
    state,
    setState,
    setDisabled,
    message,
  })

  const downloadTemplate = () => {
    const title = 'trackingNo.title.list'
    const columns = [
      {
        id: 'ticketId',
        label: 'trackingNo.field.ticketId',
      },
      {
        id: 'trackingNo',
        label: 'trackingNo.field.trackingNo',
      },
    ]
    createExcel({ message, title, columns, rows: [] })
  }

  const renderResult = () => {
    if (errors.length === 0) return null

    return (
      <Section
        variant="warningOutline"
        mb={4}
        title="trackingNo.section.error"
        titleProps={{ variant: 'warning' }}
      >
        <Table
          columns={[
            {
              id: 'ticketId',
              label: 'trackingNo.field.ticketId',
            },
            {
              id: 'errMsg',
              label: 'trackingNo.field.errMsg',
              render: ({ row }) => <Message id={row.errMsg} />,
            },
          ]}
          rows={errors}
        />
      </Section>
    )
  }

  return (
    <Page
      title="trackingNo.title.list"
      navId="orderList"
      actions={
        <>
          <LinkButton variant="plain" url="/order">
            <MdArrowBack />
            <Message ml={1} id="btn.back" />
          </LinkButton>
          <Button
            disabled={disabled}
            variant="outlineAccent"
            onClick={() => action.handleSubmit({ setResult: setErrors })}
          >
            <Message id="btn.save" />
          </Button>
        </>
      }
    >
      {renderResult()}
      <Section>
        <Flex alignItems="center" mb={2}>
          <Message id="trackingNo.message.template" />
          <Link variant="link" onClick={downloadTemplate}>
            <Message ml={2} id="btn.download" />
          </Link>
        </Flex>
        <FileInput accept=".xlsx" onUpload={action.loadExcel} />
      </Section>
      <Section mt={4}>
        {form.trackingNo}
        <Flex justifyContent="center" alignItems="center" mt={3}>
          <Button
            type="button"
            variant="outlineSecondary"
            onClick={() => action.addTrackingNo()}
          >
            <Message id="trackingNo.btn.add" />
          </Button>
        </Flex>
      </Section>
    </Page>
  )
}

import React, { useContext } from 'react'
import { Flex, Box } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import { ALERT_REMOVE } from 'constants/actionType'
import Message from 'components/Message'
import { MdClose } from 'react-icons/md'

const Wrapper = ({ hidden, children }) => (
  <Box
    display={hidden ? 'none' : 'block'}
    width={['80%', '20%']}
    ml={['-40%', '-10%']}
    overflow="hidden"
    sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      zIndex: 10000
    }}
  >
    {children}
  </Box>
)

const Close = ({ onClick }) => (
  <Flex as="a" onClick={onClick} sx={{ fontSize: 4, cursor: 'pointer' }}>
    <MdClose />
  </Flex>
)

export default ({ values }) => {
  const { dispatch } = useContext(SessionContext)
  const handleClick = idx => () => dispatch({ type: ALERT_REMOVE, idx })

  return (
    <Wrapper hidden={values.length === 0}>
      {values.map((value, idx) => {
        setTimeout(handleClick(idx), 5000)
        return (
          <Flex key={idx} variant={value.type} alignItems="center" m={3} p={3}>
            {getMessage(value.message)}
            <Close onClick={handleClick(idx)} />
          </Flex>
        )
      })}
    </Wrapper>
  )
}

const getMessage = message => {
  if (typeof message === 'string') return <Message flex={1} id={message} />

  return <Message flex={1} id={message.id} values={message.values} />
}

import React from 'react'
import { Box, Flex, Text } from 'rebass'
import Image from 'components/Image'
import ConfirmButton from 'components/ConfirmButton'
import { request } from 'utilities/requestUtil'
import { listToTree } from 'utilities/listUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, states, actions }) => [
  {
    id: 'linkUrl',
    render: ({ row }) => (
      <Flex alignItems="center">
        <Image
          src={row.imageUrl}
          alt={row.title}
          height="10rem"
          width="auto"
          sx={{ objectFit: 'contain' }}
        />
        <Box px={3} sx={{ whiteSpace: 'nowrap' }}>
          <Text fontSize={3}>{row.title}</Text>
          <Text color="grey.4">{row.linkUrl}</Text>
        </Box>
      </Flex>
    )
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <ConfirmButton
        title="categoryAd.title.delete"
        message="categoryAd.message.delete"
        onClick={handleDelete({ session, app, id: row.id, states, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const onLoad = async ({ setCategories, session, app }) => {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query($merchantId: ID!) {
      categories(merchantId: $merchantId) {
        id
        parentCategoryId
        name
        images
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setCategories(data.categories)
}

const handleDelete = ({
  session,
  app,
  id,
  states,
  actions
}) => async handleClose => {
  const { category } = states
  const variables = { id: category.id, imageId: id }
  const query = `
    mutation($id: ID!, $imageId: ID!) {
      deleteCategoryImage(id: $id, imageId: $imageId)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

export const createCategoryTree = categories => {
  if (!categories || categories.length === 0) return []
  return listToTree(categories, { parentIdKey: 'parentCategoryId' })
}

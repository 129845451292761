import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Text } from 'rebass'
import Link from 'components/Link'
import Collapsible from 'components/Collapsible'
import IconWrapper from 'components/Icon'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

const ListGroup = ({
  label,
  labelProps,
  icon,
  active,
  children,
  variant,
  formatMessage,
  textSx,
  containerSx,
  ...props
}) => {
  const [open, setOpen] = useState(active)

  useEffect(() => {
    setOpen(active)
  }, [active])

  return (
    <Flex as="li" flexDirection="column" sx={containerSx}>
      <Link variant={variant} onClick={() => setOpen(!open)} {...props}>
        <IconWrapper mr={3}>{icon}</IconWrapper>
        <Text flex="1" fontSize={1} sx={textSx}>
          {formatMessage ? (
            <FormattedMessage id={label} {...labelProps} />
          ) : (
            label
          )}
        </Text>
        <IconWrapper ml={3}>
          {open ? <MdArrowDropUp /> : <MdArrowDropDown />}
        </IconWrapper>
      </Link>
      <Collapsible open={open}>{children}</Collapsible>
    </Flex>
  )
}

ListGroup.defaultProps = {
  formatMessage: true,
}

export default ListGroup

import React, { useRef } from 'react'
import { Box, Button, Text } from 'rebass'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/purchaseAcceptPage'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const { id } = match.params
  const refs = {
    specRef: useRef(null),
  }

  return (
    <Form {...model} match={match} refs={refs}>
      {({ form, state }) => (
        <Page
          title="purchase.title.accept"
          navId="purchaseList"
          actions={
            <>
              <LinkButton variant="plain" url={`/purchase/${id}/edit`}>
                <MdArrowBack />
                <Message ml={1} id="btn.back" />
              </LinkButton>
              <Button variant="outlineAccent">
                <Message id="btn.save" />
              </Button>
            </>
          }
        >
          <Box
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 2fr'],
              gridTemplateRows: '1fr',
              gridGap: 3,
            }}
          >
            <Section title="purchase.section.basic" mb={[3, 0]}>
              {state.id ? (
                <>
                  <Message id="purchase.field.id" color="grey.4" />
                  <Text mt={1} mb={3}>
                    {state.id}
                  </Text>
                  <Message id="purchase.field.transDate" color="grey.4" />
                  <Text mt={1} mb={3}>
                    {state.transDate}
                  </Text>
                </>
              ) : (
                form.transDate
              )}
            </Section>
            <Section title="purchase.section.product">
              {form.ticketItems}
            </Section>
          </Box>
        </Page>
      )}
    </Form>
  )
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button } from 'rebass'
import Message from 'components/Message'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/estorePage'

export default ({ match }) => {
  return (
    <Form {...model} match={match}>
      {({ form, state, action }) => (
        <Page
          title="estore.title"
          navId="estore"
          actions={
            <Button variant="outlineAccent">
              <FormattedMessage id="btn.confirm" />
            </Button>
          }
        >
          <Box
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '2fr 1fr'],
              gridGap: 3,
            }}
          >
            <Box>
              <Section title="estore.section.basic">
                {form.name}
                {form.companyName}
                {form.companyEmail}
                {form.companyPhone}
                {form.companyAddress}
              </Section>
            </Box>
            <Box>
              <Section title="estore.section.logo">
                <Message mb={2} id="estore.field.headerLogoUrl" />
                {form.headerLogoUrl}
                <Message mt={3} mb={2} id="estore.field.footerLogoUrl" />
                {form.footerLogoUrl}
              </Section>
            </Box>
          </Box>
        </Page>
      )}
    </Form>
  )
}

import React from 'react'
import { Flex } from 'rebass'

export default ({ children, sx, ...props }) => {
  if (!children) {
    return null
  }

  return (
    <Flex
      width="30px"
      height="30px"
      justifyContent="center"
      alignItems="center"
      fontSize={4}
      {...props}
    >
      {children}
    </Flex>
  )
}

import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import { initialState, fields, handlers } from 'models/productShippingModal'

export default ({ value, open, actions }) => {
  const [state, setState] = useState(initialState(value))
  const action = handlers({ state, setState, actions })
  const form = fields({ state, setState })

  useEffect(() => {
    setState(initialState(value))
  }, [value])

  if (!value || !state.shippingProvider) return null

  return (
    <Modal
      title={`product.title.shipping.${state.shippingProvider}`}
      open={open}
      onClose={actions.handleClose}
      width={['100%', 600]}
      contentProps={{ titleProps: { py: 3, flex: 1 } }}
    >
      <Box mt={3}>{form.shippingFee}</Box>
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          mr={2}
          type="button"
          variant="outlineSecondary"
          onClick={actions.handleClose}
        >
          <FormattedMessage id="btn.close" />
        </Button>
        <Button
          type="button"
          variant="outlineAccent"
          onClick={action.handleConfirm}
        >
          <FormattedMessage id="btn.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Button, Link } from 'rebass'
import BgImage from 'components/BgImage'
import Section from 'components/Section'
import Form from 'components/Form'
import Page from 'components/Page'
import * as model from 'models/login'
import bgImg from 'assets/img/background.jpg'

const Login = () => (
  <Page title="login.title">
    <BgImage
      src={bgImg}
      styles={{
        content: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }}
    >
      <Section
        title="app.name"
        titleProps={{ flex: 1, py: 3 }}
        contentProps={{ pt: 2 }}
        width="300px"
      >
        <Form {...model}>
          {({ form }) => (
            <>
              {form.username}
              {form.password}
              <Flex justifyContent="center" alignItems="center" mt={3}>
                <Button type="submit" variant="outlineSecondary">
                  <FormattedMessage id="login.btn.login" />
                </Button>
              </Flex>
            </>
          )}
        </Form>
      </Section>
    </BgImage>
    <Flex
      justifyContent="center"
      width={1}
      sx={{ position: 'fixed', bottom: 0, left: 0, zIndex: 100 }}
    >
      <Link
        p={4}
        color="grey.3"
        // href="https://shop.mr-14.com"
        // target="_blank"
        sx={{ textDecoration: 'none' }}
      >
        &copy; 2020 藍侃科技有限公司. All rights reserved.
      </Link>
    </Flex>
  </Page>
)

export default Login

import React from 'react'
import { Text } from 'rebass'
import ConfirmButton from 'components/Icon'
import Link from 'components/Link'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'id',
    label: 'move.field.id',
    render: ({ row }) => (
      <Link url={`/move/${row.id}/edit`}>
        <Text variant="link">{row.id}</Text>
      </Link>
    )
  },
  {
    id: 'toLocation',
    label: 'move.field.toLocation'
  },
  {
    id: 'transDate',
    label: 'move.field.transDate'
  },
  {
    id: 'status',
    label: 'move.field.status'
  },
  {
    id: 'actions',
    render: ({ row }) => (
      <ConfirmButton
        title="move.title.delete"
        message="move.message.delete"
        onClick={handleDelete({ session, app, id: row.id, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const onLoad = async ({ setState, session, app }) => {
  const { merchantId } = app.state.staff
  const variables = { merchantId, input: { ticketType: 'MOVE' } }
  const query = `
    query($merchantId: ID, $input: TicketQueryInput) {
      tickets(merchantId: $merchantId, input: $input) {
        id
        toLocation
        transDate
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.tickets)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
        mutation ($id: ID!) {
          deleteTicket(id: $id)
        }
      `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

import React from 'react'
import { injectIntl } from 'react-intl'
import ReactSelect from 'react-select/creatable'
import FormField from 'components/FormField'

const styles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    borderColor: isFocused ? '#9c27b0' : styles.borderColor,
    boxShadow: 'none',
    '&:hover': {
      borderColor: isFocused ? '#9c27b0' : styles.borderColor
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#9c27b0'
      : isFocused
      ? '#efefef'
      : null,
    color: isDisabled ? '#d0d0d0' : isSelected ? '#fff' : '#000',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled && (isSelected ? '#9c27b0' : '#b761c5'),
      color: isDisabled ? '#d0d0d0' : '#fff'
    }
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '8px'
  }),
  input: styles => ({
    ...styles,
    padding: 0,
    margin: '1px 0',
    lineHeight: 1
  })
}

// const handleChange = (newValue, actionMeta) => {
//   console.group('Value Changed')
//   console.log(newValue)
//   console.log(`action: ${actionMeta.action}`)
//   console.groupEnd()
// }

// const handleInputChange = (inputValue, actionMeta) => {
//   console.group('Input Changed')
//   console.log(inputValue)
//   console.log(`action: ${actionMeta.action}`)
//   console.groupEnd()
// }

export default injectIntl(
  ({ intl, id, label, placeholder, errMsg, options, ...props }) => (
    <FormField id={id} label={label} errMsg={errMsg}>
      <ReactSelect
        isClearable
        formatCreateLabel={name =>
          intl.formatMessage({ id: 'placeholder.select.create' }, { name })
        }
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        options={options}
        styles={styles}
        {...props}
      />
    </FormField>
  )
)

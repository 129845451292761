import React from 'react'
import { Text } from 'rebass'
import ConfirmButton from 'components/ConfirmButton'
import Link from 'components/Link'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'name',
    label: 'location.field.name',
    render: ({ row }) => (
      <Link url={`/location/${row.id}`}>
        <Text variant="link">{row.name}</Text>
      </Link>
    )
  },
  {
    id: 'actions',
    render: ({ row }) => (
      <ConfirmButton
        title="location.title.delete"
        message="location.message.delete"
        onClick={handleDelete({ session, app, id: row.id, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const onLoad = async ({ setState, session, app }) => {
  const { merchantId } = app.state.staff
  const variables = {
    merchantId,
    input: { type: 'VIRTUAL', parentLocationId: null }
  }
  const query = `
    query ($merchantId: ID!, $input: LocationQueryInput) {
      locations(merchantId: $merchantId, input: $input) {
        id
        name
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  setState(data.locations)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
      mutation ($id: ID!) {
        deleteLocation(id: $id)
      }
    `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

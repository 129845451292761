import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'rebass'
import * as model from 'models/staffList'
import Message from 'components/Message'
import Page from 'components/PageContainer'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Breadcrumb from 'components/Breadcrumb'
import StaffModal from 'views/StaffModal'
import { MdHome } from 'react-icons/md'

export default ({ match }) => {
  const [open, setOpen] = useState(false)
  const [staff, setStaff] = useState(null)
  const [value, setValue] = useState({})
  const ref = useRef(null)

  const handleOpen = item => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleRefresh = () => {
    ref.current.refresh()
  }

  useEffect(() => {
    handleRefresh()
  }, [match.params.id])

  return (
    <Page
      title="staff.title.list"
      navId="staffList"
      actions={
        <Button
          type="button"
          variant="outlineSecondary"
          onClick={() => handleOpen({})}
        >
          <Message id="btn.add" />
        </Button>
      }
    >
      {staff && (
        <Section title="staff.section.path" mb={4}>
          <Breadcrumb items={getBreadcrumb(staff)} />
        </Section>
      )}
      <Section>
        <DataTable
          ref={ref}
          match={match}
          actions={{ handleOpen, handleRefresh, setStaff }}
          {...model}
        />
      </Section>
      <StaffModal
        open={open}
        value={value}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

function getBreadcrumb(staff) {
  if (!staff) return null

  const breadcrumb = [
    {
      url: `/staff`,
      label: <MdHome size="18px" />
    }
  ]

  if (!staff.path) {
    breadcrumb.push({ label: staff.name })
    return breadcrumb
  }

  breadcrumb.concat(
    staff.path.map(item => ({
      url: `/staff/${item.id}`,
      label: item.name
    }))
  )

  breadcrumb.push({ label: staff.name })
  return breadcrumb
}

import React from 'react'
import { Box } from 'rebass'
import Overlay from 'components/Overlay'

const Content = ({ children, sx }) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2,
      ...sx
    }}
  >
    {children}
  </Box>
)

export default ({ src, styles = {}, children, ...props }) => {
  const background = styles.background || {}
  const overlay = styles.overlay || { opacity: 0.8 }
  const content = styles.content || {}

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${src})`,
        ...background
      }}
      {...props}
    >
      {overlay.opacity > 0 && <Overlay sx={overlay} />}
      {children && <Content sx={content}>{children}</Content>}
    </Box>
  )
}

import React from 'react'
import { Box, Flex, Text } from 'rebass'
import ConfirmButton from 'components/ConfirmButton'
import Image from 'components/Image'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'linkUrl',
    render: ({ row }) => (
      <Flex alignItems="center">
        <Image
          src={row.imageUrl}
          alt={row.title}
          height="10rem"
          width="auto"
          sx={{ objectFit: 'contain' }}
        />
        <Box px={3} sx={{ whiteSpace: 'nowrap' }}>
          <Text fontSize={3}>{row.title}</Text>
          <Text color="grey.4">{row.linkUrl}</Text>
        </Box>
      </Flex>
    )
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <ConfirmButton
        title="ad.title.delete"
        message="ad.message.delete"
        onClick={handleDelete({ session, app, id: row.id, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const onLoad = async ({ setState, session, app, actions }) => {
  const { merchantId } = app.state.staff
  const variables = { merchantId, type: 'PRODUCT' }
  const query = `
    query($merchantId: ID!, $type: String) {
      ads(merchantId: $merchantId, type: $type) {
        id
        source
        type
        title
        linkUrl
        imageUrl
        seq
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.ads)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
      mutation ($id: ID!) {
        deleteAd(id: $id)
      }
    `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

import React, { useState, useRef, useEffect } from 'react'
import { Box, Flex, Text } from 'rebass'
import Link from 'components/Link'
import { MdMoreHoriz } from 'react-icons/md'

export default ({ icon, label, children, contentSx, linkProps, ...props }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const handleClick = (event) => {
    if (!ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <Flex
      ref={ref}
      height="100%"
      color={open ? 'accent.1' : 'inherit'}
      onClick={() => setOpen(!open)}
      // onMouseEnter={() => setOpen(true)}
      // onMouseLeave={() => setOpen(false)}
      // onFocus={() => setOpen(true)}
      // onBlur={() => setOpen(false)}
      sx={{
        position: 'relative',
      }}
      {...props}
    >
      <Link mx={2} fontSize={4} onClick={() => setOpen(!open)} {...linkProps}>
        {icon !== undefined ? icon : <MdMoreHoriz />}
        {label && <Text ml={2}>{label}</Text>}
      </Link>
      <Box
        display={open ? 'block' : 'none'}
        py={3}
        mt={4}
        bg="grey.0"
        sx={{
          position: 'absolute',
          minWidth: '150px',
          top: 0,
          right: 0,
          zIndex: 1101,
          borderRadius: '3px',
          boxShadow: 'small',
          whiteSpace: 'noWrap',
          ...contentSx,
        }}
      >
        {children}
      </Box>
    </Flex>
  )
}

import React from 'react'
import { Box, Button, Text } from 'rebass'
import { ALERT_ADD } from 'constants/actionType'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Table from 'components/Table'
import { addPurchase } from 'models/purchasePage'
import { validateForm } from 'utilities/formUtil'
import { MdDelete } from 'react-icons/md'

export const initialState = (value = {}) => ({
  products: value.products || [],
  ticketItems: [],
})

const validation = {}

export const fields = ({ state, setState }) => {
  return {
    productFilter: (
      <Select
        id="productFilter"
        placeholder="purchase.field.productFilter"
        options={state.products.map((item) => ({
          value: item.id,
          label: getProductLabel(item),
        }))}
        value=""
        onChange={(product) => {
          if (!product) return

          const { value } = product
          const ticketItems = [...state.ticketItems]
          if (ticketItems.some(({ id }) => id === value)) {
            return
          }
          const ticketItem = state.products.find(({ id }) => id === value)
          setState({
            ...state,
            ticketItems: [...ticketItems, ticketItem],
          })
        }}
      />
    ),
    product: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'purchase.field.spu',
            render: ({ row }) => {
              const { options = [] } = row
              return (
                <Box>
                  <Text>{row.spu}</Text>
                  {options.length > 0 && (
                    <Text>{options.map((item) => item.value).join(' / ')}</Text>
                  )}
                </Box>
              )
            },
          },
          {
            id: 'quantity',
            label: 'backorder.field.quantity',
            align: 'right',
          },
          {
            id: 'purchaeQuantity',
            label: 'purchase.field.quantity',
            width: '128px',
            render: ({ row, index }) => (
              <TextInput
                containerProps={{ m: 0 }}
                type="number"
                max={row.quantity}
                min="1"
                value={
                  state.ticketItems[index]
                    ? state.ticketItems[index].quantity
                    : row.quantity
                }
                onChange={(event) => {
                  const ticketItems = [...state.ticketItems]
                  const product = ticketItems[index]
                  product.quantity = event.target.value
                  ticketItems.splice(index, 1, product)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ row }) => (
              <Button
                type="button"
                variant="table"
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  const itemIdx = ticketItems.findIndex(
                    (item) => item.id === row.id
                  )
                  ticketItems.splice(itemIdx, 1)
                  setState({ ...state, ticketItems })
                }}
              >
                <Icon>
                  <MdDelete />
                </Icon>
              </Button>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

function getProductLabel(product) {
  const { spu, options } = product
  return (
    spu +
    (options && options.length > 0
      ? ` [${options.map(({ value }) => value).join(', ')}]`
      : '')
  )
}

export const handlers = ({ state, setState, session, app, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    state.products.forEach((item) => {
      item.id = item.productVariantId
    })
    const [ok] = await addPurchase(state, app, session)
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'success.save' },
    })
    action.handleLoad()
    action.handleClose()
  },
})

import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import { request } from 'utilities/requestUtil'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import TextArea from 'components/TextArea'
import Select from 'components/Select'

export const initialState = (value = {}, message) =>
  initializeState({
    id: value.id,
    message: value.message || '',
    messages: value.messages || [],
    status: value.status || 'OPEN',
  })

const validation = {
  message: [
    { type: 'required', message: 'error.required' },
    {
      type: 'maxLength',
      val: 1500,
      message: ['error.maxLength', { val: 1500 }],
    },
  ],
}

export const fields = ({ session, app, state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    message: (
      <TextArea
        rows="6"
        placeholder="support.field.reply"
        value={state.message}
        onChange={onTextChange('message')}
        errMsg={state.__error__.message}
      />
    ),
    status: (
      <Select
        isClearable={false}
        isSearchable={false}
        containerProps={{ width: '160px', mb: 0 }}
        id="supportStatus"
        placeholder="support.field.status"
        options={[
          {
            value: 'OPEN',
            label: message({ id: 'support.status.OPEN' }),
          },
          {
            value: 'CLOSED',
            label: message({ id: 'support.status.CLOSED' }),
          },
          {
            value: 'RESOLVED',
            label: message({ id: 'support.status.RESOLVED' }),
          },
        ]}
        value={{
          value: state.status,
          label: message({ id: `support.status.${state.status}` }),
        }}
        onChange={({ value }) => {
          editStatus({ session, app, state, setState, status: value })
        }}
      />
    ),
  }
}

export const handlers = ({ id, state, setState, session, app }) => ({
  handleLoad: async () => {
    const [ok, data] = await listMessages({ id, session, app, state })
    if (!ok) return

    setState(
      initialState({
        id,
        status: data.supportMessage.status,
        messages: data.supportMessages,
      })
    )
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const [ok] = await addReply({ session, app, state })
    if (!ok) return

    const [listOk, ListData] = await listMessages({ id, session, app, state })
    if (!listOk) return

    setState({ ...state, message: '', messages: ListData.supportMessages })
  },
})

const listMessages = async ({ id, app, session }) => {
  const variables = { id, input: { rootId: id } }
  const query = `
    query($id: ID!, $input: SupportMessageInput) {
      supportMessage(id: $id) {
        status
      }
      supportMessages(input: $input) {
        id
        userName
        staffName
        message
        createdAt
      }
    }
  `
  return request({ query, variables }, { session, app })
}

async function addReply({ session, app, state }) {
  const variables = {
    parentId: state.id,
    message: state.message,
  }
  const query = `
    mutation($parentId: ID!, $message: String!) {
      addSupportReply(parentId: $parentId, message: $message)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editStatus({ session, app, state, setState, status }) {
  const variables = {
    id: state.id,
    status,
  }
  const query = `
    mutation($id: ID!, $status: String) {
      editSupportStatus(id: $id, status: $status)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'support.message.failed' },
    })
    return
  }

  session.dispatch({
    type: ALERT_ADD,
    item: { type: 'success', message: 'support.message.success' },
  })
  setState({ ...state, status })
}

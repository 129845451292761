import React, {
  forwardRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useImperativeHandle
} from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, Slate } from 'slate-react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Box } from 'rebass'
import FormField from 'components/FormField'
import EditorToolbar from 'components/EditorToolbar'
import { toggleMark } from 'utilities/slateUtil'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code'
}

export default forwardRef(({ id, label, errMsg, value }, ref) => {
  const [state, setState] = useState(value)
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  useEffect(() => {
    if (value) {
      setState(value)
    }
  }, [value])

  useImperativeHandle(ref, () => ({
    getValue: () => state
  }))

  return (
    <FormField id={id} label={label} errMsg={errMsg}>
      <Slate editor={editor} value={state} onChange={value => setState(value)}>
        <EditorToolbar />
        <Wrapper>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
          />
        </Wrapper>
      </Slate>
    </FormField>
  )
})

const Wrapper = props => {
  const [focus, setFocus] = useState(false)

  return (
    <Box
      tabIndex={-1}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      p={2}
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: focus ? 'accent.1' : 'grey.3',
        borderRadius: '3px',
        outline: 'none'
      }}
      {...props}
    />
  )
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return (
        <Box as="p" {...attributes}>
          {children}
        </Box>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

import React from 'react'
import { Flex } from 'rebass'
import Message from 'components/Message'
import { useDropzone } from 'react-dropzone'

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      resolve(reader.result)
    }
  })
}

export default ({ accept, onUpload }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      const files = await Promise.all(
        acceptedFiles.map((file) => readFile(file))
      )
      await onUpload(files[0])
    },
    accept,
    multiple: false,
    noDrag: false,
  })

  const renderEmpty = () => (
    <Flex justifyContent="center" alignItems="center" width={1} color="grey.4">
      <Message id="app.message.uploadFile" />
    </Flex>
  )

  return (
    <Flex
      flexWrap="wrap"
      alignContent="center"
      variant="dropzone"
      sx={{
        borderColor: isDragActive ? 'accent.1' : 'grey.3',
        cursor: 'pointer',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {renderEmpty()}
    </Flex>
  )
}

import React from 'react'
import TextInput from 'components/TextInput'
import Table from 'components/Table'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { formatDate } from 'utilities/dateUtil'

export const initialState = (value = {}) =>
  initializeState({
    ticketItems: value.ticketItems || [],
    balances: value.refTickets ? getBalances(value.refTickets) : {},
    id: value.id || '',
    transDate: value.transDate
      ? formatDate(new Date(value.transDate))
      : getDefaultDate(),
  })

function getDefaultDate() {
  return formatDate(new Date())
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    transDate: (
      <TextInput
        id="transDate"
        type="date"
        label="purchase.field.acceptDate"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    ticketItems: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'purchase.field.spu',
          },
          {
            id: 'sku',
            label: 'purchase.field.sku',
          },
          {
            id: 'quantity',
            label: 'purchase.field.allQuantity',
            render: ({ row }) => {
              const { quantity, productVariantId } = row
              const balances = state.balances.get(productVariantId)
              const { acceptQuantity = 0, cancelQuantity = 0 } = balances || {}
              return `${quantity} / ${acceptQuantity} / ${cancelQuantity}`
            },
          },
          {
            id: 'acceptQuantity',
            label: 'purchase.field.acceptQuantity',
            width: '64px',
            render: ({ index }) => {
              const item = state.ticketItems[index]
              return (
                <TextInput
                  containerProps={{ m: 0 }}
                  type="number"
                  min="0"
                  max={item.quantity}
                  value={item.acceptQuantity || 0}
                  onChange={(event) => {
                    setQuantity({
                      state,
                      setState,
                      index,
                      item,
                      value: event.target.value,
                      key: 'acceptQuantity',
                    })
                  }}
                />
              )
            },
          },
          {
            id: 'cancelQuantity',
            label: 'purchase.field.cancelQuantity',
            width: '64px',
            render: ({ row, index }) => {
              const item = state.ticketItems[index]
              return (
                <TextInput
                  containerProps={{ m: 0 }}
                  type="number"
                  min="0"
                  max={item.quantity}
                  value={item.cancelQuantity || 0}
                  onChange={(event) => {
                    setQuantity({
                      state,
                      setState,
                      item,
                      index,
                      value: event.target.value,
                      key: 'cancelQuantity',
                    })
                  }}
                />
              )
            },
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

function setQuantity({ state, setState, index, item, value, key }) {
  let quantity = parseInt(value)
  const balances = state.balances.get(item.productVariantId)
  const { acceptQuantity = 0, cancelQuantity = 0 } = balances || {}
  const balance = item.quantity - acceptQuantity - cancelQuantity
  if (quantity > balance) {
    quantity = balance
  }
  const ticketItems = [...state.ticketItems]
  const product = ticketItems[index]
  const restKey = key === 'acceptQuantity' ? 'cancelQuantity' : 'acceptQuantity'
  if (quantity + product[restKey] > balance) {
    product[restKey] = balance - quantity
  }
  product[key] = quantity
  ticketItems.splice(index, 1, product)
  setState({ ...state, ticketItems })
}

function getBalances(tickets) {
  return tickets.reduce((result, ticket) => {
    ticket.ticketItems.forEach((item) => {
      const { productVariantId, quantity, extra } = item
      const { ticketType } = extra
      const ticketItem = result.get(productVariantId) || {}
      let { acceptQuantity = 0, cancelQuantity = 0 } = ticketItem

      if (ticketType === 'PURCHASE_ACCEPT') {
        acceptQuantity += quantity
      }

      if (ticketType === 'PURCHASE_CANCEL') {
        cancelQuantity += quantity
      }

      return result.set(productVariantId, {
        acceptQuantity,
        cancelQuantity,
      })
    })
    return result
  }, new Map())
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match,
}) => ({
  handleLoad: async () => {
    let id = match.params.id
    const { ticket, ticketItems, refTickets } = await getTicket({
      app,
      session,
      id,
    })
    setState(
      initialState({
        id,
        transDate: ticket.transDate,
        ticketItems,
        refTickets,
      })
    )
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = await addAccept(state, app, session)
    if (!ok) return

    history.push(`/purchase/${state.id}/edit`)
  },
})

async function addAccept(state, app, session) {
  const variables = {
    ticketId: state.id,
    input: {
      ticketType: 'PURCHASE',
      ticketItems: state.ticketItems.reduce((result, item) => {
        const { productVariantId, acceptQuantity, cancelQuantity } = item
        if (acceptQuantity > 0) {
          result.push({
            ticketType: 'PURCHASE_ACCEPT',
            productVariantId,
            quantity: parseInt(acceptQuantity),
          })
        }
        if (cancelQuantity > 0) {
          result.push({
            ticketType: 'PURCHASE_CANCEL',
            productVariantId,
            quantity: parseInt(cancelQuantity),
          })
        }
        return result
      }, []),
    },
  }
  const query = `
    mutation($ticketId: ID!, $input: TicketInput!) {
      addSubTicket(ticketId: $ticketId, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getTicket({ app, session, id }) {
  const variables = { ticketId: id }
  const query = `
    query($ticketId: ID!) {
      ticket(ticketId: $ticketId) {
        transDate
        status
      }
      ticketItems(ticketId: $ticketId) {
        productVariantId
        spu
        sku
        options {
          name
          value
        }
        quantity
        transDate
        status
        createdAt
        createdBy
      }
      refTickets(id: $ticketId) {
        ticketItems {
          productVariantId
          quantity
          extra
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data
}

import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Message from 'components/Message'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { formatDate, addDays } from 'utilities/dateUtil'
import { getPagination } from 'utilities/pagination'

export const initialState = (value = {}, message) =>
  initializeState({
    id: value.id || '',
    name: value.name || '',
    codes: value.codes || [],
    pagination: value.pagination || {},
    codesToAdd: [],
    codesToDelete: [],
    startDate: value.startDate || getDefaultStartDate(),
    endDate: value.endDate || getDefaultEndDate(),
    discountType: getDiscountTypeOption(value.discountType),
    discountValue: value.discountValue || 0,
    discountScope: value.discountScope || 0,
    products: value.products || [],
    product: getProduct(value.products, value.product),
    minQuantity: value.minQuantity || 1,
    maxUserQuantity: value.maxUserQuantity || 1,
    maxQuantityPerUser: value.maxQuantityPerUser || 1,
    minAmount: value.minAmount || 1,
    allowCombination: getAllowCombination(value.allowCombination, message),
  })

const validation = {
  name: [{ type: 'required', message: 'error.required' }],
  // code: [
  //   { type: 'required', message: 'error.required' },
  //   {
  //     type: 'minLength',
  //     val: 6,
  //     message: { id: 'error.minLength', values: { val: 6 } }
  //   }
  // ],
  discountType: [{ type: 'required', message: 'error.required' }],
  discountValue: [{ type: 'required', message: 'error.required' }],
}

function getAllowCombination(value, message) {
  if (value === 'YES') {
    return {
      value: 'YES',
      label: message({ id: 'discount.allowCombination.YES' }),
    }
  }
  return {
    value: 'NO',
    label: message({ id: 'discount.allowCombination.NO' }),
  }
}

function getDefaultStartDate() {
  return formatDate(new Date())
}

function getDefaultEndDate() {
  return formatDate(addDays(new Date(), 30))
}

function getProduct(products, ids) {
  if (!products || products.length === 0) return null
  if (!ids || ids.length === 0) return null

  return products.filter((product) => ids.find((id) => id === product.value))
}

function getProductLabel(product) {
  const { spu, options } = product
  return (
    spu +
    (options && options.length > 0
      ? ` [${options.map(({ value }) => value).join(', ')}]`
      : '')
  )
}

function getDiscountTypeOptions() {
  return [
    {
      value: 'FIXED_AMOUNT',
      label: <Message id="discount.discountType.fixedAmount" />,
    },
    {
      value: 'FIXED_PERCENTAGE',
      label: <Message id="discount.discountType.fixedPercentage" />,
    },
    {
      value: 'FREE_SHIPPING',
      label: <Message id="discount.discountType.freeShipping" />,
    },
  ]
}

function getDiscountTypeOption(value) {
  if (!value) return ''

  const discountTypes = getDiscountTypeOptions()
  return discountTypes.find((item) => item.value === value)
}

// function getDiscountScopeOption(value) {
//   if (!value) return ''

//   switch (value) {
//     case 'ALL':
//       return {
//         value: 'ALL',
//         label: <Message id="discount.discountScope.all" />
//       }
//     case 'ADDITIONAL':
//       return {
//         value: 'ADDITIONAL',
//         label: <Message id="discount.discountScope.additional" />
//       }
//     default:
//       return ''
//   }
// }

export const fields = ({ state, setState, message }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    name: (
      <TextInput
        id="name"
        label="discount.field.name"
        placeholder="discount.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    startDate: (
      <TextInput
        id="startDate"
        type="date"
        label="discount.field.startDate"
        value={state.startDate}
        onChange={onTextChange('startDate')}
        errMsg={state.__error__.startDate}
      />
    ),
    endDate: (
      <TextInput
        id="endDate"
        type="date"
        label="discount.field.endDate"
        value={state.endDate}
        onChange={onTextChange('endDate')}
        errMsg={state.__error__.endDate}
      />
    ),
    allowCombination: (
      <Select
        id="allowCombination"
        isSearchable={false}
        isClearable={false}
        label="discount.field.allowCombination"
        placeholder="discount.field.allowCombination"
        options={[
          {
            value: 'NO',
            label: message({ id: 'discount.allowCombination.NO' }),
          },
          {
            value: 'YES',
            label: message({ id: 'discount.allowCombination.YES' }),
          },
        ]}
        value={state.allowCombination}
        onChange={handleSelectChange(
          'allowCombination',
          state,
          setState,
          validation
        )}
      />
    ),
    discountType: (
      <Select
        id="discountType"
        isSearchable={false}
        isClearable={false}
        label="discount.field.discountType"
        placeholder="discount.field.discountType"
        options={getDiscountTypeOptions()}
        value={state.discountType}
        onChange={handleSelectChange(
          'discountType',
          state,
          setState,
          validation
        )}
      />
    ),
    discountValue: (
      <TextInput
        id="discountValue"
        type="number"
        label="discount.field.discountValue"
        placeholder="discount.field.discountValue"
        value={state.discountValue}
        onChange={onTextChange('discountValue')}
        errMsg={state.__error__.discountValue}
      />
    ),
    discountScope: (
      <TextInput
        id="discountScope"
        type="number"
        min="0"
        label="discount.field.discountScope"
        placeholder="discount.field.discountScope"
        value={state.discountScope}
        onChange={onTextChange('discountScope')}
        errMsg={state.__error__.discountScope}
      />
    ),
    product: (
      <Select
        id="product"
        isMulti
        label="discount.field.product"
        placeholder="discount.field.product"
        options={state.products}
        value={state.product}
        onChange={handleSelectChange('product', state, setState, validation)}
      />
    ),
    minQuantity: (
      <TextInput
        id="minQuantity"
        type="number"
        min="0"
        label="discount.field.minQuantity"
        placeholder="discount.field.minQuantity"
        value={state.minQuantity}
        onChange={onTextChange('minQuantity')}
        errMsg={state.__error__.minQuantity}
      />
    ),
    maxUserQuantity: (
      <TextInput
        id="maxUserQuantity"
        type="number"
        min="0"
        label="discount.field.maxUserQuantity"
        placeholder="discount.field.maxUserQuantity"
        value={state.maxUserQuantity}
        onChange={onTextChange('maxUserQuantity')}
        errMsg={state.__error__.maxUserQuantity}
      />
    ),
    maxQuantityPerUser: (
      <TextInput
        id="maxQuantityPerUser"
        type="number"
        min="0"
        label="discount.field.maxQuantityPerUser"
        placeholder="discount.field.maxQuantityPerUser"
        value={state.maxQuantityPerUser}
        onChange={onTextChange('maxQuantityPerUser')}
        errMsg={state.__error__.maxQuantityPerUser}
      />
    ),
    minAmount: (
      <TextInput
        id="minAmount"
        type="number"
        min="0"
        label="discount.field.minAmount"
        placeholder="discount.field.minAmount"
        value={state.minAmount}
        onChange={onTextChange('minAmount')}
        errMsg={state.__error__.minAmount}
      />
    ),
  }
}

export const handlers = ({ session, app, state, setState, message, id }) => ({
  handleLoad: async ({ page } = {}) => {
    const products = await getProducts({ app, session })
    let data = { products }

    if (id) {
      const items = await getData({ app, session, id, page })
      data = { products, ...items }
    }
    setState(initialState(data, message))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editDiscount(state, app, session)
      : await addDiscount(state, app, session)
    if (ok) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'success.save' },
      })
    }
  },
  handleDelete: (id) => async () => {
    const variables = { id }
    const query = `
      mutation ($id: ID!) {
        deleteProduct(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }
  },
  handleAddCodes: (codes) => {
    setState({
      ...state,
      codes: [...state.codes, ...codes],
      codesToAdd: [...state.codesToAdd, ...codes],
    })
  },
  handleDeleteCodes: (index) => {
    const codes = [...state.codes]
    const code = codes[index]
    codes.splice(index, 1)
    setState({
      ...state,
      codes,
      codesToDelete: [...state.codesToDelete, code],
    })
  },
})

async function getProducts({ app, session }) {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query($merchantId: ID!) {
      productVariants(merchantId: $merchantId) {
        id
        spu
        options {
          name
          value
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return []

  return data.productVariants.map((item) => ({
    value: item.id,
    label: getProductLabel(item),
  }))
}

async function getData({ app, session, id, page }) {
  const variables = { id, input: { discountConfigId: id, page } }
  const query = `
    query($id: ID!, $input: DiscountQueryInput) {
      discountConfig(id: $id) {
        id
        merchantId
        name
        startDate
        endDate
        rules
        discountType
        discountValue
        discountScope
        allowCombination
        status
        createdAt
      }
      discountCodeCount(input: $input)
      discountCodes(input: $input) {
        code
        quantity
        availableQuantity
        lockedQuantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return null

  const config = data.discountConfig
  const minQuantity = config.rules.find((item) => item.key === 'minQuantity')
  const maxUserQuantity = config.rules.find(
    (item) => item.key === 'maxUserQuantity'
  )
  const maxQuantityPerUser = config.rules.find(
    (item) => item.key === 'maxQuantityPerUser'
  )
  const minAmount = config.rules.find((item) => item.key === 'minAmount')
  const product = config.rules.find((item) => item.key === 'product')

  return {
    id: config.id,
    name: config.name,
    codes: data.discountCodes,
    pagination: getPagination(page, 50, data.discountCodeCount),
    startDate: formatDate(new Date(config.startDate)),
    endDate: formatDate(new Date(config.endDate)),
    discountType: config.discountType,
    discountValue: config.discountValue,
    discountScope: config.discountScope,
    allowCombination: config.allowCombination,
    product: product ? product.val : [],
    minQuantity: minQuantity ? minQuantity.val : 1,
    maxUserQuantity: maxUserQuantity ? maxUserQuantity.val : 1,
    maxQuantityPerUser: maxQuantityPerUser ? maxQuantityPerUser.val : 1,
    minAmount: minAmount ? minAmount.val : 1,
  }
}

async function addDiscount(state, app, session) {
  const variables = {
    input: {
      name: state.name,
      codesToAdd: state.codesToAdd.map((item) => ({
        ...item,
        quantity: parseInt(item.quantity),
      })),
      startDate: state.startDate,
      endDate: state.endDate,
      discountType: state.discountType.value,
      discountScope: parseInt(state.discountScope),
      discountValue: parseFloat(state.discountValue),
      allowCombination: state.allowCombination.value,
      rules: [
        { key: 'minQuantity', op: 'ge', val: parseInt(state.minQuantity) },
        {
          key: 'maxUserQuantity',
          op: 'le',
          val: parseInt(state.maxUserQuantity),
        },
        {
          key: 'maxQuantityPerUser',
          op: 'le',
          val: parseInt(state.maxQuantityPerUser),
        },
        { key: 'minAmount', op: 'ge', val: parseFloat(state.minAmount) },
      ],
    },
  }

  if (state.product && state.product.length > 0) {
    const ids = state.product.map((item) => item.value)
    const productRule = { key: 'product', op: 'in', val: ids }
    variables.input.rules.push(productRule)
  }

  const query = `
    mutation ($input: DiscountConfigInput!) {
      addDiscountConfig(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editDiscount(state, app, session) {
  const variables = {
    id: state.id,
    input: {
      name: state.name,
      codesToAdd: state.codesToAdd.map((item) => ({
        ...item,
        quantity: parseInt(item.quantity),
      })),
      codesToDelete: state.codesToDelete,
      startDate: state.startDate,
      endDate: state.endDate,
      discountType: state.discountType.value,
      discountScope: parseInt(state.discountScope),
      discountValue: parseFloat(state.discountValue),
      allowCombination: state.allowCombination.value,
      rules: [
        { key: 'minQuantity', op: 'ge', val: parseInt(state.minQuantity) },
        {
          key: 'maxUserQuantity',
          op: 'le',
          val: parseInt(state.maxUserQuantity),
        },
        {
          key: 'maxQuantityPerUser',
          op: 'le',
          val: parseInt(state.maxQuantityPerUser),
        },
        { key: 'minAmount', op: 'ge', val: parseFloat(state.minAmount) },
      ],
    },
  }

  if (state.product && state.product.length > 0) {
    const ids = state.product.map((item) => item.value)
    const productRule = { key: 'product', op: 'in', val: ids }
    variables.input.rules.push(productRule)
  }

  const query = `
    mutation ($id: ID!, $input: DiscountConfigInput!) {
      editDiscountConfig(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

// const deleteDiscount = ({ state, setState, code }) => {
//   const codes = [...state.codes]
//   const codeIdx = codes.findIndex((item) => item.code === code)
//   codes.splice(codeIdx, 1)

//   const codesToAdd = [...state.codesToAdd]
//   const addIdx = state.codesToAdd.findIndex((item) => item.code === code)

//   if (addIdx !== -1) {
//     codesToAdd.splice(addIdx, 1)
//     setState({ ...state, codes, codesToAdd })
//     return
//   }

//   const codesToDelete = [...state.codesToDelete, code]
//   setState({ ...state, codes, codesToDelete })
// }

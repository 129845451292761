import React from 'react'
import { Box } from 'rebass'
import Message from 'components/Message'
import Link from 'components/Link'
import IconWrapper from 'components/Icon'

const ListItem = ({ id, label, icon, active, onClick, variant, ...props }) => (
  <Box as="li">
    <Link variant={variant} onClick={onClick} {...props}>
      <IconWrapper mr={3}>{icon}</IconWrapper>
      <Message flex="1" fontSize={1} ml={icon ? 0 : '46px'} id={label} />
    </Link>
  </Box>
)

ListItem.defaultProps = {
  active: false,
  onClick: () => null
}

export default ListItem

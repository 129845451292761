import React from 'react'
import { Box } from 'rebass'
import Toolbar from 'components/Toolbar'
import Container from 'components/Container'
import Page from 'components/Page'

export default ({
  navId,
  title,
  menu,
  actions,
  fullWidth,
  children,
  ...props
}) => (
  <Page title={title} navId={navId}>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '64px 1fr',
        gridGap: 0,
        height: '100vh',
      }}
      {...props}
    >
      <Toolbar
        sx={{ zIndex: 100 }}
        px={4}
        title={title}
        menu={menu}
        actions={actions}
      />
      <Container fullWidth={fullWidth}>{children}</Container>
    </Box>
  </Page>
)

import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Heading } from 'rebass'
import AppContext from 'contexts/AppContext'
import LinkButton from './LinkButton'
import { MdMenu } from 'react-icons/md'
import { NAV_TOGGLE } from 'constants/actionType'

export default ({ title, menu, actions, sx, ...rest }) => {
  const { state, dispatch } = useContext(AppContext)
  const { navOpen } = state

  return (
    <Flex
      variant="default"
      height="64px"
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderBottomWidth: '1px',
        borderBottomColor: 'grey.3',
        borderBottomStyle: 'solid',
        ...sx
      }}
      {...rest}
    >
      {menu ? (
        menu
      ) : (
        <LinkButton
          mr={3}
          variant="link"
          onClick={() => dispatch({ type: NAV_TOGGLE })}
        >
          <MdMenu />
        </LinkButton>
      )}
      <Heading
        flex="1"
        variant="heading"
        display={[navOpen ? 'none' : 'block', 'block']}
      >
        <FormattedMessage id={title} />
      </Heading>
      <Box
        display={[navOpen ? 'none' : 'flex', 'flex']}
        sx={{ alignItems: 'center' }}
      >
        {actions}
      </Box>
    </Flex>
  )
}

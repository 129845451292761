import React from 'react'
import Overlay from 'components/Overlay'
import Container from 'components/Container'
import Section from 'components/Section'

export default ({ open, onClose, title, children, contentProps, ...props }) => {
  // const handleClose = e => {
  //   if (e.target === e.currentTarget) {
  //     onClose()
  //   }
  // }

  return (
    <>
      <Overlay
        sx={{
          zIndex: 1000,
          transform: open ? 'scale(1)' : 'scale(0)',
          opacity: open ? 0.6 : 0,
          transition: 'opacity 0.3s ease-out'
        }}
      />
      <Container
        // onClick={handleClose}
        alignItems="center"
        height="100vh"
        width="100vw"
        contentProps={{ py: 0, ...props }}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1001,
          transform: open ? 'translateY(0px)' : 'translateY(100vh)',
          transition: `transform .2s ease-out`
        }}
      >
        <Section title={title} titleProps={{ flex: 1 }} {...contentProps}>
          {children}
        </Section>
      </Container>
    </>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import { Flex, Box, Button, Text } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import { columns, onLoad, handleStatusChange } from 'models/paymentConfigList'
import Page from 'components/PageContainer'
import Message from 'components/Message'
import Section from 'components/Section'
import Table from 'components/Table'
import Switch from 'components/Switch'
import PaymentConfigModal from 'views/PaymentConfigModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [configs, setConfigs] = useState([])
  const [channel, setChannel] = useState('ECPAY')
  const filteredConfigs = configs.filter(
    item => item.channelProvider === channel
  )
  const cashConfig = configs.find(item => item.id === 'CASH')

  useEffect(() => {
    onLoad({ setConfigs, session, app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChannelChange = channel => {
    setChannel(channel)
  }

  const handleOpen = item => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRefresh = () => {
    onLoad({ setConfigs, session, app })
  }

  return (
    <Page
      title="paymentConfig.title.list"
      navId="paymentConfigList"
      actions={
        <Button
          type="button"
          variant="outlineSecondary"
          onClick={() => handleOpen({})}
        >
          <Message id="btn.add" />
        </Button>
      }
    >
      <Box
        mt={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 3fr'],
          gridGap: 3
        }}
      >
        <Box>
          <Section title="paymentConfig.section.channel">
            <Text
              pb={2}
              color={channel === 'ECPAY' ? 'accent.1' : 'grey.5'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleChannelChange('ECPAY')}
            >
              <Message id="paymentConfig.channel.ECPAY" />
            </Text>
            <Text
              pb={2}
              color={channel === 'LINEPAY' ? 'accent.1' : 'grey.5'}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleChannelChange('LINEPAY')}
            >
              <Message id="paymentConfig.channel.LINEPAY" />
            </Text>
          </Section>
          <Section mt={3}>
            <Flex justifyContent="space-between" alignItems="center">
              <Message id="paymentConfig.btn.toggleCash" />
              <Switch
                checked={cashConfig && cashConfig.status === 'ACTIVE'}
                onClick={() => {
                  handleStatusChange({ app, session, id: 'CASH' })

                  const newConfigs = [...configs]
                  if (!cashConfig) {
                    newConfigs.push({ id: 'CASH', status: 'ACTIVE' })
                  } else {
                    const item = newConfigs.find(({ id }) => id === 'CASH')
                    item.status =
                      item.status === 'ACTIVE' ? 'PENDING' : 'ACTIVE'
                  }

                  setConfigs(newConfigs)
                }}
              />
            </Flex>
          </Section>
        </Box>
        <Section>
          <Table
            columns={columns({
              session,
              app,
              states: { channel, configs },
              actions: { handleRefresh, setConfigs }
            })}
            rows={filteredConfigs}
          />
        </Section>
      </Box>
      <PaymentConfigModal
        open={open}
        value={{ channel }}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

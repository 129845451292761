import React, { useState, useRef } from 'react'
import { Box, Button, Flex } from 'rebass'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import ProductAdModal from 'views/ProductAdModal'
import * as model from 'models/productAdPage'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const [open, setOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [value, setValue] = useState({})
  const ref = useRef(null)
  const handleOpen = item => {
    setValue(item)
    setOpen(true)
  }
  const handleOk = item => {
    const productId = item.productId.value
    const product = products.find(({ id }) => id === productId)
    const items = [...ref.current.state.items]
    if (!items.some(({ id }) => id === productId)) {
      items.push(product)
    }
    ref.current.setState({ ...ref.current.state, items })
    setOpen(false)
    setValue({})
  }
  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <>
      <Form
        {...model}
        match={match}
        actions={{ handleOpen, setProducts }}
        ref={ref}
      >
        {({ form, action, state }) => (
          <Page
            title="productAd.title.add"
            navId="productAd"
            actions={
              <>
                <LinkButton variant="plain" url="/product/ad">
                  <MdArrowBack />
                  <Message ml={1} id="btn.back" />
                </LinkButton>
                <Button variant="outlineAccent">
                  <Message id="btn.save" />
                </Button>
              </>
            }
          >
            <Box
              mt={2}
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 2fr'],
                gridTemplateRows: '1fr',
                gridGap: 3
              }}
            >
              <Section title="productAd.section.basic">{form.name}</Section>
              <Section title="productAd.section.items">
                {form.items}
                <Flex justifyContent="center" alignItems="center" mt={3}>
                  <Button
                    type="button"
                    variant="outlineSecondary"
                    onClick={() => handleOpen({})}
                  >
                    <Message id="productAd.btn.add" />
                  </Button>
                </Flex>
              </Section>
            </Box>
          </Page>
        )}
      </Form>
      <ProductAdModal
        open={open}
        value={{ ...value, products }}
        actions={{ handleClose, handleOk }}
      />
    </>
  )
}

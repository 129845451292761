import React from 'react'
import { Box, Text } from 'rebass'
import Link from 'components/Link'
import Message from 'components/Message'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'
import ConfirmButton from 'components/ConfirmButton'

export const columns = ({ session, app, actions }) => [
  {
    id: 'username',
    label: 'staff.field.username',
    render: ({ row }) => (
      <Link url={`/staff/${row.id}`}>
        <Text variant="link">{row.username}</Text>
      </Link>
    )
  },
  {
    id: 'type',
    label: 'staff.field.type',
    render: ({ row }) => <Message id={`staff.type.${row.type}`} />
  },
  {
    id: 'actions',
    render: ({ row }) => (
      <Box sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
        {/* <Button
          type="button"
          variant="table"
          onClick={() => actions.handleOpen(row)}
        >
          <Icon>
            <MdEdit />
          </Icon>
        </Button> */}
        <ConfirmButton
          title="staff.title.delete"
          message="staff.message.delete"
          onClick={handleDelete({ session, app, id: row.id, actions })}
        >
          <MdDelete />
        </ConfirmButton>
      </Box>
    )
  }
]

export const onLoad = async ({ setState, session, app, actions }) => {
  const variables = { input: {} }
  let query = `
    query($input: StaffQueryInput) {
      staffs(input: $input) {
        id
        type
        username
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return

  actions.setStaff(data.staff)
  setState(data.staffs)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
    mutation ($id: ID!) {
      deleteStaff(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

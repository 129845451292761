import React from 'react'
import ConfirmButton from 'components/ConfirmButton'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'
import Switch from 'components/Switch'

export const columns = ({ session, app, states, actions }) => {
  const { channel } = states
  return [
    // {
    //   id: 'channel',
    //   label: 'paymentConfig.field.channel',
    //   render: ({ row }) => <Text>{getChannelProvider(row.channelProvider)}</Text>
    // },
    {
      id: channel === 'ECPAY' ? 'merchantId' : 'key',
      label:
        channel === 'ECPAY'
          ? 'paymentConfig.field.merchantId'
          : 'paymentConfig.field.LINEPAY.key',
    },
    {
      id: 'status',
      label: 'paymentConfig.field.status',
      render: ({ row }) => (
        <Switch
          checked={row.status === 'ACTIVE'}
          onClick={() => {
            handleStatusChange({ app, session, id: row.id })
            actions.setConfigs(
              states.configs.map((item) => {
                if (item.channelProvider !== states.channel) return item
                let status = 'PENDING'
                if (item.id === row.id) {
                  status = row.status === 'ACTIVE' ? 'PENDING' : 'ACTIVE'
                }
                return { ...item, status }
              })
            )
          }}
        />
      ),
    },
    {
      id: 'actions',
      align: 'right',
      render: ({ row }) => (
        <ConfirmButton
          title="paymentConfig.title.delete"
          message="paymentConfig.message.delete"
          onClick={handleDelete({ session, app, id: row.id, actions })}
        >
          <MdDelete />
        </ConfirmButton>
      ),
    },
  ]
}

// function getChannelProvider(channelProvider) {
//   switch (channelProvider) {
//     case 'ECPAY':
//       return <Message id="paymentConfig.channel.ECPAY" />
//     case 'LINEPAY':
//       return <Message id="paymentConfig.channel.LINEPAY" />
//     default:
//       return channelProvider
//   }
// }

export const onLoad = async ({ setConfigs, session, app }) => {
  const query = `
    query {
      paymentConfigs {
        id
        channelProvider
        merchantId
        key
        status
      }
    }
  `
  const [ok, data] = await request({ query }, { app, session })
  if (!ok) return

  setConfigs(data.paymentConfigs)
}

export const handleStatusChange = async ({ session, app, id }) => {
  const variables = { id }
  const query = `
      mutation($id: ID!) {
        setPaymentConfig(id: $id)
      }
    `
  await request({ query, variables }, { session, app })
}

const handleDelete =
  ({ session, app, id, actions }) =>
  async (handleClose) => {
    const variables = { id }
    const query = `
      mutation($id: ID!) {
        deletePaymentConfig(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) return

    handleClose()
    actions.handleRefresh()
  }

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Button } from 'rebass'
import LinkButton from 'components/LinkButton'
import Section from 'components/Section'
import Form from 'components/Form'
import Page from 'components/PageContainer'
import * as model from 'models/productVariantPage'

export default () => (
  <Form {...model}>
    {({ form, state, action }) => (
      <Page
        title="product.variant.title.add"
        navId="productList"
        actions={
          <>
            <LinkButton
              variant="outlineSecondary"
              mr={3}
              url={`/product/${state.product.id}/edit`}
            >
              <FormattedMessage id="btn.cancel" />
            </LinkButton>
            <Button variant="outlineAccent">
              <FormattedMessage id="btn.confirm" />
            </Button>
          </>
        }
      >
        <Box
          mt={2}
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr 2fr'],
            gridGap: 3
          }}
        >
          <Box>
            {state.spu && (
              <Section mb={4} title="product.variant.section.product">
                Product Info
              </Section>
            )}
            <Section title="product.variant.section.image">
              Product Image
            </Section>
          </Box>
          <Box>
            <Section title="product.variant.section.option">
              {form.options}
              {state.options.length < 3 && (
                <Flex mt={3} justifyContent="center">
                  <Button
                    type="button"
                    variant="outlineSecondary"
                    onClick={action.addOption}
                  >
                    <FormattedMessage id="product.variant.option.btn.add" />
                  </Button>
                </Flex>
              )}
            </Section>
            <Section mt={4} title="product.variant.section.basic">
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gridGap: 3
                }}
              >
                {form.sku}
                {form.barcode}
                {form.postedPrice}
                {form.price}
              </Box>
            </Section>
          </Box>
        </Box>
      </Page>
    )}
  </Form>
)

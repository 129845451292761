import React from 'react'
import { Text } from 'rebass'
import Link from 'components/Link'
import { request } from 'utilities/requestUtil'

export const columns = ({ session, app, actions, message }) => [
  {
    id: 'id',
    label: 'purchase.field.id',
    render: ({ row }) => (
      <Link url={`/purchase/${row.id}/edit`}>
        <Text variant="link">{row.id}</Text>
      </Link>
    ),
  },
  {
    id: 'transDate',
    label: 'purchase.field.transDate',
  },
  {
    id: 'status',
    label: 'purchase.field.status',
    render: ({ row }) => message({ id: `purchase.status.${row.status}` }),
  },
  // {
  //   id: 'actions',
  //   align: 'right',
  //   render: ({ row }) => (
  //     <ConfirmButton
  //       title="purchase.title.delete"
  //       message="purchase.message.delete"
  //       onClick={handleDelete({ session, app, id: row.id, actions })}
  //     >
  //       <MdDelete />
  //     </ConfirmButton>
  //   ),
  // },
]

export const onLoad = async ({ setState, session, app, page }) => {
  const { merchantId } = app.state.staff
  const variables = { merchantId, input: { ticketType: 'PURCHASE', page } }
  const query = `
    query ($merchantId: ID, $input: TicketQueryInput) {
      tickets (merchantId: $merchantId, input: $input) {
        data {
          id
          toLocation
          transDate
          status
        }
        pagination {
          totalCount
          totalPage
          page
          pages
          prevPage
          nextPage
          countPerPage
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.tickets)
}
import React from 'react'
import { Box, Button, Text } from 'rebass'
import Icon from 'components/Icon'
import Link from 'components/Link'
import { request } from 'utilities/requestUtil'
import { MdDelete, MdEdit } from 'react-icons/md'
import ConfirmButton from 'components/ConfirmButton'

export const columns = ({ session, app, actions }) => [
  {
    id: 'name',
    label: 'category.field.name',
    render: ({ row }) => (
      <Link url={`/category/${row.id}`}>
        <Text variant="link">{row.name}</Text>
      </Link>
    )
  },
  {
    id: 'actions',
    render: ({ row }) => (
      <Box sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
        <Button
          type="button"
          variant="table"
          onClick={() => actions.handleOpen(row)}
        >
          <Icon>
            <MdEdit />
          </Icon>
        </Button>
        <ConfirmButton
          title="category.title.delete"
          message="category.message.delete"
          onClick={handleDelete({ session, app, id: row.id, actions })}
        >
          <MdDelete />
        </ConfirmButton>
      </Box>
    )
  }
]

export const onLoad = async ({ match, setState, session, app, actions }) => {
  const parentCategoryId = match.params.id || 0
  const { merchantId } = app.state.staff
  const variables = {
    id: parentCategoryId,
    merchantId,
    input: { parentCategoryId }
  }
  let query = `
    query ($merchantId: ID!, $input: CategoryQueryInput) {
      categories(merchantId: $merchantId, input: $input) {
        id
        name
      }
    }
  `
  if (parentCategoryId) {
    query = `
      query ($id: ID!, $merchantId: ID!, $input: CategoryQueryInput) {
        category(id: $id) {
          id
          name
          path
        }
        categories(merchantId: $merchantId, input: $input) {
          id
          name
        }
      }
    `
  }
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  actions.setCategory(data.category)
  setState(data.categories)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
    mutation ($id: ID!) {
      deleteCategory(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

export const fetchAsBlob = url => fetch(url).then(resp => resp.blob())

export const convertBlobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

export const uploadImage = async ({ url, fields, image }) => {
  try {
    const form = new FormData()
    Object.entries(fields).forEach(([key, val]) => {
      form.append(key, val)
    })

    form.append('file', image)
    await fetch(url, { method: 'POST', body: form })
  } catch (e) {
    // TODO: mark upload failed
  }
}

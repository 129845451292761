import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Flex, Text } from 'rebass'
import { keyframes } from '@emotion/core'
import ReactTooltip from 'react-tooltip'

const blink = keyframes`
  to { visibility: hidden; }
`

const slots = [
  getTime(0),
  getTime(3),
  getTime(6),
  getTime(9),
  getTime(12),
  getTime(15),
  getTime(18),
  getTime(21),
  getTime(24)
]

export default () => {
  const { formatMessage } = useIntl()
  const [time, setTime] = useState(getTimeLeft(new Date().getTime()))
  const [h, m] = formatTime(time)

  useEffect(() => {
    setTimeout(() => {
      setTime(getTimeLeft(new Date().getTime()))
    }, 60000)
  }, [time])

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        width="55px"
        height="55px"
        bg="grey.0"
        color="accent.1"
        sx={{
          position: 'fixed',
          bottom: '2rem',
          right: '2rem',
          zIndex: 1000,
          borderStyle: 'solid',
          borderRadius: '50%',
          borderWidth: '5px',
          boxShadow: 'large'
        }}
        data-tip={formatMessage({ id: 'demo.message' }, { time: `${h}:${m}` })}
      >
        {h}
        <Text sx={{ animation: `${blink} 1s steps(5, start) infinite` }}>
          :
        </Text>
        {m}
      </Flex>
      <ReactTooltip effect="solid" />
    </>
  )
}

function formatTime(time) {
  const h = Math.floor(time / 3600000)
  const m = Math.floor((time - h * 3600000) / 60000)
  return [('0' + h).slice(-2), ('0' + m).slice(-2)]
}

function getTimeLeft(time) {
  if (time >= slots[0] && time < slots[1]) return slots[1] - time
  if (time >= slots[1] && time < slots[2]) return slots[2] - time
  if (time >= slots[2] && time < slots[3]) return slots[3] - time
  if (time >= slots[3] && time < slots[4]) return slots[4] - time
  if (time >= slots[4] && time < slots[5]) return slots[5] - time
  if (time >= slots[5] && time < slots[6]) return slots[6] - time
  if (time >= slots[6] && time < slots[7]) return slots[7] - time
  if (time >= slots[7] && time < slots[8]) return slots[8] - time
}

function getTime(hour) {
  const now = new Date()
  now.setHours(hour, 0, 0, 0)
  return now.getTime()
}

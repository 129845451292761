import React, { useRef } from 'react'
import { Flex, Box, Button } from 'rebass'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import ProductImageModal from 'views/ProductImageModal'
import ProductVariantModal from 'views/ProductVariantModal'
import * as model from 'models/productPage'
import { MdArrowBack } from 'react-icons/md'
import ProductShippingModal from './ProductShippingModal'

export default ({ match }) => {
  const id = match.params.id
  const refs = {
    specRef: useRef(null),
    descRef: useRef(null),
  }
  return (
    <Form {...model} match={match} refs={refs}>
      {({ form, state, action }) => (
        <Page
          title={`product.title.${id ? 'edit' : 'add'}`}
          navId="productList"
          actions={
            <>
              <LinkButton variant="plain" url="/product">
                <MdArrowBack />
                <Message ml={1} id="btn.back" />
              </LinkButton>
              <Button variant="outlineAccent">
                <Message id="btn.save" />
              </Button>
            </>
          }
        >
          <Box
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '2fr 1fr'],
              gridGap: 3,
            }}
          >
            <Box>
              <Section title="product.section.basic">
                {form.spu}
                {form.spec}
                {form.desc}
              </Section>
              <Section mt={4} title="product.section.image">
                {form.images}
              </Section>
              <Section mt={4} title="product.section.variant">
                <Box>{form.variants}</Box>
                <Flex mt={3} justifyContent="center">
                  <Button
                    type="button"
                    variant="outlineSecondary"
                    onClick={() => action.handleVariantOpen()}
                  >
                    <Message id="product.variant.btn.add" />
                  </Button>
                </Flex>
              </Section>
              <Section mt={4} title="product.section.shippingProvider">
                {form.shippingProvider(action)}
              </Section>
            </Box>
            <Box>
              <Section title="product.section.category">
                {form.categoryId}
                {form.tag}
              </Section>
              <Section mt={4} title="product.section.order">
                {form.backorder}
              </Section>
              <Section mt={4} title="product.section.shipping">
                {/* {form.shippingFee} */}
                {form.shippingWaiverFee}
              </Section>
            </Box>
          </Box>
          <ProductImageModal
            open={state.imageOpen}
            images={state.images}
            value={state.variant}
            onConfirm={action.handleImageConfirm}
            onCancel={action.handleImageClose}
          />
          <ProductVariantModal
            open={state.open}
            options={state.options}
            value={state.variant}
            actions={{
              handleClose: action.handleVariantClose,
              handleSubmit: action.handleVariantSubmit,
            }}
          />
          <ProductShippingModal
            open={state.shippingOpen}
            value={state.shippingProvider}
            actions={{
              handleClose: action.handleShippingClose,
              handleSubmit: action.handleShippingSubmit,
            }}
          />
        </Page>
      )}
    </Form>
  )
}

import React, { useState, useEffect } from 'react'
import { Flex, Image } from 'rebass'
import FormField from 'components/FormField'
import { useDropzone } from 'react-dropzone'
import { MdAddAPhoto } from 'react-icons/md'

const url = process.env.REACT_APP_STATIC_URL

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      file.preview = reader.result
      file.alt = ''
      resolve(file)
    }
  })
}

export default ({ id, label, errMsg, containerProps, value, onUpload }) => {
  const [image, setImage] = useState(value)

  useEffect(() => {
    setImage(value)
  }, [value])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async acceptedFiles => {
      const files = await Promise.all(acceptedFiles.map(file => readFile(file)))
      const uploadedImage = await onUpload(files[0])
      setImage(uploadedImage)
    },
    accept: 'image/*',
    multiple: false,
    noDrag: true
  })

  const renderItem = () => {
    let src = image.preview
    if (!src && image.src) src = url + image.src
    if (!src) return renderEmpty()

    return (
      <Image
        src={src}
        alt={image.alt}
        width={1}
        height="10rem"
        sx={{
          objectFit: 'contain',
          opacity: 1,
          transition: '.2s ease',
          backfaceVisibility: 'hidden'
        }}
      />
    )
  }

  const renderEmpty = () => (
    <Flex justifyContent="center" alignItems="center" width={1} color="grey.4">
      <MdAddAPhoto size="48px" />
    </Flex>
  )

  return (
    <FormField id={id} label={label} errMsg={errMsg} {...containerProps}>
      <Flex
        flexWrap="wrap"
        alignContent="center"
        variant="dropzone"
        sx={{
          borderColor: isDragActive ? 'accent.1' : 'grey.3',
          cursor: 'pointer'
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {renderItem()}
      </Flex>
    </FormField>
  )
}

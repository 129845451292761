import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import * as model from 'models/discountGenerateModal'

export default ({ open, actions }) => {
  const [state, setState] = useState(model.initialState())

  const field = model.fields({ state, setState })
  const handler = model.handlers({ state, setState, actions })

  return (
    <Modal
      title="discount.title.generateCode"
      open={open}
      onClose={actions.onClose}
      width={300}
    >
      {field.count}
      {field.quantity}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          mr={2}
          type="button"
          variant="outlineSecondary"
          onClick={actions.onClose}
        >
          <FormattedMessage id="btn.close" />
        </Button>
        <Button
          type="button"
          variant="outlineAccent"
          onClick={handler.handleConfirm}
        >
          <FormattedMessage id="btn.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}

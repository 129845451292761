import React, { useState } from 'react'
import { Flex, Button } from 'rebass'
import Section from 'components/Section'
import Form from 'components/Form'
import Page from 'components/PageContainer'
import Message from 'components/Message'
import * as model from 'models/passwordPage'

export default () => {
  const [disableSubmit, setDisableSubmit] = useState(false)

  return (
    <Page title="account.password.title">
      <Form {...model} actions={{ setDisableSubmit }}>
        {({ form, action, state }) => (
          <Section>
            {form.password}
            {form.newPassword}
            {form.newPasswordConfirm}
            <Flex justifyContent="flex-end" pt={2}>
              <Button disabled={disableSubmit} variant="outlineAccent">
                <Message id="btn.submit" />
              </Button>
            </Flex>
          </Section>
        )}
      </Form>
    </Page>
  )
}

import React from 'react'
import { Text, Button } from 'rebass'
import Link from 'components/Link'
import Icon from 'components/Icon'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'name',
    label: 'permission.field.name',
    render: ({ row }) => (
      <Link url={`/permission/${row.id}/edit`}>
        <Text variant="link">{row.name}</Text>
      </Link>
    )
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <Button
        type="button"
        variant="table"
        onClick={handleDelete({ session, app, id: row.id })}
      >
        <Icon>
          <MdDelete />
        </Icon>
      </Button>
    )
  }
]

export const onLoad = async ({ state, setState, session, app }) => {
  const query = `
    query {
      roles {
        id
        merchantId
        name
        createdAt
        createdBy
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return

  setState({ data: data.permissions })
}

const handleDelete = ({ session, app, id }) => async () => {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deleteRole(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Flex, Box, Text, Button } from 'rebass'
import { FormattedMessage } from 'react-intl'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import LinkButton from 'components/LinkButton'
import Message from 'components/Message'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/permissionPage'

export default ({ match }) => {
  const roleId = match.params.id
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const { formatMessage: message } = useIntl()
  const [state, setState] = useState(model.initialState())
  const form = model.fields({ state, setState, message })

  useEffect(() => {
    model.onLoad({ setState, app, session, roleId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId])

  return (
    <Page
      title="permission.title.add"
      navId="permissionList"
      actions={
        <>
          <LinkButton variant="outlineSecondary" mr={3} url="/permission">
            <FormattedMessage id="btn.cancel" />
          </LinkButton>
          <Button variant="outlineAccent">
            <FormattedMessage id="btn.confirm" />
          </Button>
        </>
      }
    >
      <Box
        mt={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 2fr'],
          gridGap: 3
        }}
      >
        <Box>
          <Section mb={4} title="permission.section.role">
            {roleId ? (
              <Flex>
                <Message flex={1} id="permission.field.name" color="grey.5" />
                <Text flex={3} mt={1} mb={3}>
                  {state.name}
                </Text>
              </Flex>
            ) : (
              form.name
            )}
          </Section>
          {/* <Section title="permission.section.location">
            {form.locations}
          </Section> */}
        </Box>
        <Section title="permission.section.permission">
          {form.permissions}
        </Section>
      </Box>
    </Page>
  )
}

import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Link from 'components/Link'
import Popover from 'components/Popover'
import Page from 'components/PageContainer'
import * as model from 'models/productList'
import { MdFileDownload } from 'react-icons/md'

export default () => {
  const ref = useRef(null)
  const handleRefresh = () => {
    ref.current.refresh()
  }
  const handleExport = () => {
    ref.current.export()
  }
  return (
    <Page
      title="product.title.list"
      navId="productList"
      actions={
        <>
          <LinkButton variant="outlineSecondary" url="/product/add">
            <FormattedMessage id="btn.add" />
          </LinkButton>
          <Popover pl={2}>
            <Link variant="inverseLink" onClick={handleExport}>
              <MdFileDownload size="24px" />
              <Message ml={2} id="btn.export" />
            </Link>
          </Popover>
        </>
      }
    >
      <Section>
        <DataTable
          title="product.title.list"
          ref={ref}
          actions={{ handleRefresh }}
          {...model}
        />
      </Section>
    </Page>
  )
}

import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = () =>
  initializeState({
    name: '',
    phone: '',
    address: ''
  })

const validation = {
  name: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)

  return {
    name: (
      <TextInput
        id="name"
        label="customer.field.name"
        placeholder="customer.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    phone: (
      <TextInput
        id="phone"
        label="customer.field.phone"
        placeholder="customer.field.phone"
        value={state.phone}
        onChange={onTextChange('phone')}
        errMsg={state.__error__.phone}
      />
    ),
    address: (
      <TextInput
        id="address"
        label="customer.field.address"
        placeholder="customer.field.address"
        value={state.address}
        onChange={onTextChange('address')}
        errMsg={state.__error__.address}
      />
    )
  }
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match
}) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const customerId = await addCustomer(state, app, session)
    if (!customerId) return

    history.push(`/customer/${customerId}/edit`)
  },
  handleDelete: id => async () => {
    const variables = { id }
    const query = `
      mutation ($id: ID!) {
        deleteCustomer(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) return
  }
})

async function addCustomer(state, app, session) {
  const variables = {
    input: {
      name: state.name,
      phones: state.phone ? [state.phone] : [],
      addresses: state.address ? [state.address] : []
    }
  }
  const query = `
    mutation ($input: CustomerInput!) {
      addCustomer(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.addCustomer
}

import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { randomAlphaNumeric } from 'utilities/randomUtil'

export const initialState = (value = {}) =>
  initializeState({
    count: value.count || 1,
    quantity: value.quantity || 1
  })

const validation = {
  count: [{ type: 'required', message: 'error.required' }],
  quantity: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    count: (
      <TextInput
        id="count"
        type="number"
        min="1"
        label="discount.field.codeCount"
        value={state.count}
        onChange={onTextChange('count')}
        errMsg={state.__error__.count}
      />
    ),
    quantity: (
      <TextInput
        id="quantity"
        type="number"
        min="1"
        label="discount.field.codeQuantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    )
  }
}

export const handlers = ({ state, setState, actions }) => ({
  handleConfirm: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    actions.onOk(generateCodes(state))
    actions.onClose()
    setState(initialState())
  }
})

function generateCodes({ count, quantity }) {
  const codes = new Set()
  while (codes.size < count) {
    codes.add(randomAlphaNumeric(8))
  }
  return Array.from(codes).map(code => ({ code, quantity }))
}

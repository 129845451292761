const rose = ['#ef5b8d', '#e91e63', '#aa1649']
const purple = ['#b761c5', '#9c27b0', '#721d81']
const blue = ['#45c2d1', '#00acc1', '#007e8d']
const green = ['#7cc47f', '#4caf50', '#38803b']
const yellow = ['#ffb445', '#ff9800', '#ba6f00']
const red = ['#f7766c', '#f44336', '#b23128']
const grey = [
  '#fff',
  '#f9f9f9',
  '#efefef',
  '#d0d0d0',
  '#999',
  '#666',
  '#333',
  '#000',
]

export default {
  breakpoints: ['576px', '768px', '992px', '1200px'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    primary: rose,
    secondary: blue,
    accent: purple,
    success: green,
    warning: yellow,
    error: red,
    grey,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    default: {
      color: 'grey.6',
      bg: 'grey.0',
      boxShadow: 'small',
      borderRadius: '3px',
    },
    glass: {
      lineHeight: '30px',
      color: 'grey.3',
      borderRadius: '3px',
      transition: 'color 300ms linear, background-color 300ms linear',
      '&:hover': {
        color: 'grey.0',
        bg: 'rgba(200, 200, 200, 0.2)',
      },
    },
    glassActive: {
      lineHeight: '30px',
      borderRadius: '3px',
      transition: 'color 300ms linear, background-color 300ms linear',
      color: 'grey.0',
      bg: 'rgba(200, 200, 200, 0.2)',
    },
    primary: {
      lineHeight: '30px',
      color: 'grey.0',
      background: `linear-gradient(60deg, ${rose[1]}, ${rose[2]})`,
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)`,
    },
    secondary: {
      lineHeight: '30px',
      color: 'grey.0',
      background: blue[1],
      borderRadius: '3px',
      boxShadow: `0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12)`,
    },
    success: {
      color: 'grey.0',
      bg: 'success.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)`,
    },
    error: {
      color: 'grey.0',
      bg: 'error.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)`,
    },
    warning: {
      color: 'grey.0',
      bg: 'warning.1',
      borderRadius: '3px',
      boxShadow: `0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2)`,
    },
    warningOutline: {
      color: 'grey.5',
      bg: 'grey.0',
      borderRadius: '3px',
      borderColor: 'warning.1',
      borderWidth: '5px',
      borderStyle: 'solid',
    },
    dropzone: {
      p: 2,
      minHeight: '10rem',
      borderWidth: '2px',
      borderRadius: '3px',
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    },
    thumbnail: {
      objectFit: 'contain',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey.2',
    },
    inverseLink: {
      px: 3,
      py: 2,
      color: 'grey.5',
      transition: 'color 300ms, background-color 300ms',
      '&:hover': {
        color: `grey.0`,
        bg: 'accent.1',
      },
    },
    link: {
      color: 'secondary.1',
    },
  },
  text: {
    heading: {
      fontSize: 3,
      fontWeight: 300,
      lineHeight: '30px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    error: {
      color: 'error.1',
    },
    link: {
      color: 'secondary.1',
      '&:hover': {
        color: `accent.1`,
      },
    },
  },
  buttons: {
    default: {
      color: 'grey.5',
      bg: 'grey.0',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey.3',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `accent.1`,
      },
    },
    plain: {
      color: 'secondary.1',
      bg: 'grey.0',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `secondary.2`,
      },
      '&:disabled': {
        color: 'grey.4',
        borderColor: 'grey.3',
        boxShadow: 'none',
        cursor: 'not-allowed',
      },
    },
    inverseButton: {
      px: 3,
      py: 2,
      width: '100%',
      color: 'grey.5',
      bg: 'grey.0',
      border: 'none',
      borderRadius: 0,
      outline: 'none',
      cursor: 'pointer',
      transition: 'color 300ms, background-color 300ms',
      '&:hover': {
        color: `grey.0`,
        bg: 'accent.1',
      },
    },
    secondary: {
      display: 'flex',
      alignItems: 'center',
      color: 'grey.0',
      bg: 'secondary.1',
      boxShadow: `0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12)`,
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1)',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0 14px 26px -12px rgba(0, 172, 193, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 172, 193, 0.2)`,
      },
    },
    accent: {
      display: 'flex',
      alignItems: 'center',
      color: 'grey.0',
      bg: 'accent.1',
      boxShadow: `0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)`,
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1)',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)`,
      },
    },
    outlineSecondary: {
      py: 1,
      color: 'secondary.1',
      bg: 'grey.0',
      boxShadow: `0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12)`,
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1)',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'secondary.1',
      borderRadius: '30px',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0 14px 26px -12px rgba(0, 172, 193, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 172, 193, 0.2)`,
      },
      '&:disabled': {
        color: 'grey.4',
        borderColor: 'grey.3',
        boxShadow: 'none',
        cursor: 'not-allowed',
      },
    },
    outlineAccent: {
      py: 1,
      color: 'accent.1',
      bg: 'grey.0',
      boxShadow: `0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)`,
      transition: 'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1)',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'accent.1',
      borderRadius: '30px',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: `0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)`,
      },
      '&:disabled': {
        color: 'grey.4',
        borderColor: 'grey.3',
        boxShadow: 'none',
        cursor: 'not-allowed',
      },
    },
    editor: {
      p: 0,
      m: 0,
      color: 'grey.4',
      bg: 'grey.0',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    editorActive: {
      p: 0,
      m: 0,
      color: 'grey.5',
      bg: 'grey.0',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
    thumb: {
      p: 0,
      m: 0,
      color: 'grey.0',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      backfaceVisibility: 'hidden',
    },
    table: {
      p: 0,
      color: 'grey.4',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: `accent.1`,
      },
    },
    chip: {
      p: 0,
      color: 'grey.5',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      lineHeight: 0,
      cursor: 'pointer',
      '&:hover': {
        color: `accent.1`,
      },
    },
    link: {
      p: 0,
      color: 'grey.5',
      bg: 'transparent',
      border: 'none',
      outline: 'none',
      fontSize: 4,
      lineHeight: 0,
      transition: 'color 300ms linear',
      cursor: 'pointer',
      '&:hover': {
        color: `secondary.1`,
      },
    },
    tab: {
      mx: 1,
      color: 'grey.5',
      bg: 'grey.0',
      border: 'none',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      outline: 'none',
      cursor: 'pointer',
      transition: 'color 300ms linear',
      '&:hover': {
        color: `primary.1`,
      },
    },
    tabActive: {
      mx: 1,
      color: 'grey.0',
      background: `linear-gradient(5deg, ${rose[1]}, ${rose[2]})`,
      border: 'none',
      borderTopRightRadius: '5px',
      borderTopLeftRadius: '5px',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      outline: 'none',
      boxShadow: `0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2)`,
      cursor: 'pointer',
    },
  },
}

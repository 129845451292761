import React from 'react'
import { Text } from 'rebass'
import Link from 'components/Link'
import ConfirmButton from 'components/ConfirmButton'
import { request } from 'utilities/requestUtil'
import { formatDate } from 'utilities/dateUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'name',
    label: 'discount.field.name',
    render: ({ row }) => (
      <Link url={`/discount/${row.id}/edit`}>
        <Text variant="link">{row.name}</Text>
      </Link>
    )
  },
  {
    id: 'startDate',
    label: 'discount.field.startDate',
    render: ({ row }) => <Text>{formatDate(new Date(row.startDate))}</Text>
  },
  {
    id: 'endDate',
    label: 'discount.field.endDate',
    render: ({ row }) => <Text>{formatDate(new Date(row.endDate))}</Text>
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <ConfirmButton
        title="discount.title.delete"
        message="discount.message.delete"
        onClick={handleDelete({ session, app, id: row.id, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const onLoad = async ({ setState, session, app }) => {
  const { merchantId } = app.state.staff
  const variables = { input: { merchantId } }
  const query = `
    query ($input: DiscountQueryInput) {
      discountConfigs(input: $input) {
        id
        name
        startDate
        endDate
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  setState(data.discountConfigs)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
      mutation ($id: ID!) {
        deleteDiscountConfig(id: $id)
      }
    `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

import React from 'react'
import TextInput from 'components/TextInput'
import ImageInput from 'components/ImageInput'
import {
  initializeState,
  validateForm,
  handleTextChange
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { uploadImage } from 'utilities/imageUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    imageId: value.imageId || '',
    title: value.title || '',
    linkUrl: value.linkUrl || '',
    imageUrl: value.imageUrl ? { src: value.imageUrl } : {},
    seq: value.seq || 0
  })

const validation = {
  // linkUrl: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    title: (
      <TextInput
        id="title"
        label="ad.field.title"
        placeholder="ad.field.title"
        value={state.title}
        onChange={onTextChange('title')}
        errMsg={state.__error__.title}
      />
    ),
    linkUrl: (
      <TextInput
        id="linkUrl"
        label="ad.field.linkUrl"
        placeholder="ad.field.linkUrl"
        value={state.linkUrl}
        onChange={onTextChange('linkUrl')}
        errMsg={state.__error__.linkUrl}
      />
    ),
    imageUrl: (
      <ImageInput
        id="imageUrl"
        label="ad.field.imageUrl"
        value={state.imageUrl}
        onUpload={image => {
          setState({ ...state, imageUrl: image, image })
          return image
        }}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addCategoryImage(state, app, session)
    if (!ok) return

    actions.handleRefresh()
    actions.handleClose()
  }
})

async function addCategoryImage(state, app, session) {
  const { image } = state
  const variables = {
    id: state.id,
    input: { title: state.title, linkUrl: state.linkUrl },
    contentType: image.type
  }
  const query = `
    mutation ($id: ID!, $input: CategoryImageInput!, $contentType: String!) {
      addCategoryImage(id: $id, input: $input, contentType: $contentType)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addCategoryImage
  await uploadImage({ url, fields, image })
  return true
}

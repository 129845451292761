import React from 'react'
import { Text } from 'rebass'
// import Link from 'components/Link'
import { request } from 'utilities/requestUtil'

export const columns = ({ session, app, actions }) => [
  {
    id: 'spu',
    label: 'inventory.field.spu'
    // render: ({ row }) => (
    //   <Link onClick={() => console.log(row.productVariantId)}>
    //     <Text variant="link">{row.spu}</Text>
    //   </Link>
    // )
  },
  {
    id: 'sku',
    label: 'inventory.field.sku'
  },
  {
    id: 'options',
    label: 'inventory.field.options',
    render: ({ row }) => {
      return row.options ? (
        <Text>{row.options.map(item => item.value).join(' / ')}</Text>
      ) : (
        <Text color="grey.3">N/A</Text>
      )
    }
  },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right'
  }
  // {
  //   id: 'actions',
  //   render: ({ row }) => (
  //     <Box sx={{ textAlign: 'right' }}>
  //       <Button
  //         type="button"
  //         variant="table"
  //         onClick={handleDelete({ session, app, id: row.id, actions })}
  //       >
  //         <Icon>
  //           <MdDelete />
  //         </Icon>
  //       </Button>
  //     </Box>
  //   )
  // }
]

export const onLoad = async ({ setState, session, app }) => {
  const variables = {}
  const query = `
    query ($input: InventoryInput) {
      inventoryBalances(input: $input) {
        locationId
        productVariantId
        quantity
        spu
        sku
        options {
          name
          value
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.inventoryBalances)
}

import { request } from 'utilities/requestUtil'

export const handleLoad = async ({ setState, app, session }) => {
  const variables = { input: { parentId: 0 } }
  const query = `
    query($input: SupportMessageInput) {
      supportMessages(input: $input) {
        id
        type
        subject
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.supportMessages)
}

import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    name: value.name || ''
  })

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] }
  ]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="store.field.name"
        placeholder="store.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editStore(state, app, session)
      : await addStore(state, app, session)

    if (ok) {
      actions.handleRefresh()
      actions.handleClose()
    }
  }
})

async function addStore(value, app, session) {
  const variables = { input: { name: value.name, type: 'STORE' } }
  const query = `
    mutation ($input: LocationInput!) {
      addLocation(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editStore(value, app, session) {
  const { id, name } = value
  const variables = { id, input: { name } }
  const query = `
    mutation ($id: ID!, $input: LocationInput!) {
      editLocation(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React from 'react'
import { Flex, Text } from 'rebass'
import Message from 'components/Message'

export default ({ label, value, children }) => (
  <Flex mb={3} lineHeight={1.5}>
    <Message
      flex={1.5}
      id={label}
      color="grey.4"
      sx={{ whiteSpace: 'nowrap' }}
    />
    {children || <Text flex={3}>{value}</Text>}
  </Flex>
)

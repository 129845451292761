import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'

export const initialState = (value = {}) =>
  initializeState({
    code: value.code || '',
    quantity: value.quantity || 1
  })

const validation = {
  code: [{ type: 'required', message: 'error.required' }],
  quantity: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    code: (
      <TextInput
        id="code"
        label="discount.field.code"
        placeholder="discount.field.code"
        value={state.code}
        onChange={onTextChange('code')}
        errMsg={state.__error__.code}
      />
    ),
    quantity: (
      <TextInput
        id="quantity"
        type="number"
        label="discount.field.codeQuantity"
        value={state.quantity}
        onChange={onTextChange('quantity')}
        errMsg={state.__error__.quantity}
      />
    )
  }
}

export const handlers = ({ state, setState, actions }) => ({
  handleConfirm: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return
    
    actions.onOk([{ code: state.code, quantity: state.quantity }])
    actions.onClose()
    setState(initialState())
  }
})

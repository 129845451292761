import React from 'react'
import { FormattedMessage } from 'react-intl'
import LinkButton from 'components/LinkButton'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Page from 'components/PageContainer'
import * as model from 'models/permissionList'

export default () => {
  return (
    <Page
      title="permission.title.list"
      navId="permissionList"
      actions={
        <LinkButton variant="outlineSecondary" url="/permission/add">
          <FormattedMessage id="btn.add" />
        </LinkButton>
      }
    >
      <Section>
        <DataTable {...model} />
      </Section>
    </Page>
  )
}

import React from 'react'
import { Button } from 'rebass'
import { ALERT_ADD } from 'constants/actionType'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import Table from 'components/Table'
import {
  initializeState,
  validateForm,
  handleTextChange
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    name: value.name || '',
    items: value.items || []
  })

const validation = {
  name: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="productAd.field.name"
        placeholder="productAd.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    items: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'productAd.field.spu'
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ row }) => (
              <Button
                type="button"
                variant="table"
                onClick={() => {
                  const items = [...state.items]
                  const itemIdx = items.findIndex(item => item.id === row.id)
                  items.splice(itemIdx, 1)
                  setState({ ...state, items })
                }}
              >
                <Icon>
                  <MdDelete />
                </Icon>
              </Button>
            )
          }
        ]}
        rows={state.items}
      />
    )
  }
}

export const handlers = ({
  match,
  history,
  state,
  setState,
  session,
  app,
  actions
}) => ({
  handleLoad: async () => {
    const configs = await getConfigs({ session, app })
    actions.setProducts(configs.products)

    const { id } = match.params
    if (id) {
      const data = await getProductAd({ session, app, id })
      setState(initialState({ ...data.productAd, items: data.productAdItems }))
    }
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editProductAd(state, app, session)
      : await addProductAd(state, app, session)

    if (ok) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'success.save' }
      })
    }
  }
})

async function getConfigs({ session, app }) {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query($merchantId: ID) {
      products(merchantId: $merchantId) {
        id
        merchantId
        primaryImage {
          src
          alt
        }
        spu
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data
}

async function getProductAd({ session, app, id }) {
  const variables = { id }
  const query = `
    query($id: ID) {
      productAd(id: $id) {
        id
        name
        seq
      }
      productAdItems(productAdId: $id) {
        productId
        spu
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  return data
}

async function addProductAd(state, app, session) {
  const variables = {
    input: {
      name: state.name,
      seq: 0,
      items: state.items.map(item => ({
        productId: item.id,
        seq: 0,
        spu: item.spu,
        startTime: '2019-01-01 01:02:03',
        endTime: '2020-01-01 01:02:03'
      }))
    }
  }
  const query = `
    mutation ($input: ProductAdInput!) {
      addProductAd(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editProductAd(state, app, session) {
  const variables = {
    id: state.id,
    input: {
      name: state.name,
      seq: 0,
      items: state.items.map((item, index) => ({
        productId: item.productId || item.id,
        seq: index,
        spu: item.spu,
        startTime: '2019-01-01 01:02:03',
        endTime: '2020-01-01 01:02:03'
      }))
    }
  }
  const query = `
    mutation ($id: ID!, $input: ProductAdInput!) {
      editProductAd(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Table from 'components/Table'
import {
  initializeState,
  handleSelectChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    fromLocations: value.fromLocations || [],
    toLocations: value.toLocations || [],
    products: value.products || [],
    id: value.id || '',
    fromLocationId: getLocationId(value.fromLocations, value.fromLocationId),
    toLocationId: getLocationId(value.toLocations, value.toLocationId),
    ticketItems: value.ticketItems || []
  })

const validation = {
  toLocationId: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState, refs }) => {
  return {
    fromLocation: (
      <Select
        id="fromLocationId"
        placeholder="move.field.fromLocation"
        options={state.fromLocations}
        value={state.fromLocationId}
        onChange={handleSelectChange(
          'fromLocationId',
          state,
          setState,
          validation
        )}
        errMsg={state.__error__.fromLocationId}
      />
    ),
    toLocation: (
      <Select
        id="toLocationId"
        placeholder="move.field.toLocation"
        options={state.toLocations}
        value={state.toLocationId}
        onChange={handleSelectChange(
          'toLocationId',
          state,
          setState,
          validation
        )}
        errMsg={state.__error__.toLocationId}
      />
    ),
    productFilter: (
      <Select
        id="productFilter"
        placeholder="move.field.productFilter"
        options={state.products.map(item => ({
          value: item.id,
          label: getProductLabel(item)
        }))}
        value=""
        onChange={product =>
          handleProductChange({ product, state, setState, app, session })
        }
      />
    ),
    product: (
      <Table
        columns={[
          {
            id: 'sku',
            label: 'move.field.sku'
          },
          {
            id: 'availableQuantity',
            label: 'move.field.availableQuantity',
            render: ({ row }) => row.availableQuantity || 0
          },
          {
            id: 'quantity',
            label: 'move.field.quantity',
            width: '128px',
            render: ({ row, index }) => (
              <TextInput
                containerProps={{ m: 0 }}
                type="number"
                min="1"
                value={
                  state.ticketItems[index]
                    ? state.ticketItems[index].quantity || 1
                    : 1
                }
                onChange={event => {
                  const quantity = event.target.value
                  if (quantity < 1) return

                  const ticketItems = [...state.ticketItems]
                  const product = ticketItems[index]
                  product.quantity = quantity
                  ticketItems.splice(index, 1, product)
                  setState({ ...state, ticketItems })
                }}
              />
            )
          }
        ]}
        rows={state.ticketItems}
      />
    ),
    ticket: (
      <Table
        columns={[
          {
            id: 'sku',
            label: 'move.field.sku'
          },
          {
            id: 'quantity',
            label: 'move.field.quantity'
          }
        ]}
        rows={state.ticketItems}
      />
    )
  }
}

function getLocationId(groups, id) {
  if (!groups && !id) return ''

  for (const group of groups) {
    const option = group.options.find(({ value }) => value === id)
    if (option) return option
  }

  return ''
}

function getProductLabel(product) {
  const { spu, options } = product
  return (
    spu +
    (options && options.length > 0
      ? ` [${options.map(({ value }) => value).join(', ')}]`
      : '')
  )
}

function handleProductChange({ product, state, setState, app, session }) {
  if (!product) return

  const { value } = product
  if (state.ticketItems.some(({ id }) => id === value)) return

  const ticketItem = state.products.find(({ id }) => id === value)
  ticketItem.quantity = 1

  if (!state.fromLocationId) {
    setState({
      ...state,
      ticketItems: [...state.ticketItems, ticketItem]
    })
    return
  }

  const locationId = state.fromLocationId.value
  getBalance({
    app,
    session,
    productId: value,
    locationId
  }).then(availableQuantity => {
    ticketItem.availableQuantity = availableQuantity
    setState({
      ...state,
      ticketItems: [...state.ticketItems, ticketItem]
    })
  })
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match
}) => ({
  handleLoad: async () => {
    const configs = await getConfigs({ app, session })
    const fromLocations = getLocationOptions(configs.locations)
    const toLocations = getLocationOptions(configs.locations)
    let id = match.params.id

    if (id) {
      const { ticket, ticketItems } = await getTicket({ app, session, id })
      const fromLocation = getLocationId(fromLocations, ticket.fromLocationId)
      const toLocation = getLocationId(toLocations, ticket.toLocationId)
      setState(
        initialState({
          id,
          products: configs.productVariants,
          fromLocations,
          fromLocationId: fromLocation.value,
          toLocations,
          toLocationId: toLocation.value,
          ticketItems: ticketItems
        })
      )
      return
    }

    setState(
      initialState({
        fromLocations,
        toLocations,
        products: configs.productVariants
      })
    )
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addMove(state, app, session)
    if (ok) {
      history.push('/move')
    }
  },
  handleDelete: id => async () => {
    const variables = { id }
    const query = `
      mutation ($id: ID!) {
        deleteProduct(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }
  }
})

function getLocationOptions(locations) {
  return locations.reduce((result, location) => {
    const option = { value: location.id, label: location.name }
    const group = result.find(({ type }) => type === location.type)
    if (group) {
      group.options.push(option)
    } else {
      result.push({
        type: location.type,
        label: <FormattedMessage id={`location.type.${location.type}`} />,
        options: [option]
      })
    }
    return result
  }, [])
}

async function getConfigs({ app, session }) {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query ($merchantId: ID!, $input: LocationQueryInput) {
      locations(merchantId: $merchantId, input: $input) {
        id
        type
        name
      }
      productVariants (merchantId: $merchantId) {
        id
        spu
        sku
        barcode
        options {
          name
          value
        }
        image {
          src
          alt
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return null

  return {
    locations: data.locations,
    productVariants: data.productVariants
  }
}

async function addMove(state, app, session) {
  const { staff } = app.state
  const variables = {
    input: {
      ticketType: 'MOVE',
      fromLocationId: state.fromLocationId.value,
      fromLocation: state.fromLocationId.label,
      toLocationId: state.toLocationId.value,
      toLocation: state.toLocationId.label,
      ticketItems: state.ticketItems.map(item => ({
        merchantId: staff.merchantId,
        productVariantId: item.id,
        quantity: parseInt(item.quantity)
      }))
    }
  }
  const query = `
    mutation ($input: TicketInput!) {
      createTicket(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function getTicket({ app, session, id }) {
  const variables = { ticketId: id }
  const query = `
    query($ticketId: ID!) {
      ticket(ticketId: $ticketId) {
        fromLocationId
        fromLocation
        toLocationId
        toLocation
        status
      }
      ticketItems(ticketId: $ticketId) {
        productVariantId
        spu
        sku
        options {
          name
          value
        }
        quantity
        transDate
        status
        createdAt
        createdBy
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data
}

async function getBalance({ app, session, productId, locationId }) {
  const variables = { input: { locationId, variants: [productId] } }
  const query = `
    query($input: InventoryInput) {
      inventoryBalances(input: $input) {
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const balances = data.inventoryBalances
  return balances.length > 0 ? balances[0].quantity : 0
}

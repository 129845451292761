import React from 'react'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}, message) =>
  initializeState({
    id: value.id || '',
    type: value.type
      ? {
          value: value.type,
          label: message({ id: `staff.type.${value.type}` })
        }
      : '',
    username: value.username || '',
    password: value.password || ''
  })

const validation = {
  username: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] }
  ],
  password: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] }
  ]
}

export const fields = ({ state, setState, message }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  const fields = {
    type: (
      <Select
        id="type"
        isSearchable={false}
        isClearable={false}
        label="staff.field.type"
        placeholder="staff.field.type"
        options={[
          {
            value: 'ADMIN',
            label: message({ id: 'staff.type.ADMIN' })
          },
          {
            value: 'HR',
            label: message({ id: 'staff.type.HR' })
          },
          {
            value: 'ACCOUNTANT',
            label: message({ id: 'staff.type.ACCOUNTANT' })
          },
          {
            value: 'SALES',
            label: message({ id: 'staff.type.SALES' })
          }
        ]}
        value={state.type}
        onChange={handleSelectChange('type', state, setState, validation)}
      />
    ),
    username: (
      <TextInput
        id="username"
        label="staff.field.username"
        placeholder="staff.field.username"
        value={state.username}
        onChange={onTextChange('username')}
        errMsg={state.__error__.username}
      />
    )
  }

  if (!state.id) {
    fields.password = (
      <TextInput
        type="password"
        label="staff.field.password"
        placeholder="staff.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={onTextChange('password')}
        errMsg={state.__error__.password}
      />
    )
  }

  return fields
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()

    const validationRules = state.id
      ? Object.keys(validation).reduce((result, item, index) => {
          if (item !== 'password') {
            result[item] = validation[index]
          }
          return result
        }, {})
      : validation
    if (!validateForm({ state, setState, validation: validationRules })) return

    const ok = state.id
      ? await editStaff(state, app, session)
      : await addStaff(state, app, session)

    if (!ok) return

    actions.handleRefresh()
    actions.handleClose()
  }
})

async function addStaff(value, app, session) {
  const { username, password, type } = value
  const variables = { input: { username, password, type: type.value } }
  const query = `
    mutation ($input: StaffInput!) {
      addStaff(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editStaff(value, app, session) {
  const { id, username, type } = value
  const variables = { id, input: { username, type: type.value } }
  const query = `
    mutation ($id: ID!, $input: StaffInput!) {
      editStaff(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React, { useState, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Text } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import { columns, onLoad, createCategoryTree } from 'models/categoryAdList'
import Page from 'components/PageContainer'
import Section from 'components/Section'
import Table from 'components/Table'
import CategoryAdModal from 'views/CategoryAdModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [categories, setCategories] = useState([])
  const [open, setOpen] = useState(false)
  const categoryTree = createCategoryTree(categories)
  const [category, setCategory] = useState({})
  const categoryImages = category.images || []
  const [image, setImage] = useState({})

  useEffect(() => {
    onLoad({ setCategories, session, app })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (category.id) {
      setCategory(categories.find(item => item.id === category.id))
    } else {
      setCategory(getDefaultCategory(categoryTree))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.id, categories])

  const handleOpen = value => {
    setImage({ ...value, id: category.id })
    setOpen(true)
  }

  const handleClose = () => {
    setImage({})
    setOpen(false)
  }

  const handleRefresh = () => {
    onLoad({ setCategories, session, app })
  }

  const renderCategoryTree = items => {
    if (!items || items.length === 0) return null

    return (
      <Box mb={3}>
        {items.map(item => {
          const isRoot = item.parentCategoryId === '0'
          return (
            <Box key={item.id}>
              <Text
                pb={2}
                color={
                  item.id === category.id
                    ? 'accent.1'
                    : isRoot
                    ? 'grey.4'
                    : 'grey.5'
                }
                sx={{ cursor: 'pointer' }}
                onClick={() => setCategory(item)}
              >
                {item.name}
              </Text>
              {renderCategoryTree(item.children)}
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Page
      title="categoryAd.title.list"
      navId="categoryAdList"
      actions={
        <Button
          type="button"
          variant="outlineSecondary"
          onClick={() => handleOpen({})}
        >
          <FormattedMessage id="btn.add" />
        </Button>
      }
    >
      <Box
        mt={2}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 3fr'],
          gridGap: 3
        }}
      >
        <Section title="categoryAd.section.category">
          {renderCategoryTree(categoryTree)}
        </Section>
        <Section>
          <Table
            columns={columns({
              session,
              app,
              states: { categories, category },
              actions: { handleRefresh }
            })}
            rows={categoryImages}
          />
        </Section>
      </Box>
      <CategoryAdModal
        open={open}
        value={image}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

const getDefaultCategory = items => {
  if (!items || items.length === 0) return {}
  return items[0]
}

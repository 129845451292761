import React from 'react'
import { Flex } from 'rebass'
import { request } from 'utilities/requestUtil'
import { initializeState, handleTextChange } from 'utilities/formUtil'
import Link from 'components/Link'
import Checkbox from 'components/Checkbox'
import TextInput from 'components/TextInput'
import Message from 'components/Message'
import Table from 'components/Table'
import Collapsible from 'components/Collapsible'
import IconWrapper from 'components/Icon'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

export const initialState = (value = {}) =>
  initializeState({
    name: value.role ? value.role.name || '' : '',
    locations: value.locations || [],
    locationAll: false,
    permissions: value.permissions || []
  })

const validation = {
  name: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState, message }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="permission.field.name"
        placeholder="permission.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    locations: (
      <Table
        columns={[
          {
            id: 'selector',
            renderHead: () => (
              <Checkbox
                checked={state.locationAll}
                onChange={() =>
                  setState({
                    ...state,
                    locationAll: !state.locationAll,
                    locations: state.locations.map(item => ({
                      ...item,
                      checked: !state.locationAll
                    }))
                  })
                }
              />
            ),
            render: ({ row, index }) => (
              <Checkbox
                checked={row.checked}
                onChange={() => {
                  const locations = [...state.locations]
                  locations[index].checked = !row.checked
                  setState({ ...state, locations })
                }}
              />
            )
          },
          {
            id: 'module',
            label: 'permission.field.module',
            render: ({ row }) => message({ id: `module.${row.module}` })
          },
          {
            id: 'action',
            label: 'permission.field.action',
            render: ({ row }) => message({ id: `action.${row.action}` })
          }
        ]}
        rows={state.locations}
      />
    ),
    permissions: Object.entries(state.permissions).map(([key, val]) => (
      <Flex
        key={key}
        flexDirection="column"
        py={2}
        sx={{
          borderBottomColor: 'grey.2',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px'
        }}
      >
        <Link onClick={() => togglePermissionOpen(state, setState, key)}>
          <Checkbox
            checked={val.active}
            onChange={event =>
              togglePermissionActive(event, state, setState, key)
            }
          />
          <Message id={`module.${key}`} ml={2} mr="auto" />
          <IconWrapper ml={3}>
            {val.open ? <MdArrowDropUp /> : <MdArrowDropDown />}
          </IconWrapper>
        </Link>
        <Collapsible open={val.open}>
          {val.items.map((item, index) => (
            <Flex key={index} py={1} ml={4}>
              <Checkbox
                mr={2}
                checked={val.items[index].active}
                onChange={event =>
                  toggleActionActive(event, state, setState, key, index)
                }
              />
              <Message key={index} id={`${key}.${item.action}`} />
            </Flex>
          ))}
        </Collapsible>
      </Flex>
    ))
  }
}

function togglePermissionOpen(state, setState, key) {
  const permissions = { ...state.permissions }
  permissions[key].open = !permissions[key].open
  setState({ ...state, permissions })
}

function togglePermissionActive(event, state, setState, key) {
  event.stopPropagation()
  const permissions = { ...state.permissions }
  const permission = permissions[key]
  const items = [...permission.items]
  items.forEach(item => {
    item.active = !permission.active
  })
  permission.active = !permission.active
  permission.items = items

  setState({ ...state, permissions })
}

function toggleActionActive(event, state, setState, key, index) {
  event.stopPropagation()
  const permissions = { ...state.permissions }
  const items = [...permissions[key].items]
  items[index].active = !items[index].active
  setState({ ...state, permissions })
}

export const onLoad = async ({ setState, app, session, roleId }) => {
  const data = await getData({ app, session, roleId })
  setState(initialState(data))
}

async function getData({ app, session, roleId }) {
  const variables = { id: roleId }
  let query = `
    query($id: ID!) {
      role(id: $id) {
        id
        name
      }
      rolePermissions(roleId: $id)
      permissions
    }
  `
  if (!roleId) {
    query = `
      query {
        permissions
      }
    `
  }
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  const permissions = data.permissions.reduce((result, code) => {
    const [module, action] = code.split('::')
    if (!result[module]) {
      result[module] = { open: false, active: false, items: [] }
    }
    result[module].items.push({ action, active: false })
    return result
  }, {})

  return {
    role: data.role || {},
    permissions
  }
}

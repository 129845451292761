import { request } from 'utilities/requestUtil'
import { addDays, formatDate } from 'utilities/dateUtil'

export const initialState = {
  dailyPie: [],
  weeklyPie: [],
  monthlyPie: [],
  monthlyBar: [],
  shipList: [],
  refundList: [],
}

export const onLoad = async ({ setState, session, app, message }) => {
  const data = await getData({ session, app, message })
  return setState(data)
}

async function getData({ app, session, message }) {
  const { merchantId } = app.state.staff
  const toDate = new Date()
  const fromDate = addDays(toDate, -30)
  const dailyInput = {
    ticketType: ['SELL', 'REFUND'],
    fromDate,
    toDate,
  }
  const input = {
    ticketType: ['SELL'],
    fromTransDate: formatDate(new Date()),
    status: ['SHIP_PENDING', 'REFUND_PENDING'],
    countPerPage: -1,
  }
  const variables = { merchantId, input, dailyInput }
  const query = `
    query($merchantId: ID, $input: TicketQueryInput, $dailyInput: ActivitySummaryInput!) {
      dailySummary(input: $dailyInput) {
        ticketType
        year
        month
        day
        status
        ticketCount
      }
      tickets(merchantId: $merchantId, input: $input) {
        data {
          id
          ticketType
          transDate
          status
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const tickets = data.tickets.data
  const dailyMap = getDailyMap(data.dailySummary)
  const monthlyBar = getBarData(fromDate, toDate, dailyMap, message)
  return {
    dailyPie: getPieData(addDays(toDate, -3), toDate, dailyMap, message),
    weeklyPie: getPieData(addDays(toDate, -7), toDate, dailyMap, message),
    monthlyPie: getPieData(addDays(toDate, -30), toDate, dailyMap, message),
    monthlyBar,
    shipList: tickets.filter(({ status }) => status === 'SHIP_PENDING'),
    refundList: tickets.filter(({ status }) => status === 'REFUND_PENDING'),
  }
}

function getBarData(fromDate, toDate, dailyMap, message) {
  const result = []
  const endDate = formatDate(toDate)
  let date = formatDate(addDays(fromDate, 1))

  while (date <= endDate) {
    const record = dailyMap[date] || {}
    const data = { date }
    if (record.SELL) {
      const name = message({ id: `dashboard.ticketType.SELL` })
      data[name] = record.SELL
    }
    if (record.REFUND) {
      const name = message({ id: `dashboard.ticketType.REFUND` })
      data[name] = record.REFUND
    }
    result.push(data)
    date = formatDate(addDays(date, 1))
  }

  return result
}

function getPieData(fromDate, toDate, dailyMap, message) {
  const result = []
  const endDate = formatDate(toDate)
  let date = formatDate(addDays(fromDate, 1))

  while (date <= endDate) {
    const record = dailyMap[date] || {}
    if (record.SELL) {
      const name = message({ id: `dashboard.ticketType.SELL` })
      result.push({ name, value: record.SELL })
    }
    if (record.REFUND) {
      const name = message({ id: `dashboard.ticketType.REFUND` })
      result.push({ name, value: record.REFUND })
    }
    date = formatDate(addDays(date, 1))
  }

  return result
}

function getDailyMap(dailySummary) {
  return dailySummary.reduce((result, item) => {
    const { year, ticketCount, ticketType } = item
    const month = ('0' + item.month).slice(-2)
    const day = ('0' + item.day).slice(-2)
    const key = `${year}-${month}-${day}`
    const record = result[key] || {}
    result[key] = { ...record, [ticketType]: ticketCount }
    return result
  }, {})
}

// function getCount(status, count) {
//   switch (status) {
//     case 'ACTIVE':
//       return { activeCount: count }
//     case 'SHIP_PENDING':
//       return { shippingCount: count }
//     case 'PAY_PENDING':
//       return { payingCount: count }
//     case 'REFUND_PENDING':
//       return { refundingCount: count }
//     case 'FULL_REFUND':
//     case 'PARTIAL_REFUND':
//       return { refundedCount: count }
//     default:
//   }
// }

import React, { useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'rebass'
import Page from 'components/PageContainer'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import AdBlockModal from 'views/AdBlockModal'
import * as model from 'models/adBlockList'

export default () => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setValue({})
  }
  const handleRefresh = () => {
    ref.current.refresh()
  }

  return (
    <Page
      title="adBlock.title.list"
      navId="adBlockList"
      actions={
        <Button type="button" variant="outlineSecondary" onClick={handleOpen}>
          <FormattedMessage id="btn.add" />
        </Button>
      }
    >
      <Section>
        <DataTable ref={ref} actions={{ handleRefresh }} {...model} />
      </Section>
      <AdBlockModal
        open={open}
        value={value}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

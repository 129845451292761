import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import LinkButton from 'components/LinkButton'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Page from 'components/PageContainer'
import * as model from 'models/adjustList'

export default () => {
  const ref = useRef(null)
  const handleRefresh = () => {
    ref.current.refresh()
  }
  return (
    <Page
      title="adjust.title.list"
      navId="adjustList"
      actions={
        <LinkButton variant="outlineSecondary" url="/adjust/add">
          <FormattedMessage id="btn.add" />
        </LinkButton>
      }
    >
      <Section>
        <DataTable ref={ref} actions={{ handleRefresh }} {...model} />
      </Section>
    </Page>
  )
}

import React from 'react'
import ConfirmButton from 'components/ConfirmButton'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'title',
    label: 'keyword.field.title'
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <ConfirmButton
        title="keyword.title.delete"
        message="keyword.message.delete"
        onClick={handleDelete({ session, app, id: row.id, actions })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const onLoad = async ({ setState, session, app, actions }) => {
  const { merchantId } = app.state.staff
  const variables = { merchantId, type: 'PRODUCT', source: 'HOME_KEYWORD' }
  const query = `
    query($merchantId: ID!, $type: String, $source: String) {
      ads(merchantId: $merchantId, type: $type, source: $source) {
        id
        title
        seq
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  setState(data.ads)
}

const handleDelete = ({ session, app, id, actions }) => async handleClose => {
  const variables = { id }
  const query = `
      mutation ($id: ID!) {
        deleteAd(id: $id)
      }
    `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleRefresh()
}

import React, { useEffect, useState, useContext } from 'react'
import { Flex, Button } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Message from 'components/Message'
import Modal from 'components/Modal'
import { initialState, handlers, fields } from 'models/backorderModal'

export default ({ open, value, onClose, action }) => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({ products: value }))
  const form = fields({ state, setState })
  const handler = handlers({ session, app, state, setState, action })

  useEffect(() => {
    setState(initialState({ products: value }))
  }, [value])

  return (
    <Modal
      as="form"
      onSubmit={handler.handleSubmit}
      title="purchase.title.add"
      open={open}
      onClose={onClose}
      width={['100%', 600]}
    >
      {form.productFilter}
      {form.product}
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          mr={2}
          type="button"
          variant="outlineSecondary"
          onClick={onClose}
        >
          <Message id="btn.cancel" />
        </Button>
        <Button variant="outlineAccent">
          <Message id="btn.save" />
        </Button>
      </Flex>
    </Modal>
  )
}

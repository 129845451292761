import React from 'react'
import { Flex, Text } from 'rebass'
import Link from 'components/Link'

const Li = ({ children }) => (
  <Flex
    as="li"
    alignItems="center"
    color="grey.5"
    sx={{
      '& + &:before': {
        padding: '0 8px',
        content: '"/"'
      }
    }}
  >
    {children}
  </Flex>
)

export default ({ items, ...props }) => {
  if (!items) return null

  return (
    <Flex
      as="ul"
      p={0}
      m={0}
      color="grey.0"
      sx={{
        listStyle: 'none'
      }}
      {...props}
    >
      {items.map((item, index) => (
        <Li key={index}>
          {item.url ? (
            <Link url={item.url}>{item.label}</Link>
          ) : (
            <Text color="grey.4">{item.label}</Text>
          )}
        </Li>
      ))}
    </Flex>
  )
}

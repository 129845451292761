import React from 'react'
import { Text, Flex } from 'rebass'
import Link from 'components/Link'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import { request } from 'utilities/requestUtil'

export const columns = ({ session, app, actions }) => [
  {
    id: 'name',
    label: 'customer.field.name',
    render: ({ row }) => (
      <Link url={`/customer/${row.id}/edit`}>
        <Text variant="link">{row.name}</Text>
      </Link>
    ),
    renderText: ({ row }) => row.name,
  },
  {
    id: 'phone',
    label: 'customer.field.phone',
    render: ({ row }) =>
      row.phones.map((item) => <Text key={item}>{item}</Text>),
    renderText: ({ row }) => row.phones.join(', '),
  },
  {
    id: 'address',
    label: 'customer.field.address',
    render: ({ row }) =>
      row.addresses.map((item) => <Text key={item}>{item}</Text>),
    renderText: ({ row }) => row.addresses.join(', '),
  },
  // {
  //   id: 'actions',
  //   render: ({ row }) => (
  //     <Box sx={{ textAlign: 'right' }}>
  //       <Button
  //         type="button"
  //         variant="table"
  //         onClick={handleDelete({ session, app, id: row.id, actions })}
  //       >
  //         <Icon>
  //           <MdDelete />
  //         </Icon>
  //       </Button>
  //     </Box>
  //   )
  // }
]

export const filters = ({
  message,
  filterOp,
  setFilterOp,
  filterInput,
  setFilterInput,
}) => [
  {
    id: 'name',
    label: 'customer.field.name',
    input: (
      <TextInput
        containerProps={{ mb: 0, flex: 3 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    ),
  },
  {
    id: 'phone',
    label: 'customer.field.phone',
    input: (
      <TextInput
        containerProps={{ mb: 0, flex: 3 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    ),
  },
  {
    id: 'address',
    label: 'customer.field.address',
    input: (
      <TextInput
        containerProps={{ mb: 0, flex: 3 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    ),
  },
  {
    id: 'createdAt',
    label: 'customer.field.createdAt',
    op: (
      <Select
        containerProps={{ mb: [2, 0], mr: 3, flex: 1, width: 1 }}
        isSearchable={false}
        isClearable={false}
        placeholder="filter.placeholder"
        options={[
          {
            value: 'EQ',
            label: message({ id: 'filter.op.eq' }),
          },
          {
            value: 'GE',
            label: message({ id: 'filter.op.ge' }),
          },
          {
            value: 'LE',
            label: message({ id: 'filter.op.le' }),
          },
        ]}
        value={filterOp}
        onChange={setFilterOp}
      />
    ),
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 2], width: 1 }}
        type="date"
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    ),
    getLabel: (item) => (
      <Flex alignItems="center" sx={{ lineHeight: 0 }}>
        {message({ id: 'customer.field.createdAt' })}
        <Text px={1}>
          {message({ id: `filter.op.${item.op.toLowerCase()}` })}
        </Text>
        {item.value}
      </Flex>
    ),
  },
]

export const onLoad = async ({
  state,
  setState,
  setExcel,
  session,
  app,
  page,
  filter = [],
  isAppend = false,
}) => {
  const countPerPage = setExcel ? -1 : 25
  const input = { page, countPerPage }
  filter.forEach((item) => {
    if (item.id === 'createdAt') {
      switch (item.op) {
        case 'GE':
          input.fromCreatedAt = item.value
          break
        case 'LE':
          input.toCreatedAt = item.value
          break
        default:
          input.fromCreatedAt = item.value
          input.toCreatedAt = item.value
      }
    } else {
      input[item.id] = item.value
    }
  })
  const variables = { input }
  const query = `
    query($input: CustomerQueryInput) {
      customers(input: $input) {
        data {
          id
          name
          phones
          addresses
        }
        nextPage
        countPerPage
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  const { customers } = data
  if (setExcel) {
    setExcel(customers.data)
    return
  }
  if (!isAppend) {
    setState(customers)
    return
  }
  const items = customers.data
  setState({
    data: [...(state.data || []), ...items],
    nextPage: items.length === 0 ? null : customers.nextPage,
    countPerPage: customers.countPerPage,
  })
  return
}

// function handleDelete({ session, app, id, actions }) {
//   return async () => {
//     const variables = { id }
//     const query = `
//         mutation ($id: ID!) {
//           deleteCustomer(id: $id)
//         }
//       `
//     const [ok] = await request({ query, variables }, { session, app })
//     if (ok) {
//       actions.handleRefresh()
//     }
//   }
// }

import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'rebass'
import * as model from 'models/categoryList'
import Message from 'components/Message'
import Page from 'components/PageContainer'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Breadcrumb from 'components/Breadcrumb'
import CategoryModal from 'views/CategoryModal'
import { MdHome } from 'react-icons/md'

export default ({ match }) => {
  const [open, setOpen] = useState(false)
  const [category, setCategory] = useState(null)
  const [value, setValue] = useState({})
  const ref = useRef(null)

  const handleOpen = item => {
    const parentCategoryId = category ? category.id : null
    setValue({ ...item, parentCategoryId })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  const handleRefresh = () => {
    ref.current.refresh()
  }

  useEffect(() => {
    handleRefresh()
  }, [match.params.id])

  return (
    <Page
      title="category.title.list"
      navId="categoryList"
      actions={
        <Button type="button" variant="outlineSecondary" onClick={handleOpen}>
          <Message id="btn.add" />
        </Button>
      }
    >
      {category && (
        <Section title="category.section.path" mb={4}>
          <Breadcrumb items={getBreadcrumb(category)} />
        </Section>
      )}
      <Section>
        <DataTable
          ref={ref}
          match={match}
          actions={{ handleOpen, handleRefresh, setCategory }}
          {...model}
        />
      </Section>
      <CategoryModal
        open={open}
        value={value}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

function getBreadcrumb(category) {
  if (!category) return null

  const breadcrumb = [
    {
      url: `/category`,
      label: <MdHome size="18px" />
    }
  ]

  if (!category.path) {
    breadcrumb.push({ label: category.name })
    return breadcrumb
  }

  breadcrumb.concat(
    category.path.map(item => ({
      url: `/category/${item.id}`,
      label: item.name
    }))
  )

  breadcrumb.push({ label: category.name })
  return breadcrumb
}

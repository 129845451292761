import React from 'react'
import { Flex, Image } from 'rebass'
import { Preload } from 'react-preload'
import { MdPhoto } from 'react-icons/md'
import LoadingIcon from 'components/LoadingIcon'

const url = process.env.REACT_APP_STATIC_URL

export default ({ src, alt, sx, ...props }) => {
  if (!src) return renderPlaceholder(props)
  return (
    <Preload
      loadingIndicator={renderLoader()}
      images={[url + src]}
      autoResolveDelay={3000}
      resolveOnError={true}
      mountChildren={true}
    >
      <Image
        src={url + src}
        alt={alt}
        width={1}
        height="100%"
        sx={{
          objectFit: 'cover',
          // borderWidth: '1px',
          // borderStyle: 'solid',
          // borderColor: 'grey.2',
          // borderRadius: '3px',
          ...sx
        }}
        {...props}
      />
    </Preload>
  )
}

const renderPlaceholder = props => (
  <Flex
    justifyContent="center"
    alignItems="center"
    width={1}
    height="100%"
    bg="grey.0"
    color="grey.3"
    fontSize={6}
    sx={
      {
        // borderWidth: '1px',
        // borderStyle: 'solid',
        // borderColor: 'grey.2',
        // borderRadius: '3px'
      }
    }
    {...props}
  >
    <MdPhoto />
  </Flex>
)

const renderLoader = () => (
  <Flex
    width={1}
    height="100%"
    bg="transparent"
    justifyContent="center"
    alignItems="center"
  >
    <LoadingIcon />
  </Flex>
)

import React, { useState, useContext, useEffect } from 'react'
import { Box, Text } from 'rebass'
import { useIntl } from 'react-intl'
import { initialState, onLoad } from 'models/dashboard'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Link from 'components/Link'
import Table from 'components/Table'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'

const color = { active: '#0088FE', refund: '#00C49F' }

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const handleLoad = () => onLoad({ setState, session, app, message })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPieSummary = ({ data }) => {
    return (
      <PieChart width={288} height={300}>
        <Pie
          data={data}
          cx={288 / 2}
          cy={110}
          innerRadius={60}
          outerRadius={80}
          paddingAngle={5}
          dataKey="value"
          label
        >
          {data.map((entry, index) => {
            const sell = message({ id: 'dashboard.ticketType.SELL' })
            const fill = entry.name === sell ? color.active : color.refund
            return <Cell key={`cell-${index}`} fill={fill} />
          })}
        </Pie>
        <Legend align="right" verticalAlign="bottom" layout="vertical" />
      </PieChart>
    )
  }

  const renderBarSummary = (data) => {
    const sell = message({ id: 'dashboard.ticketType.SELL' })
    const refund = message({ id: 'dashboard.ticketType.REFUND' })
    return (
      <BarChart
        width={960}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Bar dataKey={sell} stackId="a" fill={color.active} />
        <Bar dataKey={refund} stackId="a" fill={color.refund} />
      </BarChart>
    )
  }

  return (
    <Page title="dashboard.title" navId="dashboard">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
          gridGap: 3,
        }}
      >
        <Section title="dashboard.section.daily">
          {renderPieSummary({
            label: message({ id: 'dashboard.section.daily' }),
            data: state.dailyPie,
          })}
        </Section>
        <Section title="dashboard.section.weekly">
          {renderPieSummary({
            label: message({ id: 'dashboard.section.weekly' }),
            data: state.weeklyPie,
          })}
        </Section>
        <Section title="dashboard.section.monthly">
          {renderPieSummary({
            label: message({ id: 'dashboard.section.monthly' }),
            data: state.monthlyPie,
          })}
        </Section>
      </Box>
      <Section mt={4} title="dashboard.section.order">
        {renderBarSummary(state.monthlyBar)}
      </Section>
      <Box
        mt={4}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          gridGap: 3,
        }}
      >
        <Section title="dashboard.section.ship">
          <Table
            columns={[
              {
                id: 'transDate',
                label: 'order.field.transDate',
              },
              {
                id: 'id',
                label: 'order.field.id',
                render: ({ row }) => (
                  <Link url={`/order/${row.id}`}>
                    <Text variant="link">{row.id}</Text>
                  </Link>
                ),
              },
            ]}
            rows={state.shipList}
          />
        </Section>
        <Section title="dashboard.section.refund">
          <Table
            columns={[
              {
                id: 'transDate',
                label: 'refund.field.transDate',
              },
              {
                id: 'id',
                label: 'order.field.id',
                render: ({ row }) => (
                  <Link url={`/order/${row.id}`}>
                    <Text variant="link">{row.id}</Text>
                  </Link>
                ),
              },
            ]}
            rows={state.refundList}
          />
        </Section>
      </Box>
    </Page>
  )
}

import * as type from 'constants/actionType'
import { isMobile } from 'utilities/deviceUtil'

export const initialState = {
  token: getLocalJson('token') || { accessToken: '', refreshToken: '' },
  navId: null,
  navOpen: isMobile() ? false : true,
  staff: getLocalJson('staff') || {},
}

export function reducer(state, action) {
  let token

  switch (action.type) {
    case type.NAV_SET:
      return { ...state, navId: action.id }
    case type.LOGOUT:
      removeLocalJson('token')
      return { ...state, token: { accessToken: '', refreshToken: '' } }
    case type.NAV_TOGGLE:
      return { ...state, navOpen: !state.navOpen }
    case type.TOKEN_SET:
      setLocalJson('token', action.token)
      return { ...state, token: action.token }
    case type.TOKEN_REMOVE:
      token = { accessToken: '', refreshToken: '' }
      setLocalJson('token', token)
      return { ...state, token }
    case type.STAFF_SET:
      setLocalJson('staff', action.staff)
      return { ...state, staff: action.staff }
    // case type.PRODUCT_SET:
    //   return { ...state, products: action.items }
    // case type.PRODUCT_ADD:
    //   return { ...state, products: addItem(state.products, action.item) }
    // case type.PRODUCT_EDIT:
    //   return { ...state, products: replaceItem(state.products, action.item) }
    // case type.PRODUCT_REMOVE:
    //   return { ...state, products: removeItem(state.products, action.id) }
    default:
      return state
  }
}

function getLocalJson(key) {
  const val = localStorage.getItem(key)
  if (!val) {
    return null
  }
  return JSON.parse(val)
}

function setLocalJson(key, val) {
  localStorage.setItem(key, JSON.stringify(val))
}

function removeLocalJson(key) {
  localStorage.removeItem(key)
}

// function addItem(list, data) {
//   const result = [...list]
//   const idx = list.findIndex(item => item.id === data.id)
//   if (idx === -1) {
//     result.push(data)
//     return result
//   }

//   result.splice(idx, 1, data)
//   return result
// }

// function replaceItem(list, data) {
//   const idx = list.findIndex(item => item.id === data.id)
//   if (idx === -1) {
//     return list
//   }

//   const result = [...list]
//   result.splice(idx, 1, data)
//   return result
// }

// function removeItem(list, id) {
//   const idx = list.findIndex(item => item.id === id)
//   if (idx === -1) {
//     return list
//   }

//   const result = [...list]
//   result.splice(idx, 1)
//   return result
// }

import React from 'react'
import { useSlate } from 'slate-react'
import { Editor, Transforms } from 'slate'
import { Button } from 'rebass'
import Icon from 'components/Icon'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export default ({ format, icon, ...props }) => {
  const editor = useSlate()
  const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: n => n.type === format
    })

    return !!match
  }
  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
      match: n => LIST_TYPES.includes(n.type),
      split: true
    })

    Transforms.setNodes(editor, {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format
    })

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }
  const onClickBlock = event => {
    event.preventDefault()
    toggleBlock(editor, format)
  }

  return (
    <Button
      tabIndex={-1}
      type="button"
      variant={isBlockActive(editor, format) ? 'editorActive' : 'editor'}
      onMouseDown={onClickBlock}
      {...props}
    >
      <Icon fontSize={3}>{icon}</Icon>
    </Button>
  )
}

import React from 'react'
import { withRouter } from 'react-router'
import { Flex } from 'rebass'

const Link = ({ history, url, children, sx, ...props }) => (
  <Flex
    as="a"
    alignItems="center"
    onClick={() => (url ? history.push(url) : {})}
    sx={{
      cursor: 'pointer',
      userSelect: 'none',
      ...sx
    }}
    {...props}
  >
    {children}
  </Flex>
)

export default withRouter(Link)

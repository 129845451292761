import React from 'react'
import Select from 'components/Select'
import TextInput from 'components/TextInput'
import {
  initializeState,
  validateForm,
  handleTextChange,
  handleSelectChange,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import Switch from 'components/Switch'
import { Flex } from 'rebass'
import Message from 'components/Message'

export const initialState = (value = {}) =>
  initializeState({
    location: value.location || {},
    spu: value.spu || '',
    productId: value.productId
      ? { value: value.productId, label: value.spu }
      : null,
    products:
      value.products.map((item) => ({ value: item.id, label: item.spu })) || [],
    isPublic: true,
  })

const validation = {
  productId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id) =>
    handleSelectChange(id, state, setState, validation)

  return {
    productId: (
      <Select
        id="productId"
        label="productShelf.field.productId"
        placeholder="productShelf.field.productId"
        options={state.products}
        value={state.productId}
        onChange={onSelectChange('productId')}
      />
    ),
    spu: (
      <TextInput
        id="spu"
        label="productShelf.field.spu"
        placeholder="productShelf.field.spu"
        value={state.spu}
        onChange={onTextChange('spu')}
        errMsg={state.__error__.spu}
      />
    ),
    isPublic: (
      <Flex alignItems="center">
        <Message flex={1} id="productShelf.field.isPublic" />
        <Switch
          checked={state.isPublic}
          onClick={() => {
            setState({ ...state, isPublic: !state.isPublic })
          }}
        />
      </Flex>
    ),
  }
}

export const handlers = ({ state, setState, app, session, actions }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const value = {
      productId: state.productId.value,
      locationId: state.location.id,
      spu: state.spu || state.productId.label,
      isPublic: state.isPublic,
    }
    const ok = await addProductShelf(value, app, session)
    if (ok) {
      const product = state.products.find(
        (item) => item.value === value.productId
      )
      value.id = value.productId
      value.productName = product.label
      actions.handleOk(value)
      actions.handleClose()
    }
  },
})

async function addProductShelf(input, app, session) {
  const variables = { input }
  const query = `
    mutation ($input: ProductShelfInput!) {
      addProductShelf(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React from 'react'
import { Button } from 'rebass'
import Icon from 'components/Icon'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Table from 'components/Table'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { formatDate } from 'utilities/dateUtil'
import { MdDelete } from 'react-icons/md'

export const initialState = (value = {}) =>
  initializeState({
    products: value.products || [],
    id: value.id || '',
    transDate: value.transDate
      ? formatDate(new Date(value.transDate))
      : getDefaultDate(),
    ticketItems: value.ticketItems || [],
  })

function getDefaultDate() {
  return formatDate(new Date())
}

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState, refs }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    transDate: (
      <TextInput
        id="transDate"
        type="date"
        label="adjust.field.transDate"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    productFilter: (
      <Select
        id="productFilter"
        placeholder="adjust.field.productFilter"
        options={state.products.map((item) => ({
          value: item.id,
          label: getProductLabel(item),
        }))}
        value=""
        onChange={(product) => {
          if (!product) return

          const { value } = product
          const ticketItems = [...state.ticketItems]
          if (ticketItems.some(({ id }) => id === value)) {
            return
          }
          const ticketItem = state.products.find(({ id }) => id === value)

          const addTicketItem = (balance) => {
            ticketItem.balance = balance
            ticketItem.quantity = balance
            setState({
              ...state,
              ticketItems: [...ticketItems, ticketItem],
            })
          }
          getBalance({ app, session, ticketItem, addTicketItem })
        }}
      />
    ),
    product: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'adjust.field.spu',
          },
          {
            id: 'sku',
            label: 'adjust.field.sku',
          },
          {
            id: 'balance',
            label: 'adjust.field.balance',
          },
          {
            id: 'quantity',
            label: 'adjust.field.quantity',
            width: '128px',
            render: ({ row, index }) => (
              <TextInput
                containerProps={{ m: 0 }}
                type="number"
                min="0"
                value={
                  state.ticketItems[index]
                    ? state.ticketItems[index].quantity
                    : 0
                }
                onChange={(event) => {
                  let quantity = event.target.value
                  if (quantity < 0) quantity = 0

                  const ticketItems = [...state.ticketItems]
                  const product = ticketItems[index]
                  product.quantity = quantity
                  ticketItems.splice(index, 1, product)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ row }) => (
              <Button
                type="button"
                variant="table"
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  const itemIdx = ticketItems.findIndex(
                    (item) => item.id === row.id
                  )
                  ticketItems.splice(itemIdx, 1)
                  setState({ ...state, ticketItems })
                }}
              >
                <Icon>
                  <MdDelete />
                </Icon>
              </Button>
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
    ticket: (
      <Table
        columns={[
          {
            id: 'spu',
            label: 'adjust.field.spu',
          },
          {
            id: 'sku',
            label: 'adjust.field.sku',
          },
          {
            id: 'quantity',
            label: 'adjust.field.quantity',
            align: 'right',
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

function getProductLabel(product) {
  const { spu, options } = product
  return (
    spu +
    (options && options.length > 0
      ? ` [${options.map(({ value }) => value).join(', ')}]`
      : '')
  )
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match,
}) => ({
  handleLoad: async () => {
    const configs = await getConfigs({ app, session })
    let id = match.params.id

    if (id) {
      const { ticket, ticketItems } = await getTicket({ app, session, id })
      setState(
        initialState({
          products: configs.productVariants,
          id,
          transDate: ticket.transDate,
          ticketItems: ticketItems,
        })
      )
      return
    }

    setState(initialState({ products: configs.productVariants }))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = await addAdjust(state, app, session)
    if (!ok) return

    const id = data.createTicket
    const { ticket, ticketItems } = await getTicket({ app, session, id })
    setState(
      initialState({
        ...state,
        id,
        transDate: ticket.transDate,
        ticketItems: ticketItems,
      })
    )
    history.push(`/adjust/${id}/edit`)
  },
  handleDelete: (id) => async () => {
    const variables = { id }
    const query = `
      mutation ($id: ID!) {
        deleteProduct(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }
  },
})

async function getConfigs({ app, session }) {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query ($merchantId: ID!) {
      productVariants(merchantId: $merchantId) {
        id
        spu
        sku
        barcode
        options {
          name
          value
        }
        image {
          src
          alt
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return null

  return { productVariants: data.productVariants }
}

async function getBalance({ app, session, ticketItem, addTicketItem }) {
  const variables = {
    input: { variants: [ticketItem.id] },
  }
  const query = `
    query($input: InventoryInput!) {
      inventoryBalances(input: $input) {
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return

  const balances = data.inventoryBalances
  addTicketItem(balances.length > 0 ? balances[0].quantity : 0)
}

async function addAdjust(state, app, session) {
  const { staff } = app.state
  const variables = {
    input: {
      ticketType: 'ADJUST',
      ticketItems: state.ticketItems.map((item) => ({
        merchantId: staff.merchantId,
        productVariantId: item.id,
        quantity: parseInt(item.quantity),
      })),
    },
  }
  const query = `
    mutation ($input: TicketInput!) {
      createTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function getTicket({ app, session, id }) {
  const variables = { ticketId: id }
  const query = `
    query($ticketId: ID!) {
      ticket(ticketId: $ticketId) {
        transDate
        status
      }
      ticketItems(ticketId: $ticketId) {
        productVariantId
        spu
        sku
        options {
          name
          value
        }
        quantity
        transDate
        status
        createdAt
        createdBy
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data
}

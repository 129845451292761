export function initializeState(value) {
  return {
    ...value,
    __error__: Object.keys(value).reduce((result, key) => {
      result[key] = ''
      return result
    }, {})
  }
}

export function handleKeyPress(state, setState, id, pattern) {
  return event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    if (!pattern.test(keyValue)) {
      event.preventDefault()
    }
  }
}

export function handleTextChange(id, state, setState, validation) {
  return event => {
    const value = event.target.value
    const { message } = validateField(value, validation[id], state)

    setState({
      ...state,
      [id]: value,
      __error__: { ...state.__error__, [id]: message }
    })
  }
}

export function handleSelectChange(id, state, setState, validation) {
  return item => {
    const value = item ? item.value : null
    const { message } = validateField(value, validation[id], state)

    setState({
      ...state,
      [id]: item,
      __error__: { ...state.__error__, [id]: message }
    })
  }
}

export function handleTagChange(id, state, setState, validation) {
  return items => {
    setState({
      ...state,
      [id]: items
    })
  }
}

export function validateForm({ state, setState, validation }) {
  const error = {}

  for (const [field, rules] of Object.entries(validation)) {
    const { hasError, message } = validateField(
      state[field],
      rules,
      state
    )

    if (hasError) {
      error[field] = message
    }
  }

  if (Object.keys(error).length > 0) {
    setState({
      ...state,
      __error__: error
    })
    return false
  }

  return true
}

export function validateField(value, rules, state) {
  let hasError = false
  let message = ''

  if (!rules) {
    return { hasError, message }
  }

  for (const validation of rules) {
    switch (validation.type) {
      case 'required':
        hasError = isEmpty(value)
        break
      case 'min':
        hasError = lessThanMin(value, validation.val)
        break
      case 'minLength':
        hasError = lessThanMinLength(value, validation.val)
        break
      case 'maxLength':
        hasError = moreThanMaxLength(value, validation.val)
        break
      case 'fieldEqual':
        hasError = notEqual(value, state[validation.name])
        break
      case 'func':
        hasError = validation.func(state)
        break
      default:
        hasError = true
        console.error('Validation rule not supported', validation.type)
    }

    if (hasError) {
      return { hasError, message: validation.message }
    }
  }

  return { hasError, message }
}

function isEmpty(value) {
  return value === null || value === ''
}

function lessThanMin(value, minValue) {
  return value < minValue
}

function lessThanMinLength(value, minValue) {
  return value.length < minValue
}

function moreThanMaxLength(value, maxValue) {
  return value.length > maxValue
}

function notEqual(value, value2) {
  return value !== value2
}

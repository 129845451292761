import React, { useRef } from 'react'
import { Box, Button, Text } from 'rebass'
import { FormattedMessage } from 'react-intl'
import Message from 'components/Message'
import LinkButton from 'components/LinkButton'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/purchasePage'
import { MdArrowBack } from 'react-icons/md'

export default ({ match }) => {
  const { id } = match.params
  const refs = {
    specRef: useRef(null),
  }

  const getActions = (id, status) => {
    if (id) {
      return (
        <LinkButton
          disabled={status === 'ACTIVE'}
          variant="outlineSecondary"
          url={`/purchase/${id}/accept`}
        >
          <FormattedMessage id="purchase.btn.addAccept" />
        </LinkButton>
      )
    }

    return (
      <Button variant="outlineAccent">
        <Message id="btn.save" />
      </Button>
    )
  }

  return (
    <Form {...model} match={match} refs={refs}>
      {({ form, state }) => (
        <Page
          title={`purchase.title.${id ? 'edit' : 'add'}`}
          navId="purchaseList"
          actions={
            <>
              <LinkButton variant="plain" url="/purchase">
                <MdArrowBack />
                <Message ml={1} id="btn.back" />
              </LinkButton>
              {getActions(state.id, state.status)}
            </>
          }
        >
          <Box
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 2fr'],
              gridTemplateRows: '1fr',
              gridGap: 3,
            }}
          >
            <Box>
              <Section title="purchase.section.basic" mb={[3, 0]}>
                {state.id ? (
                  <>
                    <Message id="purchase.field.id" color="grey.4" />
                    <Text mt={1} mb={3}>
                      {state.id}
                    </Text>
                    <Message id="purchase.field.transDate" color="grey.4" />
                    <Text mt={1} mb={3}>
                      {state.transDate}
                    </Text>
                  </>
                ) : (
                  form.transDate
                )}
              </Section>
            </Box>
            <Box>
              <Section title="purchase.section.product">
                {state.id ? (
                  form.ticket
                ) : (
                  <>
                    {form.productFilter}
                    {form.product}
                  </>
                )}
              </Section>
              <Section mt={4} title="purchase.section.arrival">
                {form.arrivalTicket}
              </Section>
            </Box>
          </Box>
        </Page>
      )}
    </Form>
  )
}

import React from 'react'
import { Box } from 'rebass'

export default ({ opacity, sx, ...props }) => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1,
      bg: 'grey.7',
      opacity,
      ...sx
    }}
    {...props}
  />
)

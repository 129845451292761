import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button } from 'rebass'
import LinkButton from 'components/LinkButton'
import Form from 'components/Form'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import * as model from 'models/movePage'

export default ({ match }) => {
  const refs = {
    specRef: useRef(null)
  }
  return (
    <Form {...model} match={match} refs={refs}>
      {({ form, action, state }) => (
        <Page
          title="move.title.add"
          navId="moveList"
          actions={
            <>
              <LinkButton variant="outlineSecondary" mr={3} url="/move">
                <FormattedMessage id="btn.cancel" />
              </LinkButton>
              <Button variant="outlineAccent">
                <FormattedMessage id="btn.confirm" />
              </Button>
            </>
          }
        >
          <Box
            mt={2}
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '2fr 1fr'],
              gridTemplateRows: '1fr',
              gridGap: 3
            }}
          >
            <Section title="move.section.product">
              {state.id ? (
                form.ticket
              ) : (
                <>
                  {form.productFilter}
                  {form.product}
                </>
              )}
            </Section>
            <Box>
              <Section mb={4} title="move.section.fromLocation">
                {form.fromLocation}
              </Section>
              <Section title="move.section.toLocation">
                {form.toLocation}
              </Section>
            </Box>
          </Box>
        </Page>
      )}
    </Form>
  )
}

import React from 'react'
import TextInput from 'components/TextInput'
import ImageInput from 'components/ImageInput'
import {
  initializeState,
  validateForm,
  handleTextChange
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { uploadImage } from 'utilities/imageUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    source: value.source || 'HOME_BLOCK',
    type: value.type || 'PRODUCT',
    title: value.title || '',
    linkUrl: value.linkUrl || '',
    imageUrl: value.imageUrl ? { src: value.imageUrl } : '',
    seq: value.seq || 0
  })

const validation = {
  // linkUrl: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    title: (
      <TextInput
        id="title"
        label="adBlock.field.title"
        placeholder="adBlock.field.title"
        value={state.title}
        onChange={onTextChange('title')}
        errMsg={state.__error__.title}
      />
    ),
    linkUrl: (
      <TextInput
        id="linkUrl"
        label="adBlock.field.linkUrl"
        placeholder="adBlock.field.linkUrl"
        value={state.linkUrl}
        onChange={onTextChange('linkUrl')}
        errMsg={state.__error__.linkUrl}
      />
    ),
    imageUrl: (
      <ImageInput
        id="imageUrl"
        label="adBlock.field.imageUrl"
        value={state.imageUrl}
        onUpload={image => {
          setState({ ...state, imageUrl: image })
          return addImage({ app, session, id: state.id, image })
        }}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleLoad: async () => {
    const id = await createAdDraft({ app, session })
    setState(initializeState({ ...state, id }))
  },
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await editAd(state, app, session)
    if (!ok) return

    actions.handleRefresh()
    actions.handleClose()

    const id = await createAdDraft({ app, session })
    setState(initialState({ id }))
  }
})

async function createAdDraft({ app, session }) {
  const query = `
    mutation {
      createAdDraft
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return null
  return data.createAdDraft
}

async function editAd(state, app, session) {
  const variables = {
    id: state.id,
    input: {
      source: state.source,
      type: state.type,
      title: state.title,
      linkUrl: state.linkUrl,
      seq: state.seq
    }
  }
  const query = `
    mutation ($id: ID!, $input: AdInput!) {
      editAd(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function addImage({ app, session, id, image }) {
  const variables = { id, contentType: image.type }
  const query = `
    mutation ($id: ID!, $contentType: String!) {
      setAdImage(id: $id, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.setAdImage
  await uploadImage({ url, fields, image })
  image.key = fields.key
  return image
}

import React, { useEffect, useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'rebass'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import { columns, onLoad } from 'models/backorderList'
import Section from 'components/Section'
import Table from 'components/Table'
import Page from 'components/PageContainer'
import BackorderModal from 'views/BackorderModal'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [open, setOpen] = useState(false)
  const handleLoad = () => onLoad({ setState, session, app })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page
      title="backorder.title.list"
      navId="backorderList"
      actions={
        <Button variant="outlineSecondary" onClick={() => setOpen(true)}>
          <FormattedMessage id="backorder.btn.addPurchase" />
        </Button>
      }
    >
      <Section>
        <Table columns={columns({ session, app })} rows={state} />
      </Section>
      <BackorderModal
        open={open}
        value={state}
        onClose={() => setOpen(false)}
        action={{ handleLoad, handleClose: () => setOpen(false) }}
      />
    </Page>
  )
}

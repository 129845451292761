import React from 'react'
import { ALERT_ADD } from 'constants/actionType'
import TextInput from 'components/TextInput'
import ImageInput from 'components/ImageInput'
import {
  initializeState,
  handleTextChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { uploadImage } from 'utilities/imageUtil'

export const initialState = (value = {}) =>
  initializeState({
    name: value.name || '',
    companyName: value.companyName || '',
    companyEmail: value.companyEmail || '',
    companyPhone: value.companyPhone || '',
    companyAddress: value.companyAddress || '',
    hostname: value.hostname || '',
    headerLogoUrl: value.headerLogoUrl ? { src: value.headerLogoUrl } : {},
    footerLogoUrl: value.footerLogoUrl ? { src: value.footerLogoUrl } : {},
    headerImage: null,
    footerImage: null,
  })

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  companyName: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="estore.field.name"
        placeholder="estore.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    companyName: (
      <TextInput
        id="companyName"
        label="estore.field.companyName"
        placeholder="estore.field.companyName"
        value={state.companyName}
        onChange={onTextChange('companyName')}
        errMsg={state.__error__.companyName}
      />
    ),
    companyPhone: (
      <TextInput
        id="companyPhone"
        label="estore.field.companyPhone"
        placeholder="estore.field.companyPhone"
        value={state.companyPhone}
        onChange={onTextChange('companyPhone')}
        errMsg={state.__error__.companyPhone}
      />
    ),
    companyEmail: (
      <TextInput
        id="companyEmail"
        label="estore.field.companyEmail"
        placeholder="estore.field.companyEmail"
        value={state.companyEmail}
        onChange={onTextChange('companyEmail')}
        errMsg={state.__error__.companyEmail}
      />
    ),
    companyAddress: (
      <TextInput
        id="companyAddress"
        label="estore.field.companyAddress"
        placeholder="estore.field.companyAddress"
        value={state.companyAddress}
        onChange={onTextChange('companyAddress')}
        errMsg={state.__error__.companyAddress}
      />
    ),
    hostname: (
      <TextInput
        id="hostname"
        label="estore.field.hostname"
        placeholder="estore.field.hostname"
        value={state.hostname}
        onChange={onTextChange('hostname')}
        errMsg={state.__error__.hostname}
      />
    ),
    headerLogoUrl: (
      <ImageInput
        id="headerLogoUrl"
        value={state.headerLogoUrl}
        onUpload={(headerImage) => {
          setState({ ...state, headerLogoUrl: headerImage, headerImage })
          return headerImage
        }}
      />
    ),
    footerLogoUrl: (
      <ImageInput
        containerProps={{ mb: 0 }}
        id="footerLogoUrl"
        value={state.footerLogoUrl}
        onUpload={(footerImage) => {
          setState({ ...state, footerLogoUrl: footerImage, footerImage })
          return footerImage
        }}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app }) => ({
  handleLoad: async () => {
    const data = await getData({ state, app, session })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await editEStore({ state, app, session })
    if (!ok) return

    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'estore.success.edit' },
    })
  },
})

async function getData({ state, app, session }) {
  const query = `
    query {
      merchant {
        estore
      }
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return
  return data.merchant.estore
}

async function editEStore({ state, app, session }) {
  const { headerImage, footerImage } = state
  const input = {
    name: state.name,
    companyName: state.companyName,
    companyEmail: state.companyEmail,
    companyPhone: state.companyPhone,
    companyAddress: state.companyAddress,
    headerContentType: headerImage ? headerImage.type : '',
    footerContentType: footerImage ? footerImage.type : '',
  }

  const variables = { input }
  const query = `
    mutation($input: EStoreInput!) {
      editEStore(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  const { editEStore } = data

  if (editEStore) {
    if (editEStore.headerImage) {
      const { url, fields } = editEStore.headerImage
      await uploadImage({ url, fields, image: headerImage })
    }

    if (editEStore.footerImage) {
      const { url, fields } = editEStore.footerImage
      await uploadImage({ url, fields, image: footerImage })
    }
  }

  return ok
}

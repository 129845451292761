import React from 'react'
import Select from 'components/Select'
import {
  initializeState,
  validateForm,
  handleSelectChange
} from 'utilities/formUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    productId: value.productId
      ? { value: value.productId, label: value.spu }
      : null,
    products:
      value.products.map(item => ({ value: item.id, label: item.spu })) || []
  })

const validation = {
  productId: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  return {
    productId: (
      <Select
        id="productId"
        label="productAd.field.productId"
        placeholder="productAd.field.productId"
        options={state.products}
        value={state.productId}
        onChange={handleSelectChange('productId', state, setState, validation)}
      />
    )
  }
}

export const handlers = ({ state, setState, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    actions.handleOk(state)
  }
})

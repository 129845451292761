import { request } from 'utilities/requestUtil'

export const onLoad = async ({ setState, app, session, ticketId }) => {
  const data = await getData({ app, session, ticketId })
  setState(data)
}

async function getData({ app, session, ticketId }) {
  const variables = { ticketId, ticketType: 'REFUND', input: { ticketId } }
  const query = `
    query ($ticketId: ID!, $ticketType: String, $input: DiscountQueryInput!) {
      ticket(ticketId: $ticketId) {
        id
        ticketType
        price
        amountBilled
        amountAcquired
        shippingFee
        transDate
        extra
        status
        createdAt
      }
      ticketItems(ticketId: $ticketId) {
        productVariantId
        spu
        sku
        options {
          name
          value
        }
        image {
          src
          alt
        }
        price
        quantity
      }
      discountTransactions(input: $input) {
        code
        discountValue
      }
      payments(ticketId: $ticketId) {
        ticketId
        transDate
        transType
        channelType
        channelProvider
        transAmount
        extra
        status
      }
      refTickets(id: $ticketId, ticketType: $ticketType) {
        id
        transDate
        ticketItems {
          productVariantId
          quantity
        }
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return null

  return data
}

export async function cancelOrder({
  setState,
  app,
  session,
  ticketId,
  handleClose,
}) {
  const variables = { id: ticketId, ticketType: 'SELL' }
  const query = `
    mutation ($id: ID!, $ticketType: String!) {
      cancelTicket(id: $id, ticketType: $ticketType)
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  if (!ok) return null

  onLoad({ setState, app, session, ticketId })
  handleClose()
}

export async function confirmRefund({
  setState,
  app,
  session,
  ticketId,
  refundId,
  handleClose,
}) {
  const variables = { id: refundId, ticketType: 'REFUND' }
  const query = `
    mutation ($id: ID!, $ticketType: String!) {
      confirmTicket(id: $id, ticketType: $ticketType)
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  if (!ok) return null

  onLoad({ setState, app, session, ticketId })
  handleClose()
}

export async function cancelRefund({
  setState,
  app,
  session,
  ticketId,
  refundId,
  handleClose,
}) {
  const variables = { id: refundId, ticketType: 'REFUND' }
  const query = `
    mutation($id: ID!, $ticketType: String!) {
      cancelTicket(id: $id, ticketType: $ticketType)
    }
  `
  const [ok] = await request({ query, variables }, { app, session })
  if (!ok) return null

  onLoad({ setState, app, session, ticketId })
  handleClose()
}

export const getRefundBalance = (item, refundOrders) => {
  const refundItems = refundOrders.reduce((result, item) => {
    const { ticketItems, status } = item
    if (status === 'INACTIVE') return result

    ticketItems.forEach((item) => {
      const quantity = result[item.productVariantId] || 0
      result[item.productVariantId] = quantity + item.quantity
    })
    return result
  }, {})

  item.ticketItems.forEach((item) => {
    let refundQuantity = refundItems[item.productVariantId] || 0
    if (refundQuantity > item.quantity) {
      refundQuantity = item.quantity
    }
    item.quantity -= refundQuantity
  })
  return item
}

export const disableCancel = (ticket) => {
  const { amountAcquired, extra, status } = ticket
  const doneStates = [
    'INACTIVE',
    'ACTIVE',
    'REFUND_PENDING',
    'FULL_REFUND',
    'PARTIAL_REFUND',
  ]
  if (doneStates.includes(status)) return true
  if (amountAcquired > 0) return true

  const { paymentMethod } = extra
  if (paymentMethod === 'CASH' && status === 'PAY_PENDING') {
    return true
  }

  return false
}

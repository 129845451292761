import React from 'react'
import { Box } from 'rebass'
import Message from 'components/Message'

export default ({ id, label, errMsg, children, ...props }) => {
  if (errMsg && typeof errMsg === 'string') {
    errMsg = { id: errMsg }
  }

  if (errMsg && errMsg.constructor === Array) {
    errMsg = { id: errMsg[0], values: errMsg[1] }
  }

  return (
    <Box mb={3} {...props}>
      {label && <Message lineHeight={1} htmlFor={id} mb={2} id={label} />}
      {children}
      {errMsg && (
        <Message variant="error" id={errMsg.id} values={errMsg.values} />
      )}
    </Box>
  )
}

import React from 'react'
import {
  MdExitToApp,
  MdAttachMoney,
  MdCardGiftcard,
  MdStorage,
  MdPeople,
} from 'react-icons/md'
import { IoMdGlobe } from 'react-icons/io'
import { GiTicket } from 'react-icons/gi'
import { MdDashboard, MdShare, MdHelp, MdSecurity } from 'react-icons/md'

export const appLinks = [
  {
    id: 'password',
    label: 'app.password',
    icon: <MdSecurity />,
    path: '/password',
  },
  {
    id: 'logout',
    label: 'app.logout',
    icon: <MdExitToApp />,
    path: '/logout',
  },
]

export const pageLinks = [
  {
    id: 'dashboard',
    label: 'dashboard.title',
    icon: <MdDashboard />,
    path: '/dashboard',
  },
  {
    id: 'orderList',
    label: 'order.title.list',
    icon: <MdAttachMoney />,
    path: '/order',
  },
  {
    id: 'supportList',
    label: 'support.title.list',
    icon: <MdHelp />,
    path: '/support',
  },
  {
    id: 'product',
    label: 'product.title',
    icon: <MdCardGiftcard />,
    items: [
      {
        id: 'categoryList',
        label: 'category.title.list',
        path: '/category',
      },
      {
        id: 'productList',
        label: 'product.title.list',
        path: '/product',
      },
      {
        id: 'productShelfList',
        label: 'productShelf.title.list',
        path: '/product/shelf',
      },
      {
        id: 'purchaseList',
        label: 'purchase.title.list',
        path: '/purchase',
      },
      {
        id: 'adjustList',
        label: 'adjust.title.list',
        path: '/adjust',
      },
    ],
  },
  {
    id: 'inventoryList',
    label: 'inventory.title.list',
    icon: <MdStorage />,
    path: '/inventory',
  },
  {
    id: 'backorderList',
    label: 'backorder.title.list',
    icon: <GiTicket />,
    path: '/backorder',
  },
  {
    id: 'customerList',
    label: 'customer.title.list',
    icon: <MdPeople />,
    path: '/customer',
  },
  {
    id: 'discountList',
    label: 'discount.title.list',
    icon: <MdShare />,
    path: '/discount',
  },
  {
    id: 'estore',
    label: 'estore.title',
    icon: <IoMdGlobe />,
    items: [
      {
        id: 'estore',
        label: 'estore.title.edit',
        path: '/estore',
      },
      {
        id: 'paymentConfigList',
        label: 'paymentConfig.title.list',
        path: '/payment/config',
      },
      {
        id: 'adList',
        label: 'ad.title.list',
        path: '/ad',
      },
      {
        id: 'keywordList',
        label: 'keyword.title.list',
        path: '/keyword',
      },
      {
        id: 'adBlockList',
        label: 'adBlock.title.list',
        path: '/ad/block',
      },
      {
        id: 'categoryAdList',
        label: 'categoryAd.title.list',
        path: '/category/ad',
      },
      {
        id: 'productAd',
        label: 'productAd.title.list',
        path: '/product/ad',
      },
    ],
  },
  // {
  //   id: 'setting',
  //   label: 'setting.title',
  //   icon: <IoIosSettings />,
  //   items: [
  //     {
  //       id: 'staffList',
  //       label: 'staff.title.list',
  //       path: '/staff'
  //     },
  //     {
  //       id: 'permissionList',
  //       label: 'permission.title.list',
  //       path: '/permission'
  //     }
  //   ]
  // }
]

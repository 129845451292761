import React, { useState, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Image, Button } from 'rebass'
import { useDropzone } from 'react-dropzone'
import TextInput from 'components/TextInput'
import ImageThumb from 'components/ImageThumb'
import Modal from 'components/Modal'
import Divider from 'components/Divider'
import DraggableBox from 'components/DraggableBox'
import { THUMB } from 'constants/draggableType'

const url = process.env.REACT_APP_STATIC_URL

async function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onabort = () => reject('file reading was aborted')
    reader.onerror = () => reject('file reading has failed')
    reader.onload = () => {
      file.preview = reader.result
      file.alt = ''
      resolve(file)
    }
  })
}

export default ({
  value,
  thumb,
  onUpload,
  onDelete,
  onError,
  onDrag,
  maxSize = 1024000
}) => {
  const [images, setImages] = useState(value)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)
  const [altOpen, setAltOpen] = useState(false)
  const [altIndex, setAltIndex] = useState(-1)
  const [altValue, setAltValue] = useState('')

  useEffect(() => {
    setImages(value)
  }, [value])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async acceptedFiles => {
      const files = await Promise.all(acceptedFiles.map(file => readFile(file)))
      const uploadedImages = await onUpload(files)
      setImages([...images, ...uploadedImages])
    },
    onDropRejected: files => {
      if (onError) {
        onError(
          files.map(item => {
            if (item.size > maxSize) {
              return {
                id: 'error.imageDropzone.tooLarge',
                values: {
                  filename: item.name,
                  maxSize: Math.round(maxSize / 1000000)
                }
              }
            }

            return { message: 'error.imageDropzone.generic' }
          })
        )
      }
    },
    accept: 'image/*',
    maxSize,
    noDrag: true
  })
  const onImageDrag = useCallback(
    (dragIndex, hoverIndex) => {
      const newImages = [...images]
      newImages.splice(dragIndex, 1)
      newImages.splice(hoverIndex, 0, images[dragIndex])
      setImages(newImages)
      onDrag(newImages)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images]
  )

  const renderItem = () =>
    images.map((item, index) => {
      if (thumb) return thumb(item, index)

      const src = item.preview ? item.preview : url + item.src
      return (
        <DraggableBox
          key={index}
          type={THUMB}
          index={index}
          previewImage={src}
          onDrag={onImageDrag}
        >
          <ImageThumb
            src={src}
            alt={item.alt}
            onView={() => {
              setPreviewOpen(true)
              setPreviewImage({ src, alt: item.alt })
            }}
            onEdit={() => {
              setAltIndex(index)
              setAltValue(item.alt || '')
              setAltOpen(true)
            }}
            onDelete={async () => {
              const ok = await onDelete(item.src, index)
              if (ok) {
                const newImages = [...images]
                newImages.splice(index, 1)
                setImages(newImages)
              }
            }}
          />
        </DraggableBox>
      )
    })

  const renderEmpty = () => (
    <Flex justifyContent="center" alignItems="center" width={1} color="grey.4">
      Click to select files
    </Flex>
  )
  return (
    <>
      <Flex
        flexWrap="wrap"
        alignContent="center"
        variant="dropzone"
        sx={{ borderColor: isDragActive ? 'accent.1' : 'grey.3' }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {images.length > 0 ? renderItem() : renderEmpty()}
      </Flex>
      <Modal
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        contentProps={{ textAlign: 'center' }}
      >
        <Image {...previewImage} maxHeight="calc(100vh - 128px)" />
        <Divider bg="grey.3" />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            type="button"
            variant="outlineSecondary"
            onClick={() => setPreviewOpen(false)}
          >
            <FormattedMessage id="btn.close" />
          </Button>
        </Flex>
      </Modal>
      <Modal
        title="product.image.title.alt"
        open={altOpen}
        onClose={() => setAltOpen(false)}
        width="300px"
      >
        <TextInput
          id="name"
          label="product.image.field.alt"
          placeholder="product.image.field.alt"
          value={altValue}
          onChange={event => setAltValue(event.target.value)}
        />
        <Flex justifyContent="flex-end" alignItems="center" mt={3}>
          <Button
            type="button"
            variant="outlineSecondary"
            onClick={() => setAltOpen(false)}
            mr={2}
          >
            <FormattedMessage id="btn.close" />
          </Button>
          <Button
            type="button"
            variant="outlineAccent"
            onClick={() => {
              // onChange(altValue, altIndex)
              const newImages = [...images]
              const newImage = { ...images[altIndex], alt: altValue }
              newImages.splice(altIndex, 1, newImage)
              setImages(newImages)
              setAltOpen(false)
            }}
          >
            <FormattedMessage id="btn.submit" />
          </Button>
        </Flex>
      </Modal>
    </>
  )
}

import React, { useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'rebass'
import Page from 'components/PageContainer'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import StoreModal from 'views/StoreModal'
import * as model from 'models/storeList'

export default () => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const ref = useRef(null)
  const handleOpen = item => {
    setValue(item)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setValue({})
  }
  const handleRefresh = () => {
    ref.current.refresh()
  }

  return (
    <Page
      title="store.title.list"
      navId="storeList"
      actions={
        <Button
          type="button"
          variant="outlineSecondary"
          onClick={() => setOpen(true)}
        >
          <FormattedMessage id="store.title.add" />
        </Button>
      }
    >
      <Section>
        <DataTable
          ref={ref}
          actions={{ handleOpen, handleRefresh }}
          {...model}
        />
      </Section>
      <StoreModal
        open={open}
        value={value}
        actions={{ handleClose, handleRefresh }}
      />
    </Page>
  )
}

import React from 'react'
import TextInput from 'components/TextInput'
import Select from 'components/Select'
import Message from 'components/Message'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { formatDate } from 'utilities/dateUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    transDate: value.transDate || formatDate(new Date()),
    channelType: value.channelType || '',
    channelProvider: value.channelProvider || '',
    transAmount: value.transAmount || 0
  })

const validation = {
  transDate: [{ type: 'required', message: 'error.required' }],
  channelType: [{ type: 'required', message: 'error.required' }],
  channelProvider: [{ type: 'required', message: 'error.required' }],
  transAmount: [{ type: 'required', message: 'error.required' }]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    transDate: (
      <TextInput
        id="transDate"
        type="date"
        label="payment.field.transDate"
        value={state.transDate}
        onChange={onTextChange('transDate')}
        errMsg={state.__error__.transDate}
      />
    ),
    channelType: (
      <Select
        id="channelType"
        isSearchable={false}
        label="payment.field.channelType"
        placeholder="payment.field.channelType"
        options={[
          {
            value: 'CASH',
            label: <Message id="payment.channelType.cash" />
          },
          {
            value: 'CREDIT_CARD',
            label: <Message id="payment.channelType.creditCard" />
          }
        ]}
        value={state.channelType}
        onChange={handleSelectChange(
          'channelType',
          state,
          setState,
          validation
        )}
      />
    ),
    channelProvider: (
      <Select
        id="channelProvider"
        isSearchable={false}
        label="payment.field.channelProvider"
        placeholder="payment.field.channelProvider"
        options={[
          {
            value: 'SELF',
            label: <Message id="payment.channelProvider.self" />
          },
          {
            value: 'ECPAY',
            label: <Message id="payment.channelProvider.ecpay" />
          },
          {
            value: 'LINEPAY',
            label: <Message id="payment.channelProvider.linepay" />
          }
        ]}
        value={state.channelProvider}
        onChange={handleSelectChange(
          'channelProvider',
          state,
          setState,
          validation
        )}
      />
    ),
    transAmount: (
      <TextInput
        id="transAmount"
        type="number"
        min="1"
        label="payment.field.transAmount"
        value={state.transAmount}
        onChange={onTextChange('transAmount')}
        errMsg={state.__error__.transAmount}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = await addPayment(state, app, session)
    if (!ok) return

    actions.handleRefresh()
    actions.handlePaymentClose()
  }
})

async function addPayment(state, app, session) {
  const variables = {
    input: {
      channelType: state.channelType.value,
      channelProvider: state.channelProvider.value,
      ticketId: state.id,
      transAmount: parseFloat(state.transAmount),
      transDate: state.transDate
    }
  }
  const query = `
    mutation($input: PaymentInput!) {
      addPayment(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React from 'react'
import { Flex } from 'rebass'
import EditorMarkButton from 'components/EditorMarkButton'
import EditorBlockButton from 'components/EditorBlockButton'
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdCode,
  MdLooksOne,
  MdLooksTwo,
  MdFormatQuote,
  MdFormatListNumbered,
  MdFormatListBulleted
} from 'react-icons/md'

const Toolbar = props => <Flex {...props} />

export default () => {
  return (
    <Toolbar>
      <EditorMarkButton format="bold" icon={<MdFormatBold />} />
      <EditorMarkButton format="italic" icon={<MdFormatItalic />} />
      <EditorMarkButton format="underlined" icon={<MdFormatUnderlined />} />
      <EditorMarkButton format="code" icon={<MdCode />} />
      <EditorBlockButton format="heading-one" icon={<MdLooksOne />} />
      <EditorBlockButton format="heading-two" icon={<MdLooksTwo />} />
      <EditorBlockButton format="block-quote" icon={<MdFormatQuote />} />
      <EditorBlockButton
        format="numbered-list"
        icon={<MdFormatListNumbered />}
      />
      <EditorBlockButton
        format="bulleted-list"
        icon={<MdFormatListBulleted />}
      />
    </Toolbar>
  )
}

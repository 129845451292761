import React from 'react'
import ConfirmButton from 'components/ConfirmButton'
import TextInput from 'components/TextInput'
import { request } from 'utilities/requestUtil'
import { MdDelete } from 'react-icons/md'

export const columns = ({ session, app, actions }) => [
  {
    id: 'productName',
    label: 'productShelf.field.productId'
  },
  {
    id: 'spu',
    label: 'productShelf.field.spu'
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <ConfirmButton
        title="productShelf.title.delete"
        message="productShelf.message.delete"
        onClick={handleDelete({
          session,
          app,
          productId: row.id,
          actions
        })}
      >
        <MdDelete />
      </ConfirmButton>
    )
  }
]

export const filters = ({
  message,
  filterOp,
  setFilterOp,
  filterInput,
  setFilterInput
}) => [
  {
    id: 'spu',
    label: 'product.field.spu',
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 3], width: 1 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    )
  },
  {
    id: 'categoryName',
    label: 'product.field.category',
    input: (
      <TextInput
        containerProps={{ mb: [2, 0], flex: [1, 3], width: 1 }}
        value={filterInput}
        onChange={({ target }) => setFilterInput(target.value)}
      />
    )
  }
]

export const onLoad = async ({
  session,
  app,
  setProducts,
  setItems,
  setPagination,
  page,
  filter = []
}) => {
  const { merchantId } = app.state.staff
  const countPerPage = 25
  const input = { page, countPerPage }

  filter.forEach(item => {
    input[item.id] = item.value
  })
  
  const variables = { merchantId, input }
  const query = `
    query($merchantId: ID!, $input: ProductQueryInput) {
      products(merchantId: $merchantId) {
        id
        spu
      }
      pagedProductShelves(merchantId: $merchantId, input: $input) {
        data {
          id
          categoryName
          productName
          spu
        }
        pagination {
          totalCount
          totalPage
          page
          pages
          prevPage
          nextPage
          countPerPage
        }
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return

  setProducts(data.products)
  setItems(data.pagedProductShelves.data)
  setPagination(data.pagedProductShelves.pagination)
}

const handleDelete = ({
  session,
  app,
  productId,
  actions
}) => async handleClose => {
  const variables = { productId }
  const query = `
      mutation ($productId: ID!) {
        deleteProductShelf(productId: $productId)
      }
    `
  const [ok] = await request({ query, variables }, { session, app })
  if (!ok) return

  handleClose()
  actions.handleDelete(productId)
}

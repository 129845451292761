import React, { useState, useEffect, useContext } from 'react'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import Link from 'components/Link'
import Table from 'components/Table'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import { handleLoad } from 'models/supportList'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState([])

  useEffect(() => {
    handleLoad({ setState, app, session })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="support.title.list">
      <Section>
        <Table
          width={1}
          bg="grey.0"
          columns={[
            {
              id: 'subject',
              label: 'support.field.subject',
              render: ({ row }) => (
                <Link color="secondary.1" url={`/support/${row.id}`}>
                  {row.subject}
                </Link>
              )
            },
            {
              id: 'type',
              label: 'support.field.type',
              render: ({ row }) => <Message id={`support.type.${row.type}`} />
            },
            {
              id: 'status',
              label: 'support.field.status',
              render: ({ row }) => (
                <Message id={`support.status.${row.status}`} />
              )
            }
          ]}
          rows={state}
        />
      </Section>
    </Page>
  )
}

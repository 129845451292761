import React from 'react'
import { withRouter } from 'react-router'
import { Button } from 'rebass'

const Link = ({ history, url, children, ...props }) => (
  <Button
    type="button"
    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    onClick={() => history.push(url || '/')}
    {...props}
  >
    {children}
  </Button>
)

export default withRouter(Link)

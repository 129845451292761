import React, { useState, useEffect, useContext } from 'react'
import { Flex, Box, Button } from 'rebass'
import { useIntl } from 'react-intl'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import LinkButton from 'components/LinkButton'
import Message from 'components/Message'
import Section from 'components/Section'
import Page from 'components/PageContainer'
import DiscountGenerateModal from 'views/DiscountGenerateModal'
import DiscountAddModal from 'views/DiscountAddModal'
import { initialState, fields, handlers } from 'models/discountPage'
import { MdArrowBack, MdDelete } from 'react-icons/md'
import Table from 'components/Table'
import Icon from 'components/Icon'

export default ({ match }) => {
  const { id } = match.params
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState({}, message))
  const [openGenerate, setOpenGenerate] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)

  const form = fields({ state, setState, message })
  const handler = handlers({ app, session, state, setState, message, id })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Page
      title="discount.title.add"
      navId="discountList"
      actions={
        <>
          <LinkButton variant="plain" url="/discount">
            <MdArrowBack />
            <Message ml={1} id="btn.back" />
          </LinkButton>
          <Button type="submit" variant="outlineAccent">
            <Message id="btn.save" />
          </Button>
        </>
      }
      as="form"
      onSubmit={handler.handleSubmit}
    >
      <Box
        mt={2}
        mb={4}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          gridGap: 3,
        }}
      >
        <Section title="discount.section.basic">
          {form.name}
          {form.startDate}
          {form.endDate}
          {form.allowCombination}
        </Section>
        <Section title="discount.section.discount">
          {form.discountType}
          {form.discountValue}
          {form.product}
          {form.discountScope}
          {form.minAmount}
          {form.minQuantity}
          {form.maxUserQuantity}
          {form.maxQuantityPerUser}
        </Section>
      </Box>
      <Section title="discount.section.code">
        <Table
          columns={[
            {
              id: 'seq',
              label: 'discount.field.seq',
              render: ({ index }) => {
                const { page = 1 } = state.pagination || {}
                const seq = (page - 1) * 50
                return seq + index + 1
              },
            },
            {
              id: 'code',
              label: 'discount.field.code',
            },
            {
              id: 'quantity',
              label: 'discount.field.quantity',
            },
            {
              id: 'actions',
              render: ({ row }) => {
                const { code } = row
                return (
                  <Box sx={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                    <Button
                      type="button"
                      variant="table"
                      onClick={() =>
                        handler.deleteDiscount({ state, setState, code })
                      }
                    >
                      <Icon>
                        <MdDelete />
                      </Icon>
                    </Button>
                  </Box>
                )
              },
            },
          ]}
          rows={state.codes}
          pagination={state.pagination}
          onChange={handler.handleLoad}
        />
        <Flex justifyContent="center" alignItems="center" mt={3}>
          <Button
            mr={3}
            type="button"
            variant="outlineSecondary"
            onClick={() => setOpenGenerate(true)}
          >
            <Message id="discount.btn.generateCode" />
          </Button>
          <Button
            type="button"
            variant="outlineSecondary"
            onClick={() => setOpenAdd(true)}
          >
            <Message id="discount.btn.addCode" />
          </Button>
        </Flex>
      </Section>
      <DiscountGenerateModal
        open={openGenerate}
        actions={{
          onOk: handler.handleAddCodes,
          onClose: () => setOpenGenerate(false),
        }}
      />
      <DiscountAddModal
        open={openAdd}
        actions={{
          onOk: handler.handleAddCodes,
          onClose: () => setOpenAdd(false),
        }}
      />
    </Page>
  )
}

import React from 'react'
import { Box } from 'rebass'

export default ({ children, sx, ...props }) => (
  <Box
    as="ul"
    m={0}
    p={0}
    sx={{ listStyle: 'none', overflow: 'auto', ...sx }}
    {...props}
  >
    {children}
  </Box>
)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import Form from 'components/Form'
import * as model from 'models/staffModal'

export default ({ value, open, actions }) => {
  const { id } = value

  return (
    <Modal
      title={`staff.title.${id ? 'edit' : 'add'}`}
      open={open}
      onClose={actions.handleClose}
      width={300}
    >
      <Form {...model} value={value} actions={actions}>
        {({ form, action }) => (
          <>
            {form.type}
            {form.username}
            {form.password}
            <Flex justifyContent="flex-end" alignItems="center" mt={3}>
              <Button
                mr={2}
                type="button"
                variant="outlineSecondary"
                onClick={actions.handleClose}
              >
                <FormattedMessage id="btn.close" />
              </Button>
              <Button variant="outlineAccent">
                <FormattedMessage id="btn.submit" />
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </Modal>
  )
}

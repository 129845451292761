import React, { useState, useEffect, useRef } from 'react'
import { Box } from 'rebass'

export default ({ open, children }) => {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setHeight(open ? ref.current.scrollHeight : 0)
  }, [open])

  return (
    <Box
      ref={ref}
      sx={{
        maxHeight: `${height}px`,
        overflow: 'hidden',
        transition: 'max-height 0.2s ease-out'
      }}
    >
      {children}
    </Box>
  )
}

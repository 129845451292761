import React from 'react'
import { useSlate } from 'slate-react'
import { Button } from 'rebass'
import { toggleMark, isMarkActive } from 'utilities/slateUtil'
import Icon from 'components/Icon'

export default ({ format, icon, ...props }) => {
  const editor = useSlate()
  const onClickMark = event => {
    event.preventDefault()
    toggleMark(editor, format)
  }

  return (
    <Button
      tabIndex={-1}
      type="button"
      variant={isMarkActive(editor, format) ? 'editorActive' : 'editor'}
      onMouseDown={onClickMark}
      {...props}
    >
      <Icon fontSize={3}>{icon}</Icon>
    </Button>
  )
}

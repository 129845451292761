import React from 'react'
import TextInput from 'components/TextInput'
import {
  initializeState,
  handleTextChange,
  validateForm
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'

export const initialState = (value = {}) =>
  initializeState({
    id: value.id || '',
    parentCategoryId: value.parentCategoryId || 0,
    name: value.name || ''
  })

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] }
  ]
}

export const fields = ({ app, session, state, setState }) => {
  const onTextChange = id => handleTextChange(id, state, setState, validation)
  return {
    name: (
      <TextInput
        id="name"
        label="category.field.name"
        placeholder="category.field.name"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    )
  }
}

export const handlers = ({ state, setState, session, app, actions }) => ({
  handleSubmit: async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const ok = state.id
      ? await editCategory(state, app, session)
      : await addCategory(state, app, session)

    if (!ok) return

    actions.handleRefresh()
    actions.handleClose()
  }
})

async function addCategory(value, app, session) {
  const { name, parentCategoryId } = value
  const variables = { input: { name, parentCategoryId } }
  const query = `
    mutation ($input: CategoryInput!) {
      addCategory(input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

async function editCategory(value, app, session) {
  const { id, name, parentCategoryId } = value
  const variables = { id, input: { name, parentCategoryId } }
  const query = `
    mutation ($id: ID!, $input: CategoryInput!) {
      editCategory(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

import React, { useState, useRef, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Flex } from 'rebass'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Message from 'components/Message'
import Link from 'components/Link'
import Popover from 'components/Popover'
import Section from 'components/Section'
import DataTable from 'components/DataTable'
import Page from 'components/PageContainer'
import * as model from 'models/orderList'
import { createExcel } from 'utilities/excelUtil'
import { MdFileDownload } from 'react-icons/md'
import { FaTruck } from 'react-icons/fa'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [hotFilter, setHotFilter] = useState('ALL')
  const { formatMessage: message } = useIntl()
  const ref = useRef(null)
  const handleRefresh = (filter) => {
    ref.current.refresh({ hotFilter: filter })
  }

  const handleExport = () => {
    const title = 'order.title.list'
    const columns = model.exportColumns({ message })
    const setExcel = (rows) => createExcel({ message, title, columns, rows })
    model.onExport({ session, app, setExcel, filter: ref.current.getFilter() })
  }

  const searchHotFilter = (value) => {
    setHotFilter(value)
    // const filter = [{ id: 'status', value: { value } }]
    // handleRefresh(value === 'ALL' ? [] : filter)
    handleRefresh(value)
  }

  const renderHotFilter = () => (
    <Flex px={2} mb={3}>
      <Link
        pr={3}
        color={hotFilter === 'ALL' ? 'primary.1' : 'grey.5'}
        sx={{
          borderRightColor: 'grey.3',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
        }}
        onClick={() => searchHotFilter('ALL')}
      >
        <Message id="order.status.ALL" />
      </Link>
      <Link
        px={3}
        color={hotFilter === 'SHIP_PENDING' ? 'primary.1' : 'grey.5'}
        sx={{
          borderRightColor: 'grey.3',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
        }}
        onClick={() => searchHotFilter('SHIP_PENDING')}
      >
        <Message id="order.status.SHIP_PENDING" />
      </Link>
      <Link
        px={3}
        color={hotFilter === 'PAY_PENDING' ? 'primary.1' : 'grey.5'}
        sx={{
          borderRightColor: 'grey.3',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
        }}
        onClick={() => searchHotFilter('PAY_PENDING')}
      >
        <Message id="order.status.PAY_PENDING" />
      </Link>
      <Link
        px={3}
        color={hotFilter === 'REFUND_PENDING' ? 'primary.1' : 'grey.5'}
        sx={{
          borderRightColor: 'grey.3',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
        }}
        onClick={() => searchHotFilter('REFUND_PENDING')}
      >
        <Message id="order.status.REFUND_PENDING" />
      </Link>
      <Link
        pl={3}
        color={hotFilter === 'ACTIVE' ? 'primary.1' : 'grey.5'}
        onClick={() => searchHotFilter('ACTIVE')}
      >
        <Message id="order.status.ACTIVE" />
      </Link>
    </Flex>
  )

  return (
    <Page
      title="order.title.list"
      navId="orderList"
      actions={
        <Popover>
          <Link variant="inverseLink" url="/trackingNo">
            <FaTruck size="24px" />
            <Message ml={2} id="order.btn.importTrackingNo" />
          </Link>
          <Link variant="inverseLink" onClick={handleExport}>
            <MdFileDownload size="24px" />
            <Message ml={2} id="btn.export" />
          </Link>
        </Popover>
      }
    >
      <Section>
        {renderHotFilter()}
        <DataTable
          title="order.title.list"
          ref={ref}
          values={{ hotFilter }}
          {...model}
        />
      </Section>
    </Page>
  )
}

import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Box, Flex, Text, Button } from 'rebass'
import { FaInbox } from 'react-icons/fa'
import Pagination from 'components/Pagination'
import Select from 'components/Select'
import Chip from 'components/Chip'
import { MdSearch } from 'react-icons/md'

const Table = ({ children, ...props }) => (
  <Box
    as="table"
    width={1}
    sx={{ overflowX: 'auto', borderCollapse: 'collapse' }}
    {...props}
  >
    {children}
  </Box>
)

const Row = ({ index, children, ...props }) => (
  <Box as="tr" bg={index % 2 === 0 ? 'grey.1' : 'grey.0'} {...props}>
    {children}
  </Box>
)

const EmptyRow = ({ columnCount }) => (
  <Box as="tr" bg="grey.1">
    <Cell
      colSpan={columnCount}
      color="grey.3"
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'grey.3',
        textAlign: 'center'
      }}
    >
      <FaInbox fontSize={48} />
      <Text>No Data</Text>
    </Cell>
  </Box>
)

const HeadCell = ({ column }) => {
  const { label, align, renderHead } = column
  return (
    <Box
      as="th"
      py={3}
      px={2}
      sx={{
        borderBottom: 'none',
        textAlign: align || 'left',
        fontWeight: 300,
        whiteSpace: 'nowrap'
      }}
    >
      {renderHead ? renderHead() : label && <FormattedMessage id={label} />}
    </Box>
  )
}

const Cell = ({ align, width, children, ...props }) => (
  <Box
    as="td"
    py={3}
    px={2}
    width={width || 'auto'}
    color="grey.5"
    sx={{
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'grey.3',
      textAlign: align || 'left'
    }}
    {...props}
  >
    {children}
  </Box>
)

const getDefaultFilter = (filters, message) => {
  if (filters.length === 0) return {}

  const defaultFilter = filters[0]
  return {
    value: defaultFilter.id,
    label: message({ id: defaultFilter.label })
  }
}

export default ({
  columns,
  rows,
  footer,
  pagination,
  countPerPage,
  rowProps,
  cellProps,
  onChange,
  ...props
}) => {
  const { formatMessage: message } = useIntl()
  const [filterOp, setFilterOp] = useState('')
  const [filterInput, setFilterInput] = useState('')
  const filters = props.filters
    ? props.filters({
        message,
        filterOp,
        setFilterOp,
        filterInput,
        setFilterInput
      })
    : []
  const [filterSelect, setFilterSelect] = useState(
    getDefaultFilter(filters, message)
  )
  const [filter, setFilter] = useState([])

  const renderCell = (column, row, index) => {
    if (column.render) {
      return column.render({ row, index })
    }
    return <Text>{row[column.id]}</Text>
  }

  const renderFilterSelector = () => (
    <Select
      containerProps={{ mb: [2, 0], mr: [0, 3], flex: 1, width: 1 }}
      isSearchable={false}
      isClearable={false}
      options={filters.map(item => ({
        value: item.id,
        label: message({ id: item.label })
      }))}
      value={filterSelect}
      onChange={value => {
        setFilterOp('')
        setFilterInput('')
        setFilterSelect(value)
      }}
    />
  )

  const renderFilterInput = () =>
    filters.find(item => item.id === filterSelect.value).input

  const renderFilterOp = () =>
    filters.find(item => item.id === filterSelect.value).op

  const renderFilterButton = () => (
    <Button
      variant="plain"
      onClick={() => {
        if (!filterInput) return

        const hasFilter = filter.some(item => isFilterEqual(item))
        if (hasFilter) return

        const newFilter = [
          ...filter,
          {
            id: filterSelect.value,
            op: filterOp.value,
            value: filterInput
          }
        ]
        setFilter(newFilter)
        setFilterOp('')
        setFilterInput('')

        onChange({ filter: newFilter })
      }}
    >
      <MdSearch size="24px" />
    </Button>
  )

  const isFilterEqual = value => {
    const filter = filters.find(item => item.id === value.id)
    const filterVal = value.value
    return (
      value.id === filterSelect.value &&
      (!value.op || value.op === filterOp) &&
      (filter.getLabel ? filter.getLabel(filterVal) : filterVal) === filterInput
    )
  }

  const getFilterLabel = value => {
    const filter = filters.find(item => item.id === value.id)
    if (filter.getLabel) {
      return filter.getLabel(value)
    }

    return (
      <Flex alignItems="center" sx={{ lineHeight: 0 }}>
        {message({ id: filter.label })}
        <Text px={1}>{message({ id: 'filter.op.eq' })}</Text>
        {value.value}
      </Flex>
    )
  }

  return (
    <>
      {filters && filters.length > 0 && (
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          px={2}
          width={1}
          bg="grey.0"
        >
          {renderFilterSelector()}
          <Flex flex={[1, 3]} width={1} alignItems="center">
            {renderFilterOp()}
            {renderFilterInput()}
          </Flex>
          {renderFilterButton()}
        </Flex>
      )}
      {filter.length > 0 && (
        <Flex alignItems="center" p={2} width={1} bg="grey.0">
          {filter.map((item, index) => (
            <Chip
              mr={2}
              key={index}
              text={getFilterLabel(item)}
              onDelete={() => {
                const newFilter = [...filter]
                newFilter.splice(index, 1)
                setFilter(newFilter)
                onChange({ filter: newFilter })
              }}
            />
          ))}
        </Flex>
      )}
      <Box sx={{ overflowX: 'auto' }}>
        <Table {...props}>
          <thead>
            <Row>
              {columns.map(column => (
                <HeadCell key={column.id} column={column} />
              ))}
            </Row>
          </thead>
          <tbody>
            {rows && rows.length > 0 ? (
              rows.map((row, index) => (
                <Row key={index} index={index} {...rowProps}>
                  {columns.map(column => (
                    <Cell
                      key={column.id}
                      align={column.align}
                      width={column.width}
                      {...cellProps}
                    >
                      {renderCell(column, row, index)}
                    </Cell>
                  ))}
                </Row>
              ))
            ) : (
              <EmptyRow columnCount={columns.length} />
            )}
          </tbody>
          {footer && <tfoot>{footer}</tfoot>}
        </Table>
      </Box>
      {pagination && pagination.totalPage > 1 && (
        <Box
          pt={3}
          px={2}
          width={1}
          bg="grey.0"
          sx={{
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: 'grey.3'
          }}
        >
          <Pagination value={pagination} onClick={page => onChange({ page })} />
        </Box>
      )}
    </>
  )
}

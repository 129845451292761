import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Flex, Button } from 'rebass'
import Message from 'components/Message'
import Modal from 'components/Modal'
import Form from 'components/Form'
import * as model from 'models/paymentConfigModal'

export default ({ value, open, actions }) => {
  if (!value.channel || value.channel === 'CASH') return null

  return (
    <Modal
      title="paymentConfig.title.add"
      open={open}
      onClose={actions.handleClose}
      width={300}
    >
      <Form {...model} value={value} actions={actions}>
        {({ form, action }) => (
          <>
            {/* {form.channel} */}
            <Message id="paymentConfig.field.channel" />
            <Message mb={3} id={`paymentConfig.channel.${value.channel}`} />
            {form.merchantId}
            {form.key}
            {form.secret}
            <Flex justifyContent="flex-end" alignItems="center" mt={3}>
              <Button
                mr={2}
                type="button"
                variant="outlineSecondary"
                onClick={actions.handleClose}
              >
                <FormattedMessage id="btn.cancel" />
              </Button>
              <Button variant="outlineAccent">
                <FormattedMessage id="btn.submit" />
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </Modal>
  )
}

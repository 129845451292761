import XLSX from 'xlsx'

export const createExcel = ({ message, title, columns, rows }) => {
  title = title ? message({ id: title }) : 'report'
  const data = []

  const header = []
  for (const col of columns) {
    if (!col.label) continue
    header.push(message({ id: col.label }))
  }
  data.push(header)

  for (const row of rows) {
    const rowVals = []

    columns.forEach((col, index) => {
      if (col.label) {
        const val = row[col.id]
        rowVals.push(col.renderText ? col.renderText({ row, index }) : val)
      }
    })

    data.push(rowVals)
  }

  const ws = XLSX.utils.aoa_to_sheet(data)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, title)
  XLSX.writeFile(wb, `${title}.xlsx`)
}

export const readExcel = (file) => {
  const wb = XLSX.read(file, { type: 'binary' })
  let ws = wb.Sheets[wb.SheetNames[0]]
  return XLSX.utils.sheet_to_json(ws)
}

export const translateHeader = (ws, headerMapping) => {
  if (!headerMapping) return ws

  const range = XLSX.utils.decode_range(ws['!ref'])
  for (let c = range.s.c; c <= range.e.c; ++c) {
    const cellref = XLSX.utils.encode_cell({ c, r: range.s.r })
    if (!ws[cellref]) continue

    const cell = ws[cellref]
    cell.v = headerMapping[cell.v]
  }
}

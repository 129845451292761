import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Button } from 'rebass'
import Modal from 'components/Modal'
import { initialState, fields, handlers } from 'models/productVariantModal'

export default ({ options, value, open, actions }) => {
  const [state, setState] = useState(initialState(options, value))
  const action = handlers({ state, setState, actions })
  const form = fields({ state, setState })

  useEffect(() => {
    setState(initialState(options, value))
  }, [options, value])

  return (
    <Modal
      title="product.variant.title.add"
      open={open}
      onClose={actions.handleClose}
      width={['100%', 600]}
      contentProps={{ titleProps: { py: 3, flex: 1 } }}
    >
      {form.options}
      {state.options.length < 3 && (
        <Flex mt={3} justifyContent="flex-end">
          <Button
            type="button"
            variant="outlineSecondary"
            onClick={action.addOption}
          >
            <FormattedMessage id="product.variant.option.btn.add" />
          </Button>
        </Flex>
      )}
      <Box
        mt={4}
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          gridGap: 3
        }}
      >
        {form.sku}
        {form.barcode}
        {form.postedPrice}
        {form.price}
      </Box>
      <Flex justifyContent="flex-end" alignItems="center" mt={3}>
        <Button
          mr={2}
          type="button"
          variant="outlineSecondary"
          onClick={actions.handleClose}
        >
          <FormattedMessage id="btn.close" />
        </Button>
        <Button
          type="button"
          variant="outlineAccent"
          onClick={action.handleConfirm}
        >
          <FormattedMessage id="btn.confirm" />
        </Button>
      </Flex>
    </Modal>
  )
}

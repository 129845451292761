import * as type from 'constants/actionType'

export const initialState = {
  alerts: [],
  loading: false,
  pageResult: {},
}

export function reducer(state, action) {
  switch (action.type) {
    case type.ALERT_ADD:
      return { ...state, alerts: addItem(state.alerts, action.item) }
    case type.ALERT_REMOVE:
      return { ...state, alerts: removeItem(state.alerts, action.idx) }
    case type.LOADING_SHOW:
      return { ...state, loading: true }
    case type.LOADING_HIDE:
      return { ...state, loading: false }
    case type.SET_PAGE_RESULT:
      return { ...state, pageResult: action.data }
    default:
      return state
  }
}

function addItem(list, data) {
  return [...list, data]
}

function removeItem(list, idx) {
  const result = [...list]
  result.splice(idx, 1)
  return result
}
